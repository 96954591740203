import * as React from 'react';
import { SearchContainer } from '../../Containers/containersBarrel';
import { Link } from 'react-router-dom'

import './publishers.css'

export const Publishers = (props) => (
  <div className="PublishersPage">
    <div className="BackgroundImageSection">
      <div className="SearchAndTitleContainer">
        <SearchContainer className="Search" location={props.location.pathname} resetLocalStorage="true" />
      </div>
    </div>
    <div className="TextContainer container">
      <div className="PublishersTextContainer">
        <span className="PublishersText">Publishers</span>
        <div className="row">
            <p>
                Academic books in biblical studies and related disciplines, including ancient 
                Near Eastern studies, late antiquity and classics, archaeology, patristics and 
                early Christianity, ancient Jewish studies, early qur’anic studies, history of 
                interpretation, reception history, and theology, will be considered for display 
                on SBL Central and review in <i>RBL</i>.
            </p>
        </div>
        <div className="row">
            <p>
                For more information and for instructions on how to submit metadata using ONIX files or 
                our <Link to="/files/SBLC_Metadata_Template.xlsx" target="_blank" download>Excel template</Link>, 
                please see the <Link to="/files/SBLC_Publisher_Handbook.pdf" target="_blank" download>Publisher Handbook</Link>. 
                Physical copies are no longer accepted.
            </p>
        </div>
        <div className="row">
            <p>
                Please contact <a href="mailto:SBLCadmin@sbl-site.org">SBLCadmin@sbl-site.org</a> with any questions.
            </p>
        </div>
      </div>
    </div>
  </div>
);

/*
import React from 'react'
import { SearchContainer } from "../../Containers/containersBarrel"
import { Link } from 'react-router-dom'

import './publishers.css'

export class Publishers extends React.Component {
    render() {
        return (
            <div className="PublishersPage">
                <div className="BackgroundImageSection">
                    <div className="SearchAndTitleContainer">
                        <SearchContainer
                            className="Search"
                            location={this.props.location.pathname}
                            resetLocalStorage="true"
                        />
                    </div>
                </div>
                <div className="ContentSection">
                    <div className="col-xs-8 LeftSection">
                        <div className="LeftContainer">
                            <div className="PublishersText">
                                <h1 className="PublishersTextTitle">Publishers</h1>
                                <div className="PublishersTextDetails">
                                    Academic books in biblical studies and related disciplines, including ancient 
                                    Near Eastern studies, late antiquity and classics, archaeology, patristics and 
                                    early Christianity, ancient Jewish studies, early qur’anic studies, history of 
                                    interpretation, reception history, and theology, will be considered for display 
                                    on SBL Central and review in <i>RBL</i>.
                                </div>
                                <div className="PublishersTextDetails">
                                    For more information and for instructions on how to submit metadata using ONIX files or 
                                    our <Link to="/files/SBLC_Metadata_Template.xlsx" target="_blank" download>Excel template</Link>, 
                                    please see the <Link to="/files/publisher_handbook.pdf" target="_blank" download>Publisher Handbook</Link>. 
                                    Physical copies are no longer accepted.
                                </div>
                                <div className="PublishersTextDetails">
                                    Please contact <a href="mailto:SBLCadmin@sbl-site.org">SBLCadmin@sbl-site.org</a> with any questions.
                                </div>
                            </div>
                            <div className="PublishersTextBottom" />
                        </div>
                    </div>
                    <div className="col-xs-4 RightSection">
                        &nbsp;
                    </div>
                </div>
            </div>
        );
    }
}
*/