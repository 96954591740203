import React from 'react'
import { SearchContainer } from "../../Containers/containersBarrel"
import { Link } from 'react-router-dom'

import Logo from "../../Assets/Images/rbl_sblpress_logo.jpg"

import './RBL.css'

export class RBL extends React.Component {
    componentDidMount() {
        let footer = document.getElementById('Footer')
        footer.style.position = 'relative'
    }

    render() {
        return (
            <div className="RBLPage">
                <div className="BackgroundImageSection">
                    <div className="SearchAndTitleContainer">
                        <SearchContainer
                            className="Search"
                            location={this.props.location.pathname}
                            resetLocalStorage="true"
                        />
                    </div>
                </div>
                <div className="ContentSection">
                    <div className="col-xs-8 LeftSection">
                        <div className="LeftContainer">
                            <div className="RBLText">
                                <h1 className="RBLTextTitle">Review of Biblical Literature</h1>
                                <div className="RBLTextDetails">
                                    <i>Review of Biblical Literature</i> (<i>RBL</i>) is a publication of 
                                    SBL Press that presents reviews of books in biblical studies 
                                    and related fields. Published digitally on SBL Central and in an&nbsp;
                                    <a href="https://www.sbl-site.org/publications/browsejournals.aspx" rel="noopener noreferrer" target="_blank">annual print edition</a>
                                    , <i>RBL</i> is comprehensive, international, and timely.
                                </div>
                                <div className="RBLTextDetails">
                                    Using the filter to the left of search results, you can limit 
                                    your search to books with published reviews or books for which 
                                    you can volunteer to review. For further instructions on searching, 
                                    see the <a href="/help">Help</a> page.
                                </div>
                                <div className="RBLTextDetails">
                                    For answers to commonly asked questions on the following topics, please consult
                                    the <Link to="/files/RBLGuidelines.pdf" rel="noopener noreferrer" target="_blank" download>RBL Guidelines</Link>.
                                </div>
                                <div className="RBLTextDetails">
                                    <ul className='rblBulletList'>
                                        <li>review format and guidelines</li>
                                        <li>requesting a due-date extension</li>
                                        <li>submitting a finished review</li>
                                        <li>volunteering to review a book</li>
                                        <li>responding to an unfair review</li>
                                    </ul>
                                </div>
                                <div className="RBLTextDetails">
                                    You may email all other questions to the RBL Managing Editor 
                                    at <a href="mailto:RBLadministrator@sbl-site.org">RBLadministrator@sbl-site.org</a>.
                                </div>
                                <div className="RBLTextDetails">
                                    Publishers interested in having their books listed on SBL Central and 
                                    reviewed in <i>RBL</i> should visit the <a href="/home/publishers">Publishers</a> page.
                                </div>
                            </div>
                            <div className="RBLTextBottom" />
                        </div>
                    </div>
                    <div className="col-xs-4 RightSection">
                        <div className="rblLogo-section">
                            <img
                                src={Logo}
                                className="img-responsive rblLogo"
                                alt="RBL"
                            />
                        </div>
                        <div className="editorialTitle">
                            Editorial Board
                        </div>
                        <div>
                            <ul className="RBLList">
                                <li>Alicia J. Batten (General Editor), Conrad Grebel University College</li>
                                <li>Lee Jefferson, Centre College</li>
                                <li>Joseph Lam, University of North Carolina at Chapel Hill</li>
                                <li>Carol Meyers, Duke University</li>
                                <li>Eric M. Meyers, Duke University</li>
                                <li>Raj Nadella, Columbia Theological Seminary</li>
                                <li>Carmen Palmer, Stetson University</li>
                                <li>Angela Parker, Mercer University</li>
                                <li>Ekaputra Tupamahu, George Fox University</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
