import React from 'react'
import Modal from 'react-modal'

import '../Styles/dialogButtons.css'
import './sessionTimeoutDialog.css'

export class SessionTimeoutDialog extends React.Component {
    constructor(props) {
        super(props)

        this.timer = null

        this.state = {
            modalIsOpen: false,
            seconds: 60,
            cancellation: false
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer)
        }
    }

    onRequestClose = () => {
        this.setState({
            cancellation: true
        }, () => {
            this.closeModal()
            this.onSessionContinue()
        })
    }

    showModal = (props) => {
        this.onSessionClose = props.onSessionClose
        this.onSessionContinue = props.onSessionContinue

        this.setState({
            modalIsOpen: true,
            seconds: 60,
            cancellation: false
        }, () => this.timerTick())
    }

    timerHandler = () => {
        let seconds = this.state.seconds - 1
        if (seconds <= 0) {
            this.handleTimeout()
        }

        this.setState({seconds: seconds}, () => this.timerTick())
    }

    handleTimeout = () => {
        this.closeModal()
        this.onSessionClose()
    }

    timerTick() {
        if (!this.state.cancellation) {
            this.timer = setTimeout(this.timerHandler, 1000)
        }
    }

    closeModal() {
        this.setState({
            modalIsOpen: false
        })
    }

    render() {
        return (
            <main>
                <div className="session-timeout-modal">
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="SessionTimeoutCustomModal session-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>Session Timeout</h2>

                        <form onSubmit={this.onRequestClose}>
                            <div className="fieldContainer">
                                To keep your information secure, you are being timed out due to inactivity. To stay signed in, please click the Continue button. Otherwise, you will be signed out automatically in {this.state.seconds} seconds.
                            </div>
                            <div className="dialogButtons">
                                <button className="default-button">Continue</button>
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
