import * as React from 'react';
import { SearchContainer } from '../../Containers/containersBarrel';
import { ModalProvider } from "../../Containers/Login/loginContext";
import { SessionClosedDialog } from '../../Components/SessionClosed/sessionClosedDialog'

import './homeStyles.css';

export class Home extends React.Component {
  render() {
    sessionStorage.removeItem('searchDropdownValue');

    if (sessionStorage.getItem("sblc_session_timeout")) {
      let self = this
      sessionStorage.removeItem("sblc_session_timeout")
      setTimeout(() => {
        self.sessionDialog.showModal()
      }, 500)
    }

    return (
      <div className="HomePage">
        <div className="BackgroundImageSection">
          <div className="SearchAndTitleContainer">
            <h2 className="AboveSearchText">Fostering Biblical Scholarship</h2>
            <SearchContainer className="Search" location={this.props.location.pathname} resetLocalStorage="true" />

            <div className="App container-fluid">
              <ModalProvider>
                <SessionClosedDialog ref={ref => this.sessionDialog = ref}/>
              </ModalProvider>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
