import React from 'react'
import { Link } from 'react-router-dom'
import { DropdownButton } from "react-bootstrap";
import { MenuItem } from "react-bootstrap";
import { ButtonToolbar } from "react-bootstrap";
import { MessageFlash } from '../../Helpers/messageFlashHelper'

import logo from "../../Assets/Images/SBLCentral-AdminLogo-top.png"

import "./adminHeader.css";

export class AdminHeader extends React.Component {
    constructor(props) {
        super(props);

        let loggedInUser = {
            isAuthenticated: false,
            isAdminUser: false
        };

        if (sessionStorage.getItem("loggedInUser")) {
            loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));      
        }

        this.state = {
            loggedInUser: loggedInUser
        }
    }

    returnSignIn = () => {
        return (
            <div>
                {this.returnSignInBlock(this.state.loggedInUser)}
            </div>
        )
    }

    returnSignInBlock(loggedInUser) {
        if (loggedInUser && loggedInUser.isAuthenticated) {
            return (
                <div className="LogInNameContainer nav navbar-right">
                <ButtonToolbar>
                    <DropdownButton
                        title={this.calculateUserName(loggedInUser)}
                        id="dropdown-size-medium"
                        className="LoginUserText"
                    >
                    {this.getPublicMenu(loggedInUser)}
                    <MenuItem eventKey="allFilter" onClick={this.onLogoutClick}>
                        Sign Out
                    </MenuItem>
                    </DropdownButton>
                </ButtonToolbar>
                </div>
            );
        } 
    }

    onLogoutClick = (e) => {
        sessionStorage.removeItem('loggedInUser');
        window.location = '/';
    }

    calculateUserName = (user) => {
        if (user.firstName && user.lastName) {
          return user.firstName + " " + user.lastName;
        }
        else if ( user.firstName ) {
          return user.firstName;
        }
        else if ( user.lastName) {
          return user.lastName;
        }
    
        return "Member";
    }
    
    getPublicMenu(loggedInUser) {
        if(loggedInUser.isAdminUser) {
            return (
                <MenuItem eventKey="admin" onClick={this.onAdminClick} >
                    SBLC Public
                </MenuItem>
            );
        }
    }
    
    onAdminClick = () => {
        window.location = '/';
    }

    returnMessageFlash = () => {
        let message = MessageFlash.getMessage()
        if (!message || message === '') {
          return ''
        }
    
        return (
          <div className="MessageFlash fadeOut">
            {message}
          </div>
        )
    }

    render() {
        if (this.state.loggedInUser.isAuthenticated && this.state.loggedInUser.isAdminUser) {
            return (
                <nav className="AdminHeader navbar navbar-default">
                <div className="logoContainer navbar-header navbar-left">
                  <Link to={`/admin`} className="navbar-brand">
                    <img className="logo-image" src={logo} alt="sbl admin logo" />
                  </Link>
                </div>
                {this.returnSignIn(this.state.loggedInUser)}
                {this.returnMessageFlash()}
              </nav>
            )    
        }
        else {
            return <div></div>
        }
    }
}
