import * as React from 'react';
import { SearchContainer } from '../../Containers/containersBarrel';

import { Link } from "react-router-dom";

import './VolunteerConfirmation.css';

export class VolunteerConfirmation extends React.Component {
  componentDidMount() {
    let footer = document.getElementById('Footer')
    footer.style.position = 'absolute'
    footer.style.bottom = '0'
  }

  render() {
    return (
      <div className="VolunteerConfirmationPage">
        <div className="BackgroundImageSection">
          <div className="SearchAndTitleContainer">
            <SearchContainer className="Search" location={this.props.location.pathname} resetLocalStorage="true" />
          </div>
        </div>
        <div className="TextContainer container">
          <div className="VolunteerConfirmationTextContainer">
            <span className="VolunteerConfirmationText">Volunteer Confirmation</span>
            <div className="row">
              <p>Thank you for volunteering! The editorial board will contact you via email when a decision has been made.</p>
            </div>
            <div className="row">
              <Link to={'#'} onClick={(e) => {
                  e.preventDefault();
                  this.props.history.go(-2);
              }}>
                Back to Search Results
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
