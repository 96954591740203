import React, {Fragment} from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import CheckboxTree from 'react-checkbox-tree';
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'

import { Link } from 'react-router-dom'
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './journalIssues.css'

export class JournalIssues extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            nodes: [],
            journals: [],
            checked: [],
            expanded: [],
            loading: false
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer')
        footer.style.position = 'relative'

        this.setState({loading: true}, () => {
            this.loadDataFromServer()
        })
    }

    onNodeClick = (targetNode) => {
        //console.debug('Targeted Node: ', targetNode)
        
        if (targetNode.isLeaf === true) {
            this.props.history.push(`/admin/journalIssuesDetail/${targetNode.value}`)
        }
    }

    loadDataFromServer() {
        const url = environment.baseUrl + '/api/journals/enumerate'

        axios.get(url, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    this.setState({loading: false})
                    return
                }

                this.setState({
                    journals: response.data.journals,
                    loading: false
                }, () => {
                    this.buildNodesForTreeDisplay()
                })
            })
            .catch(error => {
                console.error('Error getting journal issues: ', error)
                alert('Error getting journal issues: ' + error)

                this.setState({loading: false})
            })
    }

    buildNodesForTreeDisplay() {
        let nodes = []
        this.initUniqueTreeNodeValue()

        this.state.journals.forEach(journal => {
            if (journal.issues.length > 0 && journal.isActive) {
                let journalNode = this.buildIndividualJournalNode(journal)
                nodes.push(journalNode)
            }
        })

        this.setState({nodes: nodes})
    }

    buildIndividualJournalNode(journal) {
        let journalNode = this.createJournalNode(journal)
        let yearNode = {}

        let issues = journal.issues.sort((a,b) => {
            if (a.year != b.year) {
                return b.year - a.year
            }

            if (a.volume != b.volume) {
                return b.volume - a.volume
            }

            const issueNumber = a.issue ? a.issue : 0

            return b.issue - issueNumber
        })

        issues.forEach(issue => {
            if (yearNode.label !== issue.year) {
                yearNode = this.createYearNode(issue)
                journalNode.children.push(yearNode)
            }

            let issueNode = this.createIssueNode(issue)

            yearNode.children.push(issueNode)
        })

        return journalNode
    }

    createJournalNode(journal) {
        return {
            label: journal.title,
            value: this.getUniqueValueForTreeNode(),
            showCheckbox: false,
            children: [],
            className: 'journal-issues-title'
        }
    }

    createYearNode(issue) {
        return {
            label: issue.year,
            value: this.getUniqueValueForTreeNode(),
            showCheckbox: false,
            children: [],
            className: 'journal-issues-year'
        }
    }

    createIssueNode(issue) {
        let display = issue.volume
        if (issue.issue) {
            display += '.' + issue.issue
        }

        return {
            label: display,
            value: issue.id,
            showCheckbox: false,
            className: 'journal-issues-volume'
        }
    }

    initUniqueTreeNodeValue() {
        this.seed = -1
    }

    getUniqueValueForTreeNode() {
        return this.seed--
    }

    getJounralIssuesDisplay() {
        if (this.state.nodes.length === 0) {
            return this.getNoJournalsFoundDisplay()
        }

        return (
            <div className="journal-issues-tree">
                <CheckboxTree
                     nodes={this.state.nodes}
                     checked={this.state.checked}
                     expanded={this.state.expanded}
                     onCheck={checked => this.setState({ checked })}
                     onExpand={expanded => this.setState({ expanded })}
                     showNodeIcon={false}
                     onClick= {(targetNode) => this.onNodeClick(targetNode)}
                     expandOnClick={true}
                />            
            </div>
        )
    }

    getNoJournalsFoundDisplay() {
        return (
            <div className="journal-issues-tree">
                No Journal Issues Found to Display
            </div>
        )
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="journal-issues-manager">
                        <div className="BackgroundImageSection">
                            <div className="TextContainer container">
                                <div className="title-section col-xs-11 text-center">
                                    <div className="col-xs-12 DetailsContainer">
                                        <div className="journal-issues-text-container">
                                            <span className="journal-issues-text">Journal Issues</span>
                                        </div>

                                        <div>
                                            <Link to={`/admin/journalIssuesDetail/new`}>Add Journal Issue</Link>
                                        </div>

                                        <div className="journal-issues-list">
                                            {this.getJounralIssuesDisplay()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
