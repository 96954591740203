import * as React from "react";
import { SearchContainer } from "../../Containers/containersBarrel";

// images
import Baker from "../../Assets/Images/SBLC-Sponsors/BakerAcademicLogo.png";
import BaylorPress from "../../Assets/Images/SBLC-Sponsors/BP-logo_and_big_website_black.jpg";
// import Bloomsbury from '../../Assets/Images/SBLC-Sponsors/bloomsbury.JPG';
import Bloomsbury from "../../Assets/Images/SBLC-Sponsors/Bloomsbury-Publishing2.png";
import Brill from "../../Assets/Images/SBLC-Sponsors/Logo_brill_blauw_groot.png";
import AmericanTheological from "../../Assets/Images/SBLC-Sponsors/Atla-Logo-Tagline-TEAL-RGB.jpg";
import MohrSiebeck from "../../Assets/Images/SBLC-Sponsors/Mohr-Siebeck-Logo-trasp_2017.png";
import PSUP from "../../Assets/Images/SBLC-Sponsors/PSUP_black.lg.png";
import DeGruyter from "../../Assets/Images/SBLC-Sponsors/rz_dg-degruyter.jpg";
import WJK from "../../Assets/Images/SBLC-Sponsors/WJK_Logo_for_SBL.png";
import WS from "../../Assets/Images/SBLC-Sponsors/WS_Logo_SBL_Central.jpg";

import "./sponsorsStyles.css";

class Sponsors extends React.Component {
  render() {
    return (
      <div className="SponsorsPage">
        <div className="BackgroundImageSection">
          <div className="SearchAndTitleContainer">
            <SearchContainer
              className="Search"
              location={this.props.location.pathname}
              resetLocalStorage="true"
            />
          </div>
        </div>
        <div className="ContentSection">
          <div className="col-xs-8 LeftSection">
            <div className="LeftContainer">
              <div className="SponsorsText">
                <h1 className="SponsorsTextTitle">Sponsors and Support</h1>
                <div className="SponsorsTextDetails">
                  Major funding for SBL Central has been provided by the Henry
                  Luce Foundation. And while this research platform is open to
                  all academic publishers, the following publishers and
                  organizations have become Foundation Sponsors to help SBL
                  build and maintain this site.
                </div>
              </div>
              <div className="SponsorImagesSection">
                <div className="row">
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={AmericanTheological}
                      className="img-responsive ATLA"
                      alt="Cinque Terre"
                    />
                  </div>
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={Baker}
                      className="img-responsive Baker"
                      alt="Cinque Terre"
                    />
                  </div>
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={BaylorPress}
                      className="img-responsive BaylorPress"
                      alt="Cinque Terre"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={Bloomsbury}
                      className="img-responsive Bloomsbury"
                      alt="Cinque Terre"
                    />
                  </div>
                  <div className="col-xs-4 col-xs-6 pad22">
                    <img
                      src={Brill}
                      className="img-responsive Brill"
                      alt="Cinque Terre"
                    />
                  </div>
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={DeGruyter}
                      className="img-responsive DG"
                      alt="Cinque Terre"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={MohrSiebeck}
                      className="img-responsive Mohr"
                      alt="Cinque Terre"
                    />
                  </div>
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={PSUP}
                      className="img-responsive PSUP"
                      alt="Cinque Terre"
                    />
                  </div>
                  <div className="col-xs-4 col-xs-12">
                    <img
                      src={WJK}
                      className="img-responsive WJK"
                      alt="Cinque Terre"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-xs-6">
                    <img
                      src={WS}
                      className="img-responsive WS"
                      alt="Cinque Terre"
                    />
                  </div>
                </div>
              </div>
              <div className="SponsorsTextBottom" />
            </div>
          </div>
          <div className="col-xs-4 RightSection">
            <ul className="SponsorsList">
              <li>Henry Luce Foundation</li>
              <li>Atla</li>
              <li>Baker Academic</li>
              <li>Baylor University Press</li>
              <li>Bloomsbury Publishing</li>
              <li>Brill Publishers</li>
              <li>De Gruyter</li>
              <li>Mohr Siebeck</li>
              <li>Penn State University Press</li>
              <li>Westminster John Knox Press</li>
              <li>Wipf and Stock Publishers</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export { Sponsors };
