import React, {Fragment} from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'
import { Checkbox } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'

import './journals.css'

export class Journals extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            journals: [],
            loading: false,
            checked: false
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer')
        footer.style.position = 'relative'

        this.setState({loading: true}, () => {
            this.loadDataFromServer()
        })
    }

    loadDataFromServer() {
        const url = environment.baseUrl + '/api/journals/enumerate'

        axios
            .get(url, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                //console.debug('Data: ', response.data)

                this.setState({
                    journals: response.data.journals,
                    loading: false
                })
            })
            .catch(error => {
                console.error('Error loading journal data: ', error, error.response)
                alert('Error Loading Journal Data')

                this.setState({
                    journals: [],
                    loading: false
                })
            })
    }

    editJournal = (e, id) => {
        e.preventDefault()
        this.props.history.push('/admin/journalDetail/' + id)
    }

    getJournals() {
        if (this.state.journals.length === 0) {
            return (
                <Fragment>
                    <div className="no-items-found">
                        No Journals Found to List
                    </div>
                </Fragment>
            )
        }

        return (
            <Fragment>
                {this.state.journals.map((journal, index) => {
                    let isActive = journal.isActive;
                    let isChecked = this.state.checked;
                    let hidden = (!isActive && !isChecked) ? "hidden" : "";
                    return ( 
                        <div className={`journal-item ${hidden}`} key={index}>                
                            <a onClick={(e) => this.editJournal(e, journal.id)}>{journal.title}</a>
                            <i>{
                                (!journal.isActive) ?
                                 " (inactive)"
                                :""
                                }</i>
                        </div>                    
                    )
                })}
            </Fragment>
        )
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    getInactiveList(){
        return (
            <div className="active-check-box-group">
                <Checkbox name="checked" 
                    checked={this.state.checked} 
                    onChange={this.onCheckboxChange}
                    id="activeCheckBox"
                >
                    Show inactive journals
                </Checkbox>
            </div>
        )
    }


    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="journal-manager">
                        <div className="BackgroundImageSection">
                            <div className="TextContainer container">
                                <div className="title-section col-xs-11 text-center">
                                    <div className="col-xs-12">
                                        <div className="journal-text-container">
                                            <span className="journal-text">Journals</span>
                                        </div>

                                        <div>
                                            <Link to={`/admin/journalDetail/new`}>Add Journal</Link>
                                        </div>
                                        <div className="journal-list">
                                        {this.getInactiveList()}
                                            {this.getJournals()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
