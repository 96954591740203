import React from 'react'

import './adminSearchComponent.css'

export class AdminSearchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: props.searchTerm || '',
            keywordType: props.keywordType || '',
        }

        this.redirectToSearchFlag = false;
    }

    onSearchSubmit = (e) => {
        e.preventDefault();
        
        if( this.state.search ) {
            this.redirectToSearchFlag = true;
            this.forceUpdate();
        }
    }

    onSearchValueChange = (e) => {
        this.setState({ 
            search: e.target.value,
            keywordType: ''
        });
    }

    redirectToSearchPage() {
        this.redirectToSearchFlag = false;
        window.location = '/admin/searchResults/' + this.state.search.trim().replace(/\//g, '%2F') + "?type=1&subtype=0";
    }

    render() {
        if ( this.redirectToSearchFlag ) {
            this.redirectToSearchPage();
            return null;
        }

        return (
            <div className="SearchContainerAdmin">
              <div className="TopSearchContainer">
                <div className="FilterSearchContainer">
                    <div className="FilterSearchDropdownContainer">
                        &nbsp;
                    </div>
                </div>
                <div className="SearchBarContainer">
                  <form className="SearchBarForm" onSubmit={this.onSearchSubmit}>
                    <input
                      className="SearchBar"
                      placeholder="Search Books..."
                      onChange={this.onSearchValueChange}
                      value={this.state.keywordType !== "subsearch" ? decodeURIComponent(this.state.search) : ""}
                      id="SearchBar"
                      autoFocus
                    />
                  </form>
                </div>
                <div className="SearchIconContainer" onClick={this.onSearchSubmit}>
                  <div className="SearchIcon" id="SearchIcon" />
                </div>
              </div>
            </div>
        );
    }
}
