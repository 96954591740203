import React, { Fragment } from 'react'
import { ListField, ContributorModal } from '../componentsBarrel'
import { ModalConsumerStacked } from "../../Containers/Login/loginContext";

import "./contributors.css"

export class Contributors extends React.Component {
    constructor(props) {
        super(props)

        this.title = props.title ? props.title : 'Contributors'

        this.state = {
            contributors: props.contributors ? props.contributors : []
        }
    }

    getModal() {
        return (
            <div>
                <span>
                    <ModalConsumerStacked>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="modal-button">
                                    <button id="contributor-button" className="EditButton" onClick={(e) => this.addModal(e, this.showContributorModal, showModal)}>
                                        Add Contributor
                                    </button>
                                </div>
                                <div className="col-xs-10">
                                    <ListField name="contributorList"
                                        required
                                        data={this.state.contributors}
                                        displayitem={this.displayContributor}
                                        modal={this.showContributorModal}
                                        showModal={showModal}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </ModalConsumerStacked>
                </span>
            </div>
        )
    }

    addModal(event, modal, showModal) {
        event.preventDefault();
        modal(showModal, this)
    }

    editModal(event, modal, showModal, item, index) {
        event.preventDefault();
        if (item !== undefined) {
            modal(showModal, this, item, index)
        }
    }

    showContributorModal(showModal, self, item, index) {
        let close = (data, self) => {
            if (data) {
                let array = self.state.contributors

                if (data.deleteRequested) {
                    array.splice(index, 1)
                }
                else if (data.edit) {
                    let record = array[index]
                    record.firstName = data.firstName
                    record.lastName = data.lastName
                    record.isAuthor = data.isAuthor
                    record.isEditor = data.isEditor
                    record.isTranslator = data.isTranslator
                    
                    if (record.author && record.author.person) {
                        record.author.person.lastName = data.lastName
                        record.author.person.firstName = data.firstName
                    }

                    array[index] = record;
                }
                else {
                    array.push(data)
                }
            }
        }

        showModal(ContributorModal, { onClose: close, parent: self, item: item })
    }

    displayContributor = (item, index, modal, showModal) => {
        return (
            <div key={index}>
                <a onClick={(e) => this.editModal(e, modal, showModal, item, index)}>
                    {item.firstName + ' ' + item.lastName + ': ' + this.calculateRoleDisplay(item)}
                </a>
            </div>
        )
    }

    calculateRoleDisplay(contributor) {
        let role = "";

        role += contributor.isAuthor ? ", Author" : '';
        role += contributor.isEditor ? ", Editor" : '';
        role += contributor.isTranslator ? ", Translator" : '';

        if (role.length > 0) {
            role = role.substring(2);
        }

        return role;
    }

    render() {
        return (
            <div className="select-title">
                <span className="select-title-text">
                    <span className="requiredMarker">* </span><strong>{this.title}</strong>
                </span>

                {this.getModal()}
            </div>
        )
    }
}