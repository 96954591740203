import React from 'react'
import Modal from 'react-modal'
import { InputField } from '../componentsBarrel'

import '../Styles/dialogButtons.css'
import './contributorModal.css'
import { Checkbox } from 'react-bootstrap'

export class ContributorModal extends React.Component {

    constructor(props) {
        super(props);

        this.showDelete = props.item ? true : false

        this.state = {
            firstName: props.item ? props.item.firstName : '',
            lastName: props.item ? props.item.lastName : '',
            isAuthor: props.item ? props.item.isAuthor : true,
            isEditor: props.item ? props.item.isEditor : false,
            isTranslator: props.item ? props.item.isTranslator : false,
            edit: props.item ? true : false
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();

        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    }

    onRequestDelete = (e) => {
        e.preventDefault();
        let data = {
            ...this.state,
            deleteRequested: true
        }
        this.onRequestClose(e);
        this.props.onClose(data, this.props.parent);

    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.isAuthor === false
            && this.state.isEditor === false
            && this.state.isTranslator === false) {
            alert('Must select at least one role') 
            return false;
        }
        else {
            this.onRequestClose(e);
            this.props.onClose(this.state, this.props.parent);
            return true;
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    getDeleteButton() {
        if (this.showDelete) {
            return (
                <button className="secondary-button" onClick={(e) => this.onRequestDelete(e)}>Delete</button>
            )
        }
    }

    render() {
        return (
            <main>
                <div className="book-contributor-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="BookContributorCustomModal book-contributor-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>Contributor</h2>

                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="fieldGroup">
                                    <InputField title="First Name"
                                        name="firstName"
                                        onChange={this.onChange}
                                        value={this.state.firstName}
                                        maxLength="255"
                                        required
                                        autoFocus
                                    />
                                </div>
                                <div className="fieldGroup">
                                    <InputField title="Last Name"
                                        name="lastName"
                                        onChange={this.onChange}
                                        value={this.state.lastName}
                                        maxLength="255"
                                        required
                                    />
                                </div>
                                <div className="fieldGroup">
                                    <div>
                                        <strong>Role</strong>
                                    </div>
                                    <div className="contributor-checkbox-group">
                                        <div className="check-box">
                                            <Checkbox name="isAuthor" checked={this.state.isAuthor} onChange={this.onCheckboxChange}>
                                                Author
                                            </Checkbox>
                                        </div>
                                        <div className="check-box">
                                            <Checkbox name="isEditor" checked={this.state.isEditor} onChange={this.onCheckboxChange}>
                                                Editor
                                            </Checkbox>
                                        </div>
                                        <div className="check-box">
                                            <Checkbox name="isTranslator" checked={this.state.isTranslator} onChange={this.onCheckboxChange}>
                                                Translator
                                            </Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dialogButtons">
                                <button className="default-button">Save</button>
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                                {this.getDeleteButton()}
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
