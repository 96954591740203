import React, { Fragment } from 'react'
import Tooltip from 'react-tooltip-lite';

import { ModalConsumer } from "../../Containers/Login/loginContext";
import { JournalArticleModal } from '../componentsBarrel'

import './journalArticles.css'

export class JournalArticles extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            articles: props.articles ? props.articles : []
        }
    }

    showJournalArticleModal(showModal, self, item, index) {
        let close = (data, self) => {
            if (data) {
                let array = self.state.articles

                if (data.deleteRequested) {
                    array.splice(index, 1)
                }
                else if (data.edit) {
                    let record = array[index]

                    record.title = data.title
                    record.doi = data.doi
                    record.authors = data.authors

                    array[index] = record;
                }
                else {
                    array.push(data)
                }

                self.setState({
                    articles: array
                })
            }
        }

        showModal(JournalArticleModal, { onClose: close, parent: self, item: item })
    }

    addModal(event, modal, showModal) {
        event.preventDefault();
        modal(showModal, this)
    }

    editModal(event, modal, showModal, item, index) {
        event.preventDefault();
        if (item !== undefined) {
            modal(showModal, this, item, index)
        }
    }

    ellipsisFormat(longText, maxLength) {
        maxLength = (maxLength === null) ? 25 : maxLength

        if (!longText || longText.length <= maxLength) {
            return longText
        }

        return longText.substring(0, maxLength) + '...'
    }

    formatTooltip(text) {
        return text;
    }

    getArticlesGrid(modal, showModal) {
        return (
            <div className="articles-table">
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Author</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.articles.map((x, index) => {
                            return (
                                <tr key={index}>
                                    <td className="links-title">{this.displayTitle(x, index, modal, showModal)}</td>
                                    <td>{this.displayAuthor(x)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                </table>
            </div>
        )
    }

    displayTitle = (item, index, modal, showModal) => {
        let title = this.ellipsisFormat(item.title, 50)
        let tooltip = this.formatTooltip(item.title)

        if (item.title.length > 50) {
            return (
                <div className="links">
                    <Tooltip content={tooltip}>
                        <a onClick={(e) => this.editModal(e, modal, showModal, item, index)}>{title}</a>
                    </Tooltip>
                </div>
            )
        }

        return (
            <div className="links">
                <a onClick={(e) => this.editModal(e, modal, showModal, item, index)}>{item.title}</a>
            </div>
        )
    }

    displayAuthor = (item) => {
        let name = `${item.authors[0].firstName} ${item.authors[0].lastName}`
        let display = this.ellipsisFormat(name, 50)
        let tooltip = this.formatTooltip(name)

        if (name.length > 50) {
            return (
                <Tooltip content={tooltip}>
                    {display}
                </Tooltip>
            )
        }

        return name;
    }

    getModal() {
        return (
            <div>
                <span>
                    <ModalConsumer>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="modal-link">
                                    <a onClick={(e) => this.addModal(e, this.showJournalArticleModal, showModal)}>Add Article</a>
                                </div>

                                {this.getArticlesGrid(this.showJournalArticleModal, showModal)}
                            </Fragment>
                        )}
                    </ModalConsumer>
                </span>
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                <div className="journal-articles">
                    <div className="select-title">
                        {this.getModal()}
                    </div>
                </div>
            </Fragment>
        )
    }
}
