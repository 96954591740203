import React, { Fragment } from 'react'
import { ModalConsumer } from "../../Containers/Login/loginContext";
import { ListField, ImprintModal } from '../componentsBarrel'

import './imprints.css'

export class Imprints extends React.Component {
    constructor(props) {
        super(props)

        let array = props.imprints ? props.imprints : []
        //array.sort(this.imprintCompare)

        this.state = {
            imprints: array
        }
    }

    showImprintModal(showModal, self, item, index) {
        let close = (data, self) => {
            if (data) {
                let array = self.state.imprints

                if (data.deleteRequested) {
                    array.splice(index, 1)
                }
                else if (data.edit) {
                    let record = array[index]
                    record.description = data.description
                    array[index] = record;
                }
                else {
                    array.push(data)
                }

                array.sort(self.imprintCompare)

                self.setState({
                    imprints: array
                })
            }
        }

        showModal(ImprintModal, { onClose: close, parent: self, item: item })
    }

    imprintCompare = (a,b) => {
        return a.description.localeCompare(b.description)
    }

    addModal(event, modal, showModal) {
        event.preventDefault();
        modal(showModal, this)
    }

    editModal(event, modal, showModal, item, index) {
        event.preventDefault();
        if (item !== undefined) {
            modal(showModal, this, item, index)
        }
    }

    getModal() {
        return (
            <div>
                <span>
                    <ModalConsumer>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="modal-button">
                                    <button className="EditButton" onClick={(e) => this.addModal(e, this.showImprintModal, showModal)}>
                                        Add Imprint
                                    </button>
                                </div>
                                <div className="col-xs-10">
                                    <ListField name="imprintList"
                                        data={this.state.imprints}
                                        displayitem={this.displayImprint}
                                        modal={this.showImprintModal}
                                        showModal={showModal}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </ModalConsumer>
                </span>
            </div>
        )
    }

    displayImprint = (item, index, modal, showModal) => {
        let imprint = item.description
        
        return (
            <div key={index}>
                <a onClick={(e) => this.editModal(e, modal, showModal, item, index)}>{imprint}</a>
            </div>
        )
    }

    render() {
        return (
            <div className="imprints-div">
                <div className="select-title">
                    <span className="select-title-text">
                        <strong>Imprints</strong>
                    </span>

                    {this.getModal()}
                </div>
            </div>
        )
    }
}