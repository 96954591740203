import React from 'react'
import { BusyIndicator } from '../Components/componentsBarrel'

import './busyIndicator.css'

export const getBusyIndicator = (isLoading, alt) => {
    if (isLoading) {
        return <div className="busy-indicator">
            <BusyIndicator busy={true} />
        </div>
    }
    else {
        return alt;
    }
}
