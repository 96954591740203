import React from 'react'
import './roundCheckbox.css'

export const RoundCheckbox = (props) => {
    return (
        <label className="rounded-checkbox-container">{props.text}
            <input type="checkbox" 
                defaultChecked={props.selected}
                onChange={props.onChange}
            >
            </input>
            <span className="checkmark"></span>
        </label>
    )
}
