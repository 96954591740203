import React, { Component, createContext } from "react";

const ModalContext = createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {}
});

const ModalContextStacked = createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {}
});

export class ModalProvider extends Component {
  showModal = (component, props = {}) => {
    this.setState({ 
      modal1: {
        component: component,
        props: props,
        showModal: this.showModal,
        hideModal: this.hideModal
      } 
    });
  };

  showModalStacked = (component, props = {}) => {
    this.setState({
      modal2: {
        component,
        props,
        showModal: this.showModalStacked,
        hideModal: this.hideModalStacked
      }
    });
  };

  hideModal = () =>
    this.setState({
      modal1: {
        component: null,
        props: {},
        showModal: this.showModal,
        hideModal: this.hideModal
      }
    });

  hideModalStacked = () =>
    this.setState({
      modal2: {
        component: null,
        props: {},
        showModal: this.showModalStacked,
        hideModal: this.hideModalStacked
      }
    });

  state = {
    modal1: {
      component: null,
      props: {},
      showModal: this.showModal,
      hideModal: this.hideModal
    },
    modal2: {
      component: null,
      props: {},
      showModal: this.showModalStacked,
      hideModal: this.hideModalStacked
    }
  };

  render() {
    return (
      <ModalContext.Provider key={this.state.modal1} value={this.state.modal1}>
        <ModalContextStacked.Provider key={this.state.modal2} value={this.state.modal2}>
          {this.props.children}
        </ModalContextStacked.Provider>
      </ModalContext.Provider>
    );
  }
}

export const ModalConsumer = ModalContext.Consumer;
export const ModalConsumerStacked = ModalContextStacked.Consumer;
