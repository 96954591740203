import React from 'react'
import "./selectField.css"

const requiredMark = (required) => {
    if ( required ) {
        return <span className="requiredMarker">* </span>
    }
}

export const SelectField = (props) => {
    return (
        <div className="select-field-control">
            <div>
                <span className="select-field-title">
                    {requiredMark(props.required)}
                    <strong>{props.Title}</strong>
                </span>
            </div>

            <select className="select-field" value={props.value} onChange={props.onChange} name={props.name} id={props.name}>
                {props.data.map((item, index) => {
                    let display, value
                    if (typeof item === 'object') {
                        display = item[props.displayName]
                        value = item[props.displayValue]
                    }
                    else {
                        display = item
                        value = item
                    }

                    return (
                        <option key={index} value={value}>{display}</option>
                    )
                })}
            </select>
        </div>
    )
}
