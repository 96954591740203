export class MessageFlash {
    static getMessage() {
        let message = sessionStorage.getItem('sblBookDetailMessage')
        sessionStorage.removeItem('sblBookDetailMessage')
        return message
    }

    static setMessage(message) {
        sessionStorage.setItem('sblBookDetailMessage', message)
    }
}
