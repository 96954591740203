import * as React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';

import './footerStyles.css';

export const Footer = ({ match }) => (
  <div className="Footer" id="Footer">
    <div className="LeftFooterContainer">
      <div className="CopyrightContainer">
        <span>©{Moment().year()} Society of Biblical Literature</span>
      </div>
    </div>
    <div id="RightFooterContainer" className="RightFooterContainer">
      <div className="FooterTextContainer">
        <div className="AboutLinkContainer">
          <Link to={`/home/about`}>About</Link>
        </div>
        <div className="RBLLinkContainer">
          <Link to={`/home/rbl`}>RBL</Link>
        </div>
        <div className="PublishersLinkContainer">
          <Link to={`/home/publishers`}>Publishers</Link>
        </div>
        <div className="SponsorsLinkContainer">
          <Link to={`/home/sponsors`}>Sponsors</Link>
        </div>
        <div className="ContactLinkContainer">
          <Link to={`/home/contact`}>Contact</Link>
        </div>
      </div>
    </div>
  </div>
);
