import React, { Fragment } from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import environment from "../../Environments/environments";
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import './accountingExportHistoryModal.css'

export class AccountingExportHistoryModal extends React.Component {
    constructor(props) {
        super(props)

        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            history: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({loading: true}, () => this.loadFromServer())
    }

    loadFromServer() {
        let url = `${environment.baseUrl}/api/admin/accounting-export-history`

        axios.get(url, this.config)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                //console.debug('TEST: ', response.data.history)

                this.setState({
                    history: response.data.history,
                    loading: false
                })
            })
            .catch(error => {
                console.error('Error getting export history: ', error)
                alert('Error getting export history: ' + error)

                this.setState({
                    loading: false
                })
            })
    }

    getHistoryRows() {
        return (
            <div className="export-history-table">
                <table>
                    <thead>
                        <tr>
                            <th>
                                Publisher
                            </th>
                            <th>
                                Export Date
                            </th>
                            <th>
                                User
                            </th>
                            <th>
                                From Date
                            </th>
                            <th>
                                To Date
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.history.map((x, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {x.publisher}
                                    </td>
                                    <td>
                                        {new Date(x.exportDate).toLocaleDateString()}
                                    </td>
                                    <td>
                                        {x.adminUser}
                                    </td>
                                    <td>
                                        {new Date(x.fromDate).toLocaleDateString()}
                                    </td>
                                    <td>
                                        {new Date(x.toDate).toLocaleDateString()}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                </table>
            </div>
        )
    }

    onRequestClose = (e) => {
        e.preventDefault();

        if (this.props.onRequestClose) {
            // this is what allows the dialog to close
            this.props.onRequestClose();
        }
    }

    render() {
        return (
            <main>
                <div className="accounting-export-history-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="AccountingExportHistoryCustomModal accounting-export-history-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <Fragment>
                            <h2>Accounting Export History</h2>

                            {this.getHistoryRows()}

                            <div id="dialog-buttons" className="dialogButtons">
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Close</button>
                            </div>
                        </Fragment>
                    </Modal>
                </div>
            </main>
        )
    }
}
