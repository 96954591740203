import React from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import {ExpertiseModalContent} from '../../Components/Expertise/expertiseModalContent'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';
import { Helmet } from 'react-helmet';

import './bookNotifications.css'

export class BookNotifications extends React.Component {
    constructor(props) {
        super(props)

        this.user = JSON.parse(sessionStorage.getItem('loggedInUser'))
        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            settings: [],
            bookSubjects: [],
            loading: false
        }
    }

    componentDidMount() {
        if (!this.user) {
            window.location = '/'
        }

        this.setState({loading: true}, () => this.loadFromServer())
    }

    loadFromServer() {
        if (!this.user) return
       // debugger;
        let url = `${environment.baseUrl}/api/notifications/enumerate/${this.user.sblId}`

        try {
            axios
                .get(url, this.config)
                .then(response => {
                    if (response.data.error) {
                        alert(response.data.error)
                        return
                    }

                    let subjects = []
                    response.data.settings.forEach(x => {
                        subjects.push({
                            bookId: 0,
                            subjectId: x.subjectId,
                            subject: x.subject
                        })
                    })

                    this.setState({
                        settings: response.data.settings,
                        bookSubjects: subjects,
                        loading: false
                    })
                })
                .catch(error => {
                    console.error('Error getting notifications: ', error, error.response);
                    alert('Error Getting Notifications:' + error);

                    this.setState({
                        bookSubjects: [],
                        loading: false
                    })
                })
        }
        finally {
            this.setState({loading: false})
        }
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.onClose()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let notifications = {
            sblId: this.user.sblId,
            settings: []
        }

        this.expertise.state.bookSubjects.forEach(x => {
            let notification = {
                sblId: this.user.sblId,
                subjectId: x.subjectId
            }

            notifications.settings.push(notification)
        })

        let url = `${environment.baseUrl}/api/notifications/save`

        axios
            .post(url, notifications, this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return
                }

                alert('Book Notifications Saved Successfully')
                
                this.props.onClose()
            })
            .catch(error => {
                console.error('Error saving notifications: ', error, error.response);
                alert('Error Saving Notifications: ' + error);
            })
    }

    render() {
        return (
            <div className="notifications-page">
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="notifications-text-container">
                    <span className="notifications-text">Manage Book Notifications</span>
                </div>

                <div className="notifications-description">
                    <label>
                        Use this screen to specify your subject areas of interest. We’ll notify you when books and book reviews matching your selections are added.
                    </label>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="notifications-area">
                        <ExpertiseModalContent ref={x => this.expertise = x} subjects={this.state.bookSubjects}/>
                    </div>

                    <div>
                        <div className="text-center button-group">
                            <button className="SubmitButton">Submit</button>
                            <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
