import React from "react";
import { ModalConsumer, ModalConsumerStacked } from "./loginContext";

const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, props, hideModal }) =>
      Component ? <Component {...props} onRequestClose={hideModal} /> : null
    }
  </ModalConsumer>
);

export const ModalRootStacked = () => (
  <ModalConsumerStacked>
    {({ component: Component, props, hideModal }) =>
      Component ? <Component {...props} onRequestClose={hideModal} /> : null
    }
  </ModalConsumerStacked>
)

export default ModalRoot;
