import React, { Fragment } from 'react'
import Modal from 'react-modal'
import { BookStatusHelper } from '../../Helpers/bookStatusHelper'

import '../Styles/divTables.css'
import './bookStatusHistoryModal.css'

export class BookStatusHistoryModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            bookId: props.bookId,
            history: props.history
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();

        if (this.props.onRequestClose) {
            // this is what allows the dialog to close
            this.props.onRequestClose();
        }
    }

    translateStatus(statusId) {
        return BookStatusHelper.translateStatus(statusId)
    }

    getStatusRows() {
        return (
            <div className="status-history-table">
                <table>
                    <thead>
                        <tr>
                            <th>
                                Date
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Previous Status
                            </th>
                            <th>
                                Updated Status
                            </th>
                            <th>
                                Note
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.history.map((x, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {new Date(x.created).toLocaleDateString()}
                                    </td>
                                    <td>
                                        {x.bookStatusName}
                                    </td>
                                    <td>
                                        {this.translateStatus(x.previousStatus)}
                                        </td>
                                    <td>
                                        {this.translateStatus(x.newStatus)}
                                        </td>
                                    <td>
                                        {x.note}
                                        </td>
                                </tr>
                            )
                        })}
                        </tbody>
                </table>
            </div>
        )
    }

    render() {
        return (
            <main>
                <div className="book-status-history-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="BookStatusHistoryCustomModal book-status-history-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <Fragment>
                            <h2>Status History</h2>

                            {this.getStatusRows()}

                            <div id="dialog-buttons" className="dialogButtons">
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Close</button>
                            </div>
                        </Fragment>
                    </Modal>
                </div>
            </main>
        )
    }
}
