const filterReducer = (
  state = {
    twentySixteenToPresent: false,
    twentyElevenToTwentyFifteen: false,
    twoThousandSixToTwentyTen: false,
    twoThousandOneToTwoThousandFive: false,
    bookFilter: false,
    journalFilter: false,
    conferencePaperFilter: false,
    annualMeetingFilter: false,
    internationalMeetingFilter: false,
      reviewedFilter: false,
      notReviewedFilter: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_TWENTY_SIXTEEN_TO_PRESENT_FILTER':
      state = {
        ...state,
        twentySixteenToPresent: action.checked
      };
      break;
    case 'SET_TWENTY_ELEVEN_TO_TWENTY_FIFTEEN_FILTER':
      state = {
        ...state,
        twentyElevenToTwentyFifteen: action.checked
      };
      break;
    case 'SET_TWO_THOUSAND_SIX_TO_TWENTY_TEN_FILTER':
      state = {
        ...state,
        twoThousandSixToTwentyTen: action.checked
      };
      break;
    case 'SET_TWO_THOUSAND_ONE_TO_TWO_THOUSAND_FIVE_FILTER':
      state = {
        ...state,
        twoThousandOneToTwoThousandFive: action.checked
      };
      break;
    case 'SET_BOOK_FILTER':
      state = {
        ...state,
        bookFilter: action.checked
      };
      break;
    case 'SET_JOURNAL_FILTER':
      state = {
        ...state,
        journalFilter: action.checked
      };
      break;
    case 'SET_CONFERENCE_PAPER_FILTER':
      state = {
        ...state,
        conferencePaperFilter: action.checked
      };
      break;
    case 'SET_ANNUAL_MEETING_FILTER':
      state = {
        ...state,
        annualMeetingFilter: action.checked
      };
      break;
    case 'SET_INTERNATIONAL_MEETING_FILTER':
      state = {
        ...state,
        internationalMeetingFilter: action.checked
      };
      break;
      case 'SET_REVIEWED_FILTER':
          state = {
              ...state,
              reviewedFilter: action.checked
          };
          break;
      case 'SET_NOT_YET_REVIEWED_FILTER':
          state = {
              ...state,
              notReviewedFilter: action.checked
          };
          break;
      default:
          break;
  }
  return state;
};

export default filterReducer;
