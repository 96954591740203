import React, {Fragment} from 'react'
import {ExpertiseModal, TextField} from '../componentsBarrel'
import { ModalConsumerStacked } from "../../Containers/Login/loginContext";

import './expertiseComponent.css'

export class ExpertiseComponent extends React.Component {
    constructor(props) {
        super(props)

        this.rows = props.rows ? props.rows : "4"
        this.title = props.title ? props.title : 'Subjects'

        this.state = {
            subjects: props.subjects ? props.subjects : []
        }
    }

    getSubjects() {
        let result = ''

        for (let i = 0; i < this.state.subjects.length; ++i) {
            result += i === 0 ? '' : ', '
            result += this.state.subjects[i].subject.description
        }

        return result;
    }

    getSubjectsDisplay() {
        return (
            <div>
                {this.getSubjectsModalButton()}
                <TextField 
                    value={this.getSubjects()}
                    rows={this.rows}
                    readOnly
                />
            </div>
        )
    }

    getSubjectsModalButton() {
        return (
            <div>
                <span>
                    <ModalConsumerStacked>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="modal-button">
                                    <button className="EditButton" onClick={(e) => this.addModal(e, showModal)}>
                                        Add/Edit Subjects
                                    </button>
                                </div>
                            </Fragment>
                        )}
                    </ModalConsumerStacked>
                </span>
            </div>
        )
    }

    showExpertiseModal(showModal, self) {
        let close = (data, self) => {
            if (data) {
                self.props.onSubjectChange(data);
                self.setState({
                    subjects: data
                })
            }
        }

        let passedArray = []
        self.state.subjects.forEach(x => passedArray.push(x))

        showModal(ExpertiseModal, { onClose: close, parent: self, subjects: passedArray })
    }

    addModal(event, showModal) {
        event.preventDefault();
        this.showExpertiseModal(showModal, this)
    }

    render() {
        return (
            <div className="select-title">
                <span className="select-title-text"><strong>{this.title}</strong></span>
                {this.getSubjectsDisplay()}
            </div>
        )
    }
}
