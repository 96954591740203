import React, {Fragment} from 'react'
import axios from 'axios'
import DataCallerBaseComponent from '../../Core/dataCallerBaseComponent'
import environment from "../../../Environments/environments";
import ReactExport from "react-data-export"

import { BaseSummaryReport } from '../BaseSummaryReport'
import { guardAdmin } from '../../Admin/admin-base'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class ImportSummaryReport extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: null
        }

        this.reportTitle = 'Import Summary'
    }

    getDataDisplay = () => {
        if (!this.state.data) {
            return ''
        }

        return (
            <Fragment>
                <div className="data-display">
                    <hr/>
                    <div>
                        <a className="export-link" onClick={this.base.onDownload}>Export to Excel</a>
                        {this.getExcelFunctionalButton()}
                    </div>
                    <div className="data-table">
                        <table id="data-table">
                            <thead>
                                <tr className="table-head">
                                    <th>Date</th>
                                    <th>Account</th>
                                    <th>Source File</th>
                                    <th>Source Activity</th>
                                    <th>Num Records Inserted</th>
                                    <th>Num Records Updated</th>
                                    <th>Num Records Failed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map((item, index) => {
                                    item.formattedDate = this.formatDate(item.date)
                                    return (
                                        <tr className="table-row" key={index}>
                                            <td>{item.formattedDate}</td>
                                            <td>{item.account}</td>
                                            <td>{item.sourceFile}</td>
                                            <td>{item.sourceActivity}</td>
                                            <td>{item.numRecordsInserted}</td>
                                            <td>{item.numRecordsUpdated}</td>
                                            <td>{item.numRecordsFailed}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }

    formatDate(date) {
        return new Date(date).toLocaleDateString()
    }

    getExcelFunctionalButton() {
        return (
            <ExcelFile element={this.base.getExcelButton()} filename="SBLCImportSummary">
                <ExcelSheet data={this.state.data} name="SBLCImportSummary">
                    <ExcelColumn label="Date" value="formattedDate"/>
                    <ExcelColumn label="Account" value="account"/>
                    <ExcelColumn label="Source File" value="sourceFile"/>
                    <ExcelColumn label="Source Activity" value="sourceActivity"/>
                    <ExcelColumn label="Num Records Inserted" value="numRecordsInserted"/>
                    <ExcelColumn label="Num Records Updated" value="numRecordsUpdated"/>
                    <ExcelColumn label="Num Records Failed" value="numRecordsFailed"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }

    onSubmit = async (e) => {
        let url = `${environment.baseUrl}/api/reports/importsummary`

        let data = {
            fromDate: this.base.state.fromDate.toLocaleString(),
            toDate: this.base.state.toDate.toLocaleString()
        }

        return axios.post(url, data, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    console.error('Error: ', response.data.message)
                    alert('Error getting Import Summary data: ' + response.data.message)
                    return;
                }

                let reportData = response.data.data

                this.setState({ data: reportData }, () => true)
            })
            .catch(error => {
                console.log('Error: ', error)
                alert('An error occurred getting report data: ' + error)
                return false;
            })
    }

    render() {
        return guardAdmin(
            <BaseSummaryReport 
                reportTitle = "Import Summary"
                getDataDisplay={this.getDataDisplay}
                onSubmit={this.onSubmit}
                ref = {x => this.base = x}
            />
        )
    }
}
