import axios from "axios";
import environment from "../../Environments/environments";

export function guardAdmin (proposedJSX) {
    setTimeout(validateAdmin, 500)
    return proposedJSX
}

function validateAdmin() {
    let user = null;
        
    if(sessionStorage.getItem('loggedInUser')) {
        user = JSON.parse(sessionStorage.getItem('loggedInUser'));
    }

    //console.debug('USER USER: ', user)

    if ( !user || user.isAuthenticated === false || user.isAdminUser === false || !user.token) {
        window.location = '/';
    }

    if (user) {
        axios.post(`${environment.baseUrl}/api/token/checktoken`, {token: user.token})
        .then(response => {
            if (response.data.success === false) {
                console.error('Error checking: ', response.data.message)
                alert('Error checking token: ' + response.data.message)
                window.location = '/'
                return
            }

            //console.debug('valid')
        })
        .catch(error => {
            console.error('Internal error: ', error)

            if (error.includes("Request aborted") == false) {
                window.location = '/'
            }
        })
    }
}
