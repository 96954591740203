import React from 'react'
import Modal from 'react-modal'
import { InputField } from '../componentsBarrel'

import './bookReviewModal.css'

export class BookReviewModal extends React.Component {

    constructor(props) {
        super(props)

        this.showDelete = props.item ? true : false

        this.state = {
            names: props.item ? props.item.names : '',
            file: props.item ? props.item.file : null,
            reviewPDFName: props.item ? props.item.reviewPDFName : '',
            reviewedOn: new Date(),
            edit: props.item ? true : false
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();
        this.props.onRequestClose();
    }

    onRequestDelete = (e) => {
        let data = {
            ...this.state,
            deleteRequested: true
        }
        
        this.onRequestClose(e);
        this.props.onClose(data, this.props.parent);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onBrowseClick = (e) => {
        e.preventDefault()
        this.fileUpload.click()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.reviewPDFName === '') {
            alert('Review file is required')
            return false;
        }

        this.onRequestClose(e);
        this.props.onClose(this.state, this.props.parent);
    }

    handleFileSelection = (e) => {
        const self = this
        const file = e.target.files[0]

        let filename = e.target.value

        if (!this.checkExtension(filename)){
            alert('Invalid file type. File must be PDF format.')
            return false
        }

        const sizeInMb = file.size / 1024 / 1024
        if (sizeInMb > 10) {
            let sizeErrorMessage = "File is too big. File size cannot exceed 10 MB."
            alert(sizeErrorMessage + "  The selected file is " + Math.ceil(sizeInMb) + "mb.")
            return false
        }

        var reader = new FileReader()

        reader.onload = function (e) {

            console.debug('review file: ', file)

            self.setState({
                selectedFile: file,
                // eslint-disable-next-line
                reviewPDFName: filename.replace(/^.*?([^\\\/]*)$/, '$1'),
                needsUploading: true
            })
        }

        reader.readAsDataURL(e.target.files[0])
    }

    checkExtension(filename) {
        var hash = {
            '.pdf': 1
        };

        const re = /\.[0-9a-z]+$/i
        const ext = filename.toLowerCase().match(re);

        return hash[ext]
    }

    getDeleteButton() {
        if ( this.showDelete ) {
            return (
                <button className="secondary-button" onClick={(e) => this.onRequestDelete(e)}>Delete</button>
            )
        }
    }

    render() {
        return (
            <main>
                <div className="book-review-modal">
                <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="BookReviewCustomModal book-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>Review</h2>

                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="fieldGroup">
                                    <InputField title="Reviewers" 
                                        name="names" 
                                        onChange={this.onChange} 
                                        value={this.state.names} 
                                        maxLength="200"
                                        required
                                        autoFocus
                                    />
                                </div>
                                <div className="fieldGroup">
                                    <div id="fileUploadGroup">
                                        <input type="file"
                                            id="avatar" name="avatar"
                                            accept="application/pdf"
                                            onChange={this.handleFileSelection}
                                            ref={(ref) => this.fileUpload = ref}
                                        />
                                        <button id="browseButton" onClick={this.onBrowseClick}>Browse</button>
                                        <input name="reviewPDFName"
                                            id="reviewPDFName"
                                            value={this.state.reviewPDFName}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="dialogButtons">
                                <button className="default-button">Save</button>
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                                {this.getDeleteButton()}
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
