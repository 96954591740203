import * as React from 'react';
import { SearchContainer } from '../../Containers/containersBarrel';

import './aboutStyles.css';

export const About = (props) => (
  <div className="AboutPage">
    <div className="BackgroundImageSection">
      <div className="SearchAndTitleContainer">
        <SearchContainer className="Search" location={props.location.pathname} resetLocalStorage="true" />
      </div>
    </div>
    <div className="TextContainer container">
      <div className="AboutUsTextContainer">
        <span className="AboutUsText">About Us</span>
        <div className="row">
          <p>SBL Central is SBL’s response to the pressures that biblical scholars face: increased specialization of the field, increased disaggregation of scholarly publications, decreased discoverability of publications, increased strain on traditional research hubs (libraries), and lack of access to resources in many parts of the world, as well as among unaffiliated scholars and adjunct faculty.</p>
        </div>
        <div className="row">
          <p>As a nonprofit academic publisher and learned society, SBL looked for the sweet spot in which everyone wins: scholars, publishers, libraries, library services, and institutions of higher education. SBL Central fills a niche not currently being served and fosters each stakeholder's success. SBL rightly serves as a catalyst, not a competitor, by creating a mutually beneficial hub.</p>
        </div>
        <div className="row">
          <p>What is its simple idea? SBL Central is a research platform to support participants in the entire interdisciplinary ecosystem of biblical, religious, and theological studies, including scholars, students, publishers, publishing associations, and libraries and their institutions. A learned society exists at the intersection of these stakeholders. Learned societies foster collaboration and, at their best, innovation. Like its Annual Meeting, a learned society facilitates networking, creates opportunity, fosters the production of knowledge, and provides opportunities for professional development. Institutions, publishers, and scholars depend on the hub learned societies provide. SBL’s job is to serve each of these. SBL Central embodies the adage that the whole is greater than the sum of its parts.</p>
        </div>
        <div className="row">
          <p>SBL Central was conceived in this context. By creating granular discovery of content (books, book reviews, conference papers, and journal articles) naturally identified with the field's research specializations, our objective is for this resource to be a core tool to the research and professional development of its members.</p>
        </div>
      </div>
    </div>
  </div>
);
