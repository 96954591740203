/**
 * Created by bhadaway on 2/8/18.
 */
import * as React from "react";
import { SearchContainer } from "../../Containers/containersBarrel";
import "./conferencePaperDetailsStyles.css";
import axios from "axios";
import environment from "../../Environments/environments";
import ConferencePaperImage from "../../Assets/Images/Paper-standard-image.png";
// import TwoThousandImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2000-AMcover.png";
// import TwoThousandOneImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2001-AMcover.png";
// import TwoThousandTwoImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2002-AMcover.png";
// import TwoThousandThreeImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2003-AMcover.png";
// import TwoThousandFourImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2004-AMCover.png";
// import TwoThousandFiveImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2005-AMCover.png";
// import TwoThousandSixImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2006-AMcover.png";
// import TwoThousandSevenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2007-AMcover.png";
// import TwoThousandEightImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2008-AMcover.png";
// import TwoThousandNineImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2009-AMcover.png";
// import TwoThousandTenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2010-AMcover.png";
// import TwoThousandElevenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2011-AMcover.png";
// import TwoThousandTwelveImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2012-AMcover.png";
// import TwoThousandThirteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2013-AMcover.png";
// import TwoThousandFourteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2014-AMcover.png";
// import TwoThousandFifteenImageA from "../../Assets/Images/SearchResultsPlaceholderImages/2015-AMcover.png";
// import TwoThousandSixteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2016-AMcover.png";
// import TwoThousandSeventeenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2017-AMcover.png";
// import TwoThousandEighteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2018-AMcover.png";
// import TwoThousandNineteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2019-AMcover.jpg";
// import TwoThousandTwentyImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2020-AMcover.png";
// import TwoThousandTwentyOneImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2021-AMcover.png";
// import TwoThousandTwentyTwoImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2022-AMcover.png";
// import TwoThousandTwentyThreeImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2023-AMcover.png";

// import TwoThousandImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2000-IMcover.jpg";
// import TwoThousandOneImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2001-IMcover.jpg";
// import TwoThousandTwoImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2002-IMcover.jpg";
// import TwoThousandThreeImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2003-IMcover.jpg";
// import TwoThousandFourImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2004-IMcover.jpg";
// import TwoThousandFiveImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2005-IMcover.jpg";
// import TwoThousandSixImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2006-IMcover.jpg";
// import TwoThousandSevenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2007-IMcover.jpg";
// import TwoThousandEightImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2008-IMcover.jpg";
// import TwoThousandNineImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2009-IMcover.jpg";
// import TwoThousandTenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2010-IMcover.jpg";
// import TwoThousandElevenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2011-IMcover.jpg";
// import TwoThousandTwelveImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2012-IMcover.jpg";
// import TwoThousandThirteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2013-IMcover.jpg";
// import TwoThousandFourteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2014-IMcover.jpg";
// import TwoThousandFifteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2015-IMcover.jpg";
// import TwoThousandSixteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2016-IMcover.jpg";
// import TwoThousandSeventeenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2017-IMcover.jpg";
// import TwoThousandEighteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2018-IMcover.jpg";
// import TwoThousandNineteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2019-IMcover.png";
// import TwoThousandTwentyTwoImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2022-IMcover.png";
// import TwoThousandTwentyThreeImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2023-IMcover.png";

import { Link } from "react-router-dom";
import Moment from "moment";
import queryString from 'query-string';

class ConferencePaperDetails extends React.Component {
  constructor(props) {
    super(props);

    let search = (this.props.location.search !== '') ? queryString.parse(this.props.location.search) : {search: ''};

    this.state = {
      id: this.props.match.params.Id,
      conferencePaperData: [],
      loading: false,
      searchTerm: search.search
    };

    this.loadResultsFromServer = this.loadResultsFromServer.bind(this);
  }

  async loadResultsFromServer() {
    this.setState({
      loading: true
    });

    await axios
      .get(environment.baseUrl + "/api/conference/" + this.state.id, {
        params: {}
      })
      .then(response => {
        this.setState(
          {
            conferencePaperData: response.data,
            loading: false,
            year: response.data.year,
            meetingType: response.data.meetingType
          },
          () => {}
        );
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadResultsFromServer();
  }

  returnPresenters(conferencePaperData) {
    if (conferencePaperData.coPresenterNames) {
      return (
        <div>
          <span>
            <strong>Presenter(s): </strong>
          </span>
          {this.state.conferencePaperData.presenterFullName},{" "}
          {conferencePaperData.coPresenterNames}
        </div>
      );
    } else {
      return (
        <div>
          <span>
            <strong>Presenter(s): </strong>
          </span>
          {this.state.conferencePaperData.presenterFullName}
        </div>
      );
    }
  }

  returnSubjects(conferencePaperData) {
    if (conferencePaperData.subjects) {
      var subjects = String(conferencePaperData.subjects).split("|");
      return (
        <div>
          <span>
            <strong>Subject(s): </strong>
          </span>
          {subjects.map(function(subject, index) {
            return (
              <Link
                key={index}
                to={
                  `/home/searchResults/` +
                  subject.trim().replace(/\//g, "%2F") +
                  "?kw=subsearch&reset=true"
                }
              >
                {subject.trim()}
                {index < subjects.length - 1 ? ", " : ""}
              </Link>
            );
          })}
        </div>
      );
    }
  }

  returnBackToSearchResults() {
    if (this.props.history.length <= 1) {
      return ''
    }

    return (
      <Link to={'#'} onClick={(e) => {
          e.preventDefault();
          this.props.history.goBack();
      }}>
        Back to Search Results
      </Link>
    );
  }

  returnImage(year, meetingType) {
    if (meetingType === "International") {
      if (year === 2000) {
        return TwoThousandImageIM;
      } else if (year === 2001) {
        return TwoThousandOneImageIM;
      } else if (year === 2002) {
        return TwoThousandTwoImageIM;
      } else if (year === 2003) {
        return TwoThousandThreeImageIM;
      } else if (year === 2004) {
        return TwoThousandFourImageIM;
      } else if (year === 2005) {
        return TwoThousandFiveImageIM;
      } else if (year === 2006) {
        return TwoThousandSixImageIM;
      } else if (year === 2007) {
        return TwoThousandSevenImageIM;
      } else if (year === 2008) {
        return TwoThousandEightImageIM;
      } else if (year === 2009) {
        return TwoThousandNineImageIM;
      } else if (year === 2010) {
        return TwoThousandTenImageIM;
      } else if (year === 2011) {
        return TwoThousandElevenImageIM;
      } else if (year === 2012) {
        return TwoThousandTwelveImageIM;
      } else if (year === 2013) {
        return TwoThousandThirteenImageIM;
      } else if (year === 2014) {
        return TwoThousandFourteenImageIM;
      } else if (year === 2015) {
        return TwoThousandFifteenImageIM;
      } else if (year === 2016) {
        return TwoThousandSixteenImageIM;
      } else if (year === 2017) {
        return TwoThousandSeventeenImageIM;
      } else if (year === 2018) {
        return TwoThousandEighteenImageIM;
      } else if (year === 2019) {
        return TwoThousandNineteenImageIM;
      } else if (year === 2022) {
        return TwoThousandTwentyTwoImageIM;
      } else if (year === 2023) {
        return TwoThousandTwentyThreeImageIM;
      } else {
        return ConferencePaperImage;
      }
    } else if (meetingType === "Annual") {
      if (year === 2000) {
        return TwoThousandImageAM;
      } else if (year === 2001) {
        return TwoThousandOneImageAM;
      } else if (year === 2002) {
        return TwoThousandTwoImageAM;
      } else if (year === 2003) {
        return TwoThousandThreeImageAM;
      } else if (year === 2004) {
        return TwoThousandFourImageAM;
      } else if (year === 2005) {
        return TwoThousandFiveImageAM;
      } else if (year === 2006) {
        return TwoThousandSixImageAM;
      } else if (year === 2007) {
        return TwoThousandSevenImageAM;
      } else if (year === 2008) {
        return TwoThousandEightImageAM;
      } else if (year === 2009) {
        return TwoThousandNineImageAM;
      } else if (year === 2010) {
        return TwoThousandTenImageAM;
      } else if (year === 2011) {
        return TwoThousandElevenImageAM;
      } else if (year === 2012) {
        return TwoThousandTwelveImageAM;
      } else if (year === 2013) {
        return TwoThousandThirteenImageAM;
      } else if (year === 2014) {
        return TwoThousandFourteenImageAM;
      } else if (year === 2015) {
        return TwoThousandFifteenImageA;
      } else if (year === 2016) {
        return TwoThousandSixteenImageAM;
      } else if (year === 2017) {
        return TwoThousandSeventeenImageAM;
      } else if (year === 2018) {
        return TwoThousandEighteenImageAM;
      } else if (year === 2019) {
        return TwoThousandNineteenImageAM;
      } else if (year === 2020) {
        return TwoThousandTwentyImageAM;
      } else if (year === 2021) {
        return TwoThousandTwentyOneImageAM;
      } else if (year === 2022) {
        return TwoThousandTwentyTwoImageAM;
      } else if (year === 2023) {
        return TwoThousandTwentyThreeImageAM;
      } else {
        return ConferencePaperImage;
      }
    } else {
      return ConferencePaperImage;
    }
  }

  getConferencePaperDocumentLink() {
    if (this.state.conferencePaperData.conferencePaperDocumentURL) {
      const url = this.state.conferencePaperData.conferencePaperDocumentURL
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          Read the Paper (PDF)
        </a>
      )
    }

    return ''
  }

  getConferencePresentationDocumentLink() {
    if (this.state.conferencePaperData.conferencePresentationDocumentURL) {
      const url = this.state.conferencePaperData.conferencePresentationDocumentURL
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          See the Presentation (PDF)
        </a>
      )
    }

    return ''
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="sk-circle">
          <div className="sk-circle1 sk-child" />
          <div className="sk-circle2 sk-child" />
          <div className="sk-circle3 sk-child" />
          <div className="sk-circle4 sk-child" />
          <div className="sk-circle5 sk-child" />
          <div className="sk-circle6 sk-child" />
          <div className="sk-circle7 sk-child" />
          <div className="sk-circle8 sk-child" />
          <div className="sk-circle9 sk-child" />
          <div className="sk-circle10 sk-child" />
          <div className="sk-circle11 sk-child" />
          <div className="sk-circle12 sk-child" />
        </div>
      );
    }
    return (
      <div className="ConferencePaperDetailsPage">
        <div className="BackgroundImageSection">
          <div className="SearchAndTitleContainer">
            <SearchContainer
              className="Search"
              location={this.props.location.pathname}
              detailsSearchTerm={this.state.searchTerm}
              searchDropdownValue={sessionStorage.getItem("searchDropdownValue")}
            />
          </div>
        </div>
        <div className="container ContentSection">
          <div className="ContentContainer">
            <div className="BackButtonContainer">
              {this.returnBackToSearchResults()}
            </div>
            <div className="row ImageAndDetailsRow">
              <div className="col-xs-3 ImageSection">
                <div className="ConferencePaperImageContainer">
                  <img
                    id="ConferencePaperImage"
                    src={this.state.conferencePaperData.imgUrl || ConferencePaperImage}
                    alt="Conference Paper"
                  />
                </div>
              </div>
              <div className="col-xs-9 DetailsSection">
                <div className="DetailsContainer">
                  <div>
                    <span>
                      <strong>Presentation Title: </strong>
                    </span>
                    {this.state.conferencePaperData.title}
                  </div>
                  {this.returnPresenters(this.state.conferencePaperData)}
                  <div>
                    <span>
                      <strong>Presentation Date: </strong>
                    </span>
                    {this.state.conferencePaperData.presentationDate
                      ? Moment(
                          this.state.conferencePaperData.presentationDate
                        ).format("YYYY-MM-DD")
                      : ""}
                  </div>
                  <div>
                    <span>
                      <strong>Program Unit: </strong>
                    </span>
                    {this.state.conferencePaperData.programUnit}
                  </div>
                  <div>
                    <span>
                      <strong>Meeting Name: </strong>
                    </span>
                    {this.state.conferencePaperData.meetingName}
                  </div>
                  <div>
                    <span>
                      <strong>Meeting Location: </strong>
                    </span>
                    {this.state.conferencePaperData.meetingLocation}
                  </div>
                  {this.returnSubjects(this.state.conferencePaperData)}
                  <div>
                    <span>
                      <strong>Abstract: </strong>
                    </span>
                    {this.state.conferencePaperData.abstract}
                  </div>
                  <div>
                    {this.getConferencePaperDocumentLink()}
                  </div>
                  <div>
                    {this.getConferencePresentationDocumentLink()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { ConferencePaperDetails };
