import React, { Fragment } from 'react'
import axios from 'axios'
import environment from "../../Environments/environments";
import DataCallerBaseComponent from '../../Components/Core/dataCallerBaseComponent'

import { Checkbox } from 'react-bootstrap';
import { AdminButton } from '../../Components/AdminButton/adminButton'
import { SelectField } from '../../Components/componentsBarrel'
import { getBusyIndicator } from '../../Helpers/busyIndicator'

import './journalNotificationTest.css'

export class JournalNotificationTest extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        let alternate = environment.defaultNotificationEmail ? environment.defaultNotificationEmail : ''

        this.journals = [{id: 0, title: 'Select an Option'}]

        this.state = {
            recipient: '',
            alternate: alternate,
            bccAdmin: false,
            bccEmail: this.user.emailAddress,
            journalId: 0,
            ignoreInternalStaffApproval: false,
            loading: false
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.setState({loading: true}, () => this.loadData())
    }

    loadData() {
        let promises = []

        promises.push(this.loadJournals())

        Promise.all(promises)
            .then(values => {
                this.setState({loading: false})
            })
    }

    loadJournals() {
        //debugger;
        return axios.get(environment.baseUrl + '/api/journals/enumerate', this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                var data = this.journals.concat(response.data.journals)
                this.journals = []

                data.forEach(x => {if (x.isActive === true) this.journals.push(x)})

                return response.data
            })
            .catch(error => {
                console.error('Error loading journal data: ', error, error.response)
                alert('Error Loading Journal Data')

                this.journals = []
            })
    }

    onSendProductionNotification = async () => {
        if (this.validate() === false) {
            return;
        }

        if (window.confirm('Are you sure you wish to trigger the journal notification process now?')) {
            let data = this.getNotificationParams()
            data.redirectEmail = ''

            await this.triggerNotificationProcess(data)
        }
    }

    onSubmit = async (e, special) => {
        if (this.validate()) {
            let data = this.getNotificationParams()

            if (special === 'TEXTONLY') {
                if (window.confirm('This will trigger the notification but only send the text version of the email for testing purposes.  Continue?')) {
                    data.IsTextOnlyEmail = true
                    await this.triggerNotificationProcess(data)
                }
            }
            else if (window.confirm('Are you sure you wish to trigger the journal notification process now?')) {
                await this.triggerNotificationProcess(data)
            }
        }
    }

    getNotificationParams() {
        let params = {
            memberEmail: this.state.recipient,
            redirectEmail: this.state.alternate,
            bccEmail: this.state.bccAdmin ? this.state.bccEmail : '',
            journalId: this.state.journalId,
            ignoreInternalStaffApproval: this.state.ignoreInternalStaffApproval
        }

        return params
    }

    async triggerNotificationProcess(data) {
        let uri = `${environment.baseUrl}/api/trigger-journal-notifications`

        return axios.post(uri, data, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.error)
                    return
                }

                let message = `Process returned successfully.  ${response.data.count} email(s) were sent.`
                alert(message)
            })
            .catch(error => {
                console.error('Error calling journal notification trigger: ', error, error.message)
                alert('Error calling journal notification trigger: ' + error.message)
            })
    }

    validate() {
        if (this.state.journalId < 1) {
            alert('A journal selection is required.')
            return false
        }

        if (this.state.recipient === '') {
            alert('A member email address is required.')
            return false
        }

        const emailValidator = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        let isEmailValid = emailValidator.test(this.state.recipient)

        if (isEmailValid === false) {
            alert('Email address is not valid. Please try again.')
            return false
        }

        if (this.state.alternate !== '') {
            if (emailValidator.test(this.state.alternate) === false) {
                alert('Alternate email address is not valid.  Please try again.')
                return false
            }
        }

        return true
    }

    handleInputChanges = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    onTriggerAuto = (e, special) => {
        let message = 'This will trigger the notification process.  Continue?'

        if (special === 'failure') {
            message = 'This will trigger the notification process but induce a failure to test the confirmation email.  Continue?'
        }

        if (window.confirm(message)) {
            axios.post(`${environment.baseUrl}/api/autotrigger-journal-notifications/${special}`, null, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                alert('Process called.')
            })
            .catch(error => {
                console.error('Error calling auto-notification: ', error, error.message)
                alert('Error calling auto-notification: ' + error.message)
            })
        }
    }

    getTestingOptions() {
        if (process.env.REACT_APP_ENVIRONMENT === 'SBLQA' || !process.env.REACT_APP_ENVIRONMENT) {
            return (
                <div className="advanced-options">
                    <hr/>
                    <div className="advanced-title">
                        Testing Options (QA Only)
                    </div>

                    <div className="testing-options-desc">
                        The functions in this section are designed to assist in testing efforts.  They will only appear in the QA environment.
                    </div>

                    <div className="recipient">
                        <label>QA Destination - Divert Resulting Notification Email(s) to Alternate Address:&nbsp;&nbsp;</label>
                        <br/>
                        <input name='alternate'
                            maxLength="50"
                            value={this.state.alternate}
                            onChange={this.handleInputChanges}
                            size="50"
                            type="email"
                        />
                    </div>

                    <div>
                        <Checkbox name="ignoreInternalStaffApproval" 
                            checked={this.state.ignoreInternalStaffApproval} 
                            onChange={this.onCheckboxChange}
                            id="ignoreInternalStaffApproval"
                        >
                            <b>For QA only. Send most recent issue with at least one article regardless of whether issue is approved for member notification, but only if the member with Member Email above has selected the Journal for notifications.</b>
                        </Checkbox>
                    </div>

                    <div className="button-section">
                        <div className="qa-button">
                            <div>
                                This button processes a single notification just like the "Send Notification" button in the production system but diverts to the resulting notification email to the specified QA destination.
                            </div>
                            <AdminButton onClick={this.onSubmit}>QA Send Notification</AdminButton>
                        </div>

                        <div className="qa-button">
                            <div>
                                This button will trigger the notification process using the settings in the production 
                                section above, but will only send the text version of the email.
                            </div>
                            <AdminButton onClick={(e) => this.onSubmit(e, 'TEXTONLY')}>QA Send Notification - Text Only Email</AdminButton>
                        </div>

                        <div className="qa-button">
                            <div>
                                This button will throw a coding exception to test that an error email is sent to siteadmin.
                            </div>
                            <AdminButton onClick={(e) => this.onTriggerAuto(e, 'failure')}>Trigger Induced Failure</AdminButton>
                        </div>

                        <div className="qa-button">
                            <div>
                                This button will trigger the full journal notification process and divert any pending notification emails to the QA Destination address above.  
                                This process does not honor the selections in the production section above.
                            </div>
                            <AdminButton onClick={(e) => this.onTriggerAuto(e, 'none')}>Trigger Full Journal Notification Process</AdminButton>
                        </div>

                    </div>
                </div>
            )
        }

        return ''
    }

    getProductionSection() {
        return (
            <Fragment>
                <div className="notification-title">
                    <h2>Process Journal Notifications</h2>
                    <div className="journal-notification-desc">
                        This page allows you to trigger the standard journal notifications email for the specified member. A notification for the most recently approved issue of the selected journal will be sent.
                    </div>
                </div>

                <div className="journal-select-section">
                    <SelectField name="journalId"
                        Title="Journal"
                        data={this.journals}
                        onChange={this.handleInputChanges}
                        value={this.state.journalId}
                        displayValue="id"
                        displayName="title"
                        required
                        autoFocus
                    />
                </div>

                <div className="recipient">
                    <label>Member Email:&nbsp;&nbsp;</label>
                    <input name='recipient'
                        maxLength="50"
                        value={this.state.recipient}
                        onChange={this.handleInputChanges}
                        size="50"
                        type="email"
                        autoFocus
                    />
                    <Checkbox name="bccAdmin" checked={this.state.bccAdmin} onChange={this.onCheckboxChange}>Send blind courtesy copy of generated email to you?</Checkbox>
                </div>

                <div className="button-section">
                    <AdminButton onClick={this.onSendProductionNotification}>Send Notification</AdminButton>
                </div>
            </Fragment>
        )
    }

    getRendering() {
        return (
            <div className="notification-test">
                <div className="notification-content">
                    {this.getProductionSection()}
                    {this.getTestingOptions()}                   
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="journal-notifications-page">
                {getBusyIndicator(this.state.loading,this.getRendering())}
            </div>
        )
    }
}
