import React from 'react'
import Modal from 'react-modal'

import '../Styles/dialogButtons.css'
import './sessionClosedDialog.css'

export class SessionClosedDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modalIsOpen: false
        }
    }

    onRequestClose = () => {
        this.closeModal()
    }

    handleSubmit = () => {
        this.closeModal()
    }

    showModal = (props) => {
        this.setState({
            modalIsOpen: true
        })
    }

    closeModal() {
        this.setState({
            modalIsOpen: false
        })
    }

    render() {
        return (
            <main>
                <div className="session-closed-modal">
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="SessionClosedCustomModal session-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>Session Closed</h2>

                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                To keep your information secure, you have been signed out due to inactivity.
                            </div>
                            <div className="dialogButtons">
                                <button className="default-button">OK</button>
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
