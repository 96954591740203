import * as React from 'react';

import { BookStatusFilter } from '../componentsBarrel'

import './adminFilterBar.css';

export const AdminFilterBar = (props) => (
    <form className="AdminFilterBar">
      <div className="FilterTitle">Filter</div>
      <div className="PublicationDateSection PadBottom10">

      <div className="bookStatusFilterSection">
        <BookStatusFilter onFilterClick={props.onFilterClick} filterData={props.filterData}/>
      </div>

        <div className="PublicationDateTitle PadTop10 PadBottom10">
          <div className="PublicationDateTitleText">Years</div>
        </div>
        <div className="PublicationDateContainer">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="defaultCheck20"
              checked={props.filterData.twentyTwentyOneToPresent}
              onChange={() => props.onFilterClick('twentyTwentyOneToPresent')}
            />
            <label className="form-check-label" htmlFor="defaultCheck20">
              2021 - Present
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="defaultCheck1"
              checked={props.filterData.twentySixteenToTwentyTwenty}
              onChange={() => props.onFilterClick('twentySixteenToTwentyTwenty')}
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              2016 - 2020
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="defaultCheck2"
              checked={props.filterData.twentyElevenToTwentyFifteen}
              onChange={() => props.onFilterClick('twentyElevenToTwentyFifteen')}
            />
            <label className="form-check-label" htmlFor="defaultCheck2">
              2011 - 2015
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="defaultCheck3"
              checked={props.filterData.twoThousandSixToTwentyTen}
              onChange={() => props.onFilterClick('twoThousandSixToTwentyTen')}
            />
            <label className="form-check-label" htmlFor="defaultCheck3">
              2006 - 2010
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="defaultCheck4"
              checked={props.filterData.twoThousandOneToTwoThousandFive}
              onChange={() => props.onFilterClick('twoThousandOneToTwoThousandFive')}
            />
            <label className="form-check-label" htmlFor="defaultCheck4">
              2001 - 2005
            </label>
          </div>
          {!props.isSeeMoreYearsHidden && <MoreYears filterData={props.filterData} isSeeMoreYearsHidden={props.isSeeMoreYearsHidden} onFilterClick={props.onFilterClick} />}
        </div>
      </div>
    </form>
);

class MoreYears extends React.Component {
  render() {
    return (
      <div className="ViewMore PadBottom10">
        {
          <MoreYearsDiv filterData={this.props.filterData} onFilterClick={this.props.onFilterClick} />
        }
      </div>
    )
  }
}

const MoreYearsDiv = (props) => (
  <div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="defaultCheck12"
        checked={props.filterData.ninetySixToTwoThousand}
        onChange={() => props.onFilterClick('ninetySixToTwoThousand')}
      />
      <label className="form-check-label" htmlFor="defaultCheck12">
        1996 - 2000
      </label>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="defaultCheck13"
        checked={props.filterData.ninetyOneToNinetyFive}
        onChange={() => props.onFilterClick('ninetyOneToNinetyFive')}
      />
      <label className="form-check-label" htmlFor="defaultCheck13">
        1991 - 1995
      </label>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="defaultCheck14"
        checked={props.filterData.eightySixToNinety}
        onChange={() => props.onFilterClick('eightySixToNinety')}
      />
      <label className="form-check-label" htmlFor="defaultCheck14">
        1986 - 1990
      </label>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="defaultCheck15"
        checked={props.filterData.eightyOneToEightyFive}
        onChange={() => props.onFilterClick('eightyOneToEightyFive')}
      />
      <label className="form-check-label" htmlFor="defaultCheck15">
        1981 - 1985
      </label>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="defaultCheck16"
        checked={props.filterData.seventySixToEighty}
        onChange={() => props.onFilterClick('seventySixToEighty')}
      />
      <label className="form-check-label" htmlFor="defaultCheck16">
        1976 - 1980
      </label>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="defaultCheck17"
        checked={props.filterData.seventyOneToSeventyFive}
        onChange={() => props.onFilterClick('seventyOneToSeventyFive')}
      />
      <label className="form-check-label" htmlFor="defaultCheck17">
        1971 - 1975
      </label>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="defaultCheck18"
        checked={props.filterData.uptoSeventy}
        onChange={() => props.onFilterClick('uptoSeventy')}
      />
      <label className="form-check-label" htmlFor="defaultCheck18">
        Up to 1970
      </label>
    </div>
    {/* <div className="ViewMore">
      <a onClick={() => props.showLessYears()}>See Less</a>
    </div> */}
  </div>
)