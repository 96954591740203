import * as React from 'react';
import './adminButton.css'

export const AdminButton = props => {
    return (
        <button onClick={props.onClick} className="adminButton">
            {props.children}
        </button>
    )
}
