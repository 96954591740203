import React from 'react'
import Modal from 'react-modal'
import { ExpertiseModalContent } from './expertiseModalContent';

import '../Styles/dialogButtons.css'
import './expertiseModal.css'

export class ExpertiseModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bookSubjects: props.subjects ? props.subjects : [],
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.isDataValid() === false) {
            // display alert if error messages needed
            return false;
        }
        else {
            this.onRequestClose(e);
            this.props.onClose(this.state.bookSubjects, this.props.parent);
            return true;
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();
        this.props.onRequestClose(e);
    }

    isDataValid() {
        // Validate data here and return false if not valid
        return true;
    }

    render() {
        return (
            <main>
                <div className="book-expertise-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="BookExpertiseCustomModal book-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <form onSubmit={this.handleSubmit}>
                            <h2>Subjects</h2>

                            <ExpertiseModalContent
                                subjects={this.state.bookSubjects}
                            />

                            <div className="dialogButtons">
                                <button className="default-button">Save</button>
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
