import React from 'react'
import { BookDetails } from '../../Components/componentsBarrel'
import { SearchContainer } from '../containersBarrel'
import { BookStatusEnum } from '../../Helpers/bookStatusHelper'

export class BookDetailsContainer extends React.Component {

    getBookEditLink = (host) => {}

    returnVolunteerEditLink = (host) => {
        if (host.state.bookData.isAcceptingVolunteers === true
            && (host.state.bookData.bookStatusId === BookStatusEnum.RBLApproved || host.state.bookData.bookStatusId === BookStatusEnum.RBLPublished)) {
                return (
                    <div>
                    <hr />
                    {host.returnVolunteerLink("", host.state.loggedInUser)}
                    </div>
                );
        }
    }

    getSearchContainer = (host) => {
        return (
            <SearchContainer
                className="Search"
                location={host.props.location.pathname}
                detailsSearchTerm={host.state.searchTerm}
                searchDropdownValue={sessionStorage.getItem("searchDropdownValue")}
            />
        )
    }

    getBookStatusDisplay = (host) =>{}
    
    render() {
        return (
            <BookDetails
                location = {this.props.location}
                match = {this.props.match}
                history = {this.props.history}
                returnVolunteerEditLink = {this.returnVolunteerEditLink}
                returnBookEditLink = {this.getBookEditLink}
                basePath = {'/home'}
                getSearchContainer = {this.getSearchContainer}
                calculateBackgroundImageClass = {() => 'BackgroundImageSection BackgroundEmoryImage'}
                getBookStatusDisplay = {this.getBookStatusDisplay}
            />
        )
    }
}
