import React from 'react'
import { Link } from 'react-router-dom'
import { BookDetails, AdminSearchComponent } from '../../Components/componentsBarrel'
import { guardAdmin } from '../../Components/Admin/admin-base'
import { BookStatusHelper } from '../../Helpers/bookStatusHelper'

export class AdminBookDetailsContainer extends React.Component {

    getSearchContainer = (host) => {
        return (
            <AdminSearchComponent
                className="Search"
                location={host.props.location.pathname}
                searchTerm={host.state.searchTerm}
                searchDropdownValue={sessionStorage.getItem("searchDropdownValue")}
            />
        )
    }

    getBookEditLink = (host) => {
        return (
            <div>
                <span>
                    <Link to={`/admin/bookEditor/` + host.state.id}>Edit Book Detail</Link>
                    <br/>
                </span>   
            </div>
        );
    }

    getVolunteerEditLink = (host) => {}

    calculateBookStatusBlock(host) {
        return (
            <div>
                <strong>Status: </strong>{BookStatusHelper.translateStatus(host.state.bookData.bookStatusId)}
            </div>
        )
    }

    getBookStatusDisplay = (host) => {
        const created = new Date(host.state.bookData.created)
        return (
            <div>
                <div>
                    <strong>Entered: </strong>{created.toLocaleDateString()}&nbsp;{created.toLocaleTimeString()}
                </div>
                {this.calculateBookStatusBlock(host)}
            </div>
        )
    }
    
    render() {
        return guardAdmin(
            <BookDetails
                location = {this.props.location}
                match = {this.props.match}
                history = {this.props.history}
                returnVolunteerEditLink = {this.getVolunteerEditLink}
                returnBookEditLink = {this.getBookEditLink}
                basePath = {'/admin'}
                getSearchContainer = {this.getSearchContainer}
                calculateBackgroundImageClass = {() => 'BackgroundImageSection BackgroundNoImage'}
                getBookStatusDisplay = {this.getBookStatusDisplay}
            />
        )
    }
}
