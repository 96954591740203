import React from 'react'
import { BookStatusFilterEnum } from '../../Helpers/bookStatusHelper'
import './bookStatusFilter.css'

export class BookStatusFilter extends React.Component {
    render() {
        return (
            <div className="BookStatusFilter">
                <div className="BookStatusFilterTitle">
                    Status
                </div>
                <div className="BookStatusFilterContent">
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="All"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.All}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.All)}
                            />
                            All
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="PendingSBLCApproval"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.PendingSBLCApproval}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.PendingSBLCApproval)}
                            />
                            Pending SBLC Approval
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="PendingRBLApproval"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.PendingRBLApproval}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.PendingRBLApproval)}
                            />
                            Pending RBL Approval
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="PendingSubjectTag"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.PendingSubjectTag}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.PendingSubjectTag)}
                            />
                            Pending Subject Tag
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="RBLApproved"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.RBLApproved}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.RBLApproved)}
                            />
                            RBL Approved
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="RBLPublished"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.RBLPublished}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.RBLPublished)}
                            />
                            RBL Published
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="SBLCRejected"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.SBLCRejected}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.SBLCRejected)}
                            />
                            SBLC Rejected
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="RBLRejected"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.RBLRejected}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.RBLRejected)}
                            />
                            RBL Rejected
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input type="radio"
                                name="book-status-filter"
                                value="Withdrawn"
                                checked={this.props.filterData.bookStatusFilter === BookStatusFilterEnum.Withdrawn}
                                className="book-status-check-input"
                                onChange={(e) => this.props.onFilterClick('bookStatusFilter', 
                                    BookStatusFilterEnum.Withdrawn)}
                            />
                            Withdrawn by Publisher
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}
