import * as React from "react";
import { SearchComponent } from "../../Components/componentsBarrel";

import "./searchContainerStyles.css";

export class SearchContainer extends React.Component {
  returnSearchComponent = () => {
    if (this.props.resetLocalStorage === "true") {
      sessionStorage.setItem("searchDropdownValue", "All");
    }

    if (this.props.detailsSearchTerm) {
      return (
        <SearchComponent
          myUrl={this.props.myUrl}
          resetSearchFilters={this.resetSearchFilters}
          keywordType={this.props.keywordType}
          updateSearchTerm={this.props.updateSearchTerm}
          searchTerm={this.props.detailsSearchTerm}
        />
      );
    } else {
      return (
        <SearchComponent
          myUrl={this.props.myUrl}
          resetSearchFilters={this.resetSearchFilters}
          keywordType={this.props.keywordType}
          updateSearchTerm={this.props.updateSearchTerm}
          searchTerm={this.props.searchTerm}
        />
      );
    }
  };

  resetSearchFilters = resetValue => {
    if (resetValue === true) {
      sessionStorage.setItem("scrollPosition", 0);
    }
  };

  render() {
    return <div className="SearchSection">{this.returnSearchComponent()}</div>;
  }
}
