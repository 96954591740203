import React, { Fragment } from 'react'
import axios from 'axios'
import environment from "../../Environments/environments"
import ModalRoot from "../../Containers/Login/loginRoot"
import ReactExport from "react-data-export"
import DatePicker from 'react-datepicker'
import ReactTooltip from 'react-tooltip'
import { Helmet } from 'react-helmet'

import { RBLExportHistoryModal } from './rblExportHistoryModal'
import { ModalConsumer } from '../../Containers/Login/loginContext'
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import { CustomDateInput } from '../componentsBarrel'

import "react-datepicker/dist/react-datepicker.css";
import './rblExport.css'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class RBLExport extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(sessionStorage.getItem('loggedInUser'))
        if (!user || user === null) {
            window.location = '/'
            return
        }

        this.config = DefaultConfig.getDefaultConfig()

        const userName = user.firstName + ' ' + user.lastName

        this.state = {
            data: null,
            loading: false,
            userName: userName
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        let fromDate = new Date()
        let toDate = new Date()

        fromDate.setDate(fromDate.getDate())
        toDate.setDate(toDate.getDate())

        this.setfromDate(fromDate)
        this.settoDate(toDate)
    }

    setfromDate = (date) => {
        date.setHours(0, 0, 0, 0)

        this.setState({
            fromDate: date,
            fromDateText: date.toLocaleString('en-US', options) + ' 12:00 AM'
        })
    }

    settoDate = (date) => {
        date.setHours(23, 59, 59, 999)

        this.setState({
            toDate: new Date(date),
            toDateText: date.toLocaleString('en-US', options) + ' 11:59:59.999 PM'
        })
    }

    onCancel = (e) => {
        window.location = '/admin/reports'
    }

    validate() {
        if (this.state.toDate < this.state.fromDate) {
            alert('To Date cannot be prior to From Date')
            return false
        }

        let tomorrow = new Date()
        tomorrow.setHours(0, 0, 0, 0)
        tomorrow.setDate(tomorrow.getDate() + 1)

        if(this.state.toDate > tomorrow) {
            alert('To Date cannot be after current date')
            return false
        }

        return true
    }

    onSubmit = (e) => {
        this.setState({data: null}, () => this.updateDataDisplay())
    }

    updateDataDisplay() {
        this.setState({loading: true}, () => this.processData())
    }

    processData() {
        if (!this.validate()) {
            this.setState({loading: false})
            return
        }

        let data = {
            fromDate: this.state.fromDate.toLocaleString(),
            toDate: this.state.toDate.toLocaleString()
        }

        let url = `${environment.baseUrl}/api/admin/rblExport`

        axios.post(url, data, this.config)
            .then(response => {
                if (response.data.success === false) {
                    alert('Error processing export data: ' + response.data.error)
                    this.setState({loading: false})
                    return
                }

                //console.debug('Export Data: ', response.data.exportData)

                this.setState({
                    data: response.data.exportData,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({loading: false})
                console.error('Error getting RBL Export data: ', error)
                alert('Error getting RBL Export data: ' + error)
            })
    }

    getExportHistoryLink(showModal) {
        return (
            <a className="export-history-link" onClick={(e) => this.onExportHistory(e, showModal)}>Export History</a>
        )
    }

    getExportHistoryBlock() {
        return (
            <div>
                <span>
                    <ModalConsumer>
                        {({ showModal }) => (
                            <Fragment>
                                {this.getExportHistoryLink(showModal)}
                            </Fragment>
                        )}
                    </ModalConsumer>
                </span>
            </div>
        )
    }

    onExportHistory = (e, showModal) => {
        showModal(RBLExportHistoryModal, {})
    }

    onDownload = (e) => {
        let now = new Date()
        now.setHours(0, 0, 0, 0)

        let body = {
            exportDate: now.toLocaleDateString(),
            adminUser: this.state.userName,
            fromDate: this.state.fromDate.toLocaleString(),
            toDate: this.state.toDate.toLocaleString()
        }

        let url = `${environment.baseUrl}/api/admin/book-export-history`

        axios.post(url, body, this.config)
            .then(response => {
                if (response.data.success === false) {
                    alert('Error saving history: ' + response.data.message)
                    return
                }

                let button = document.getElementById('table-xls-button')
                button.click()
            })
            .catch(error => {
                console.error('Error saving export history', error)
                alert('Error saving export history: ' + error)
            })
    }

    ellipsisFormat(longText) {
        if (!longText || longText.length <= 25) {
            return longText
        }

        return longText.substring(0, 25) + '...'
    }

    formatTooltip(text) {
        const tipPrefix = "<p style='text-align: left; width: 600px'>"
        const tipSuffix = '</p>'

        return tipPrefix + text + tipSuffix
    }

    getDataDisplay() {
        if (!this.state.data) {
            return ''
        }

        return (
            <Fragment>
                <div className="data-display">
                    <hr/>
                    <div>
                        <a className="export-link" onClick={this.onDownload}>Export to Excel</a>
                        {this.getExcelFunctionalButton()}
                    </div>
                    <div className="data-table">
                        <table id="data-table">
                            <thead>
                                <tr className="table-head">
                                    <th>Date Entered</th>
                                    <th>Title ID</th>
                                    <th>ISBN</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    {/* <th>Price</th> */}
                                    <th>Format</th>
                                    <th>Publisher Location</th>
                                    <th>Publisher</th>
                                    <th>Copyright Year</th>
                                    <th>DefaultNoReviewCopies</th>
                                    <th>Authors</th>
                                    <th>Editors</th>
                                    <th>Translators</th>
                                    <th>Book Description</th>
                                    <th>Number of Pages</th>
                                    <th>Edition</th>
                                    <th>Series Title</th>
                                    <th>Series Number</th>
                                    <th>Account</th>
                                    <th>Subjects</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map((item, index) => {
                                    let subjectDisplay = this.ellipsisFormat(item.subjects)
                                    let subjectTooltip = this.formatTooltip(item.subjects)

                                    let descDisplay = this.ellipsisFormat(item.description)
                                    let descTooltip = this.formatTooltip(item.description)

                                    return (
                                        <tr className="table-row" key={index}>
                                            <td>{this.formatDate(item.created)}</td>
                                            <td>{item.titleId}</td>
                                            <td>{item.isbn}</td>
                                            <td>{item.title}</td>
                                            <td>{item.subTitle}</td>
                                            {/* <td>{item.price}</td> */}
                                            <td>{item.format}</td>
                                            <td>{item.publisherLocation}</td>
                                            <td>{item.publisherName}</td>
                                            <td>{item.copyrightYear}</td>
                                            <td>{item.copies}</td>
                                            <td>{item.authors}</td>
                                            <td>{item.editors}</td>
                                            <td>{item.translators}</td>
                                            <td>
                                                <p data-tip={descTooltip} data-html={true} data-multiline={false}>
                                                    {descDisplay}
                                                </p>
                                            </td>
                                            <td>{item.numberOfPages}</td>
                                            <td>{item.edition}</td>
                                            <td>{item.seriesTitle}</td>
                                            <td>{item.numberInSeries}</td>
                                            <td>{item.account}</td>
                                            <td>
                                                <p data-tip={subjectTooltip} data-html={true} data-multiline={false}>
                                                    {subjectDisplay}
                                                </p>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }

    formatDate(date) {
        return new Date(date).toLocaleDateString()
    }

    getExcelButton() {
        return (
            <button
                id = "table-xls-button"
                className = "download-table-xls-button"
            ></button>
        )
    }

    getYesterday() {
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        return yesterday
    }

    filtertoDate = (date, fromDate) => {
        let yesterday = this.getYesterday()
        return (date <= yesterday) && date >= fromDate
    }

    filterfromDate = (date) => {
        let yesterday = this.getYesterday()
        return date <= yesterday
    }

    getExcelFunctionalButton() {
        return (
            <ExcelFile element={this.getExcelButton()} filename="RBLExport">
                <ExcelSheet data={this.state.data} name="RBL Export">
                    <ExcelColumn label="Date Entered" value="created"/>
                    <ExcelColumn label="Title ID" value="titleId"/>
                    <ExcelColumn label="ISBN" value="isbn"/>
                    <ExcelColumn label="Title" value="title"/>
                    <ExcelColumn label="Subtitle" value="subTitle"/>
                    {/* <ExcelColumn label="Price" value="price"/> */}
                    <ExcelColumn label="Format" value="format"/>
                    <ExcelColumn label="Publisher Location" value="publisherLocation"/>
                    <ExcelColumn label="Publisher" value="publisherName"/>
                    <ExcelColumn label="Copyright Year" value="copyrightYear"/>
                    <ExcelColumn label="DefaultNoReviewCopies" value="copies"/>
                    <ExcelColumn label="Authors" value="authors"/>
                    <ExcelColumn label="Editors" value="editors"/>
                    <ExcelColumn label="Translators" value="translators"/>
                    <ExcelColumn label="Book Description" value="description"/>
                    <ExcelColumn label="Number of Pages" value="numberOfPages"/>
                    <ExcelColumn label="Edition" value="edition"/>
                    <ExcelColumn label="Series Title" value="seriesTitle"/>
                    <ExcelColumn label="Series Number" value="numberInSeries"/>
                    <ExcelColumn label="Account" value="account"/>
                    <ExcelColumn label="Subjects" value="subjects"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading, 
                    <div className="rbl-export">
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <ModalRoot/>
                        <ReactTooltip multiline={true}/>
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="rbl-export-container">
                                        <span className="title-text">RBL Export</span>
                                    </div>

                                    <div className="export-content">
                                        <div className="RBLExportLink">
                                            {this.getExportHistoryBlock()}
                                        </div>

                                        <div className="row rbl-date-pick">
                                            <div className="col-xs-4"><strong>From</strong></div>
                                            <div className="col-xs-5"><strong>To</strong></div>
                                        </div>

                                        <div className="row rbl-date-pick">
                                            <div className="col-xs-4">
                                                <DatePicker
                                                    selected={this.state.fromDate}
                                                    customInput={<CustomDateInput formatted={this.state.fromDateText}/>}
                                                    onChange={date => this.setfromDate(date)}
                                                    filterDate={this.filterfromDate}
                                                />
                                            </div>
                                            <div className="col-xs-5">
                                                <DatePicker
                                                    selected={this.state.toDate}
                                                    customInput={<CustomDateInput formatted={this.state.toDateText}/>}
                                                    onChange={date => this.settoDate(date)}
                                                    filterDate={date => this.filtertoDate(date, this.state.fromDate)}
                                                />
                                            </div>
                                        </div>

                                        <div className="button-section row col-xs-13 text-center">
                                            <button className="SubmitButton" onClick={this.onSubmit}>Submit</button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.getDataDisplay()}
                    </div>
                )}
            </Fragment>
        )
    }
}

const options = { 
    timeZone: "America/New_York", 
    hour12: true,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    //hour: 'numeric',
    //second: 'numeric',
    //minute: 'numeric'
};
