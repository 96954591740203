import React from 'react'
import './busyIndicator.css'

export class BusyIndicator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            busy: props.busy ? props.busy : false
        }
    }

    show() {
        this.setState({busy:true});
    }

    hide() {
        this.setState({busy:false});
    }

    render() {
        return (
            this.state.busy ? 
                <div className="busyIndicator">
                    <div className="sk-circle">
                        <div className="sk-circle1 sk-child" />
                        <div className="sk-circle2 sk-child" />
                        <div className="sk-circle3 sk-child" />
                        <div className="sk-circle4 sk-child" />
                        <div className="sk-circle5 sk-child" />
                        <div className="sk-circle6 sk-child" />
                        <div className="sk-circle7 sk-child" />
                        <div className="sk-circle8 sk-child" />
                        <div className="sk-circle9 sk-child" />
                        <div className="sk-circle10 sk-child" />
                        <div className="sk-circle11 sk-child" />
                        <div className="sk-circle12 sk-child" />
                    </div>
                </div>
            : null
        )
    }
}
