import React, { Fragment } from 'react'
import { ListField } from '../componentsBarrel'
import { ModalConsumer } from "../../Containers/Login/loginContext";
import { CollectedWorksEssaysModal } from '../CollectedWorksEssaysModal/collectedWorksEssaysModal';

import './collectedWorksEssays.css'

export class CollectedWorksEssays extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            essays: props.essays ? props.essays : [],
            loading: false
        }

        //console.debug('passed essays: ', props.essays)
    }

    getEssayDisplay = (item, index, modal, showModal) => {
        const {authors, editors, translators} = this.sortContributors(item.contributors)

        return (
            <span key={index}>
                <div>
                    <a onClick={(e) => this.onAddEditButtonClick(e, showModal, item, index)}>
                        {item.title}
                    </a>
                </div>
                <div className="essay-contributor-block">
                    {this.getContributorListing('author', authors)}
                    {this.getContributorListing('editor', editors)}
                    {this.getContributorListing('translator', translators)}
                </div>
            </span>
        )
    }

    getContributorListing(name, people) {
        if (people.length > 0) {
            return (
                <div className="essay-contributor-block-list">
                    {name}: {this.getContributorNameList(people)}
                </div>
            )
        }

        return ''
    }

    sortContributors(contributors) {
        let authors = []
        let editors = []
        let translators = []

        contributors = contributors ? contributors : []

        contributors.forEach(x => {
            let name = `${x.firstName} ${x.lastName}`
            if (x.isAuthor) authors.push(name)
            if (x.isEditor) editors.push(name)
            if (x.isTranslator) translators.push(name)
        })

        return {authors, editors, translators}
    }

    getContributorNameList(array) {
        if (array.length === 1) {
            return array[0]
        }

        let divider = ' and '
        let result = ''

        for(let i = array.length-1; i >= 0; --i) {
            result = array[i] + result;
            if ( i > 0) {
                result = divider + result
                divider = ', '
            }
        }

        return result
    }

    onAddEditButtonClick = (e, showModal, item, index) => {
        e.preventDefault()

        let self = this

        let save = (data) => {
            let array = this.state.essays

            if (data.deleteRequested) {
                array.splice(index, 1)
            }
            else if (data.edit) {
                array[index] = data.essay
            }
            else {
                array.push(data.essay)
            }

            self.setState({
                essays: array
            })
        }

        showModal(CollectedWorksEssaysModal, {onSave: save, parent: self, essay: item})
    }

    render() {
        if (this.state.loading) {
            return ""
        }

        return (
            <div className="select-title">
                <span className="select-title-text"><strong>Collected Works / Essays</strong></span>
                <div>
                    <span>
                        <ModalConsumer>
                            {({ showModal }) => (
                                <Fragment>
                                    <div id="edit-essay-button-section">
                                        <button className="EditButton" 
                                            onClick={(e) => this.onAddEditButtonClick(e, showModal)}>
                                                Add Essay
                                        </button>
                                    </div>

                                    <div className="col-xs-10">
                                        <ListField name="essayList"
                                            data={this.state.essays}
                                            displayitem={this.getEssayDisplay}
                                            showModal={showModal}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </ModalConsumer>
                    </span>
                </div>
            </div>
        )
    }
}
