import React, { Fragment } from 'react'
import './accountSelect.css'

const MainAccount = (props) => {
    return (
        <option className="account-select-main" key={`AA ${props.account}`} value={props.account} name={props.item.name}>
            {props.item.name}
        </option>
    )
}

const ImprintAccounts = (props) => {
    let imprints = props.imprints.sort((a,b) => {
        return a.description.localeCompare(b.description)
    })
    
    return (
        imprints.map((imprint, index) => {
            return (
                <option className="account-select-imprint" 
                    key={`${props.account} ${index}`} 
                        value={props.account} 
                        email={props.email}
                        name={props.name}>
                            &nbsp;&nbsp;&nbsp;{imprint.description}
                </option>
            )
        })
    )
}

const requiredMark = (required) => {
    if ( required ) {
        return <span className="requiredMarker">* </span>
    }
}

export class AccountSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            accounts: props.accounts ? props.accounts : []
        }
    }

    render() {
        let defaultValue = this.props.defaultValue ? this.props.defaultValue : -1
        defaultValue = defaultValue === 0 ? -1 : defaultValue

        return (
            <Fragment>
                <div>
                    <span className="account-field-title">
                        {requiredMark(this.props.required)}
                        <strong>{this.props.Title}</strong>
                    </span>
                </div>

                <select className="account-select" name={this.props.name} id={this.props.name} onChange={this.props.onChange} defaultValue={defaultValue}>
                    <option key={-1} disabled value={-1}>Select an Option</option>
                    {this.state.accounts.map((item, index) => {
                        let result = (
                            <Fragment key={index}>
                                <MainAccount item={item} account={item.id}/>
                                <ImprintAccounts imprints={item.imprints} account={item.id} name={item.name}/>
                            </Fragment>
                        )

                        return result
                    })}
                </select>
            </Fragment>
        )
    }
}
