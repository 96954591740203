import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import ModalRoot, {ModalRootStacked} from "../../Containers/Login/loginRoot";
import { guardAdmin } from '../Admin/admin-base'
import { InputField, SelectField, Imprints} from '../componentsBarrel'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { MessageFlash } from '../../Helpers/messageFlashHelper'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper'
import { Helmet } from 'react-helmet'

import './accountDetail.css'

export class AccountDetail extends React.Component {
    constructor(props) {
        super(props)

        let id = this.props.match.params.Id

        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            id: id === 'new' ? 0 : id,
            states: [],
            countries: [],
            country: {},
            countryId: 1,
            isNew: (id === 'new'),
            name: '',
            imprints: [],
            copies: '1',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address1: '',
            address2: '',
            city: '',
            state: {},
            stateId: 0,
            province: '',
            postalCode: '',
            telephone: ''
        }

        this.defaultReviewCopyOptions = ['1', '2', '3']
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.setState({loading: true}, () => this.loadAccount());
    }

    loadAccount() {
        let promises = []

        if (this.state.isNew === false) {
            promises.push(axios
                .get(environment.baseUrl + '/api/accounts/get/' + this.state.id, this.config)
                .then(response => {
                    if (response.data.error) {
                        alert(response.data.error)
                        return;
                    }

                    if (response.data.imprints) {
                        response.data.imprints.sort((a,b) => {
                            return a.description.localeCompare(b.description)
                        })
                    }

                    this.loadValues(response.data)
                    return response.data
                })
                .catch(error => {
                    console.error('Error loading account: ', error, error.response);
                    alert('Error Loading Account');
    
                    this.setState({
                        loading: false
                    })
                }))
        }

        promises.push(axios
            .get(environment.baseUrl + '/api/accounts/states', this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return;
                }

                let states = response.data
                states.unshift({id: 0, name: ''})

                this.setState({
                    states: response.data
                })

                return response.data
            })
            .catch(error => {
                console.error('Error loading state data: ', error, error.response);
                alert('Error Loading State Data');

                this.setState({
                    states: [],
                    loading: false
                })
            }))

        promises.push(axios
            .get(environment.baseUrl + '/api/accounts/countries', this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return;
                }

                this.setState({
                    countries: response.data
                })

                return response.data
            })
            .catch(error => {
                console.error('Error loading state data: ', error, error.response);
                alert('Error Loading State Data');

                this.setState({
                    countries: [],
                    loading: false
                })
            }))

        Promise.all(promises)
            .then(values => {
                this.setState({loading: false})
            })
    }

    loadValues(data) {
        this.setState({
            id: data.id,
            name: data.name,
            imprints: data.imprints,
            copies: data.copies,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            stateId: data.stateId,
            province: data.province,
            countryId: data.countryId,
            postalCode: data.postalCode,
            telephone: data.telephone
        })
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    onDelete = (e) => {
        e.preventDefault();

        if (!window.confirm('Are you sure you want to delete this account?')) {
            return false;
        }
        
        axios
            .delete(environment.baseUrl + '/api/accounts/delete/' + this.state.id, this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return
                }

                MessageFlash.setMessage('Account deleted')
                this.props.history.goBack();
            })
            .catch(error => {
                console.error('Error deleting account: ', error, error.response);
                alert('Error Deleting Account');
            })
    }

    getDeleteButton() {
        if (this.state.isNew === true) {
            return ''
        }

        return (
            <button className="secondary-button" onClick={this.onDelete}>Delete</button>
        )
    }

    validateForm() {
        let isEmailValid = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
            .test(this.state.email)

        if (isEmailValid === false) {
            alert('Email address is not valid. Please try again.')
            return false
        }

        return true
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validateForm()) {
            return false;
        }

        let model = {...this.state}

        model.stateId = this.isCountryUSA() ? model.stateId : 0
        model.province = this.isCountryUSA() ? '' : model.province

        axios
            .post(environment.baseUrl + '/api/accounts/save', model, this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return
                }

                MessageFlash.setMessage('Account Saved Successfully')
                this.props.history.goBack();
            })
            .catch(error => {
                console.error('Error saving account: ', error, error.response);
                alert('Error Saving Account');
            })
    }

    isCountryUSA() {
        return `${this.state.countryId}` === '1'
    }

    getProvinceField() {
        if (this.isCountryUSA()) {
            return (
                <InputField title="Province"
                    name="province"
                    value={''}
                    disabled
                />
            )
        }

        return (
            <InputField title="Province"
                maxLength="100"
                name="province"
                value={this.state.province}
                onChange={this.handleInputChange}
            />
        )
    }

    getStateField() {
        if (this.isCountryUSA()) {
            return (
                <SelectField name="stateId"
                    Title="State"
                    data={this.state.states}
                    onChange={this.handleInputChange}
                    value={this.state.stateId}
                    displayValue="id"
                    displayName="name"
                />
            )
        }

        return (
            <InputField title="State"
                name="state"
                value={''}
                disabled
            />
        )
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="account-detail">
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <ModalRoot/>
                        <ModalRootStacked/>
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="account-text-container">
                                        <span className="account-text">Account</span>
                                    </div>
                                </div>
                                <div className="row required">
                                    <div className="required-legend col-xs-12">
                                        <span className="requiredMarker">
                                            *
                                        </span>
                                        <span className="requiredText">
                                            &nbsp;Required
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={this.handleSubmit}>
                                <div className="row col-xs-8">
                                    <InputField title="Publisher"
                                        maxLength="100"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.handleInputChange}
                                        required
                                        autoFocus
                                    />
                                </div>

                                <div className="row col-xs-12">
                                    <Imprints imprints={this.state.imprints}/>
                                </div>

                                <div className="row col-xs-12">
                                    <SelectField name="copies"
                                        Title="Default Number of Review Copies"
                                        data={this.defaultReviewCopyOptions}
                                        onChange={this.handleInputChange}
                                        value={this.state.copies}
                                        required
                                    />
                                </div>

                                <div className="row col-xs-12">
                                    <h4>Billing Contact</h4>
                                </div>

                                <div className="row">
                                    <div className="col-xs-5">
                                        <InputField title="First Name"
                                            maxLength="100"
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-xs-5">
                                        <InputField title="Last Name"
                                            maxLength="100"
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-5">
                                        <InputField title="Email"
                                            maxLength="100"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-xs-5">
                                        <InputField title="Telephone"
                                            maxLength="25"
                                            name="telephone"
                                            value={this.state.telephone}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row col-xs-8">
                                    <InputField title="Address Line 1"
                                        maxLength="100"
                                        name="address1"
                                        value={this.state.address1}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="row col-xs-8">
                                    <InputField title="Address Line 2"
                                        maxLength="100"
                                        name="address2"
                                        value={this.state.address2}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-xs-5">
                                        <InputField title="City"
                                            maxLength="100"
                                            name="city"
                                            value={this.state.city}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="col-xs-5">
                                        {this.getStateField()}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-5">
                                        {this.getProvinceField()}
                                    </div>

                                    <div className="col-xs-5">
                                        <SelectField name="countryId"
                                            Title="Country"
                                            data={this.state.countries}
                                            onChange={this.handleInputChange}
                                            value={this.state.countryId}
                                            displayValue="countryId"
                                            displayName="countryName"
                                        />
                                    </div>
                                </div>

                                <div className="row col-xs-4">
                                    <InputField title="Postal Code"
                                        maxLength="15"
                                        name="postalCode"
                                        value={this.state.postalCode}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="col-xs-12">
                                    <div className="row col-xs-12 text-center button-group">
                                        <button className="SubmitButton">Submit</button>
                                        <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                        {this.getDeleteButton()}
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
