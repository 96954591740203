import React from 'react'
import './listField.css'

export class ListField extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            data: props.data ? props.data : []
        }

        this.listId = props.id ? props.id : ''
    }

    render() {
        const self = this

        if (this.state.data.length === 0) {
            return (
                <div className="select-list">None</div>
            )
        }

        return (
            <div className="select-list" id={self.listId}>
                {this.state.data.map(function (item, index) {
                    return self.props.displayitem(item, index, self.props.modal, self.props.showModal)
                })}
            </div>
        )
    }
}
