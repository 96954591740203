import React from 'react'
import Modal from 'react-modal'
import { CollectedWorksEssaysModalContent } from './collectedWorksEssaysModalContent'

import './collectedWorksEssaysModal.css'

export class CollectedWorksEssaysModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            essay: props.essay ? props.essay : {contributors: [], subjects: []},
            edit: props.essay ? true : false
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    }

    onRequestDelete = (e) => {
        e.preventDefault();
        let data = {
            ...this.state,
            deleteRequested: true
        }
        this.onRequestClose(e);
        this.props.onSave(data, this.props.parent);
    }

    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (this.isDataValid() === false) {
            return false;
        }

        this.onRequestClose(e)

        const data = {
            essay: this.content.state,
            edit: this.state.edit,
            deleteRequested: this.state.deleteRequested
        }

        this.props.onSave(data)
        return true;
    }

    isDataValid() {
        if (this.state.essay.contributors.length < 1) {
            alert('You must enter at least one contributor.')
            return false
        }

        return true;
    }

    getDeleteButton() {
        if ( this.state.edit ) {
            return (
                <button className="secondary-button" onClick={(e) => this.onRequestDelete(e)}>Delete</button>
            )
        }
    }

    render() {
        return (
            <main>
                <div className="book-format-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="CollectedWorksEssaysModal book-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <form onSubmit={this.handleSubmit}>
                            <h2>Essay</h2>

                            <CollectedWorksEssaysModalContent ref={(ref) => this.content = ref} essay={this.state.essay}/>

                            <div className="dialogButtons row">
                                <button className="default-button">Save</button>
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                                {this.getDeleteButton()}
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
