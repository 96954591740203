const localEnvironment = {
  baseUrl: 'http://localhost:29002',
  loginUrl: 'http://localhost:29002',
  defaultNotificationEmail: 'harsha.hammika@soltech.net',
  sessionTimeoutInSeconds: 1000 * 60 * 60
};
const SBLQAEnvironment = {
  baseUrl: 'https://test.www.sblcentral.org/API',
  loginUrl: 'https://test.www.sblcentral.org/API',
  defaultNotificationEmail: 'coconnor@sbl-site.org',
  sessionTimeoutInSeconds: 1000 * 60 * 60
};
const SBLUATEnvironment = {
  baseUrl: 'https://uat.sblcentral.org/API',
  loginUrl: 'https://uat.sblcentral.org/API',
  sessionTimeoutInSeconds: 1000 * 60 * 60
}
const SBLStagingEnvironment = {
  baseUrl: 'https://staging.sblcentral.org/API',
  loginUrl: 'https://authenticate.sbl-site.org',
  sessionTimeoutInSeconds: 1000 * 60 * 60
}
const SBLProdrodEnvironment = {
  baseUrl: 'https://www.sblcentral.org/API',
  loginUrl: 'https://www.sblcentral.org/API',
  sessionTimeoutInSeconds: 1000 * 60 * 60 * 4
};

let environment;

if (process.env.REACT_APP_ENVIRONMENT === 'SBLQA') {
  environment = SBLQAEnvironment;
}
else if (process.env.REACT_APP_ENVIRONMENT === 'SBLUAT') {
  environment = SBLUATEnvironment;
}
else if (process.env.REACT_APP_ENVIRONMENT === 'SBLProd') {
  environment = SBLProdrodEnvironment;
}
else if (process.env.REACT_APP_ENVIRONMENT === 'SBLSTAGING') {
  environment = SBLStagingEnvironment
}
else {
  environment = localEnvironment;
}

export default {
  // add common environment values here
  ...environment
};