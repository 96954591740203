import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import ModalRoot, {ModalRootStacked} from "../../Containers/Login/loginRoot";
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'

import { guardAdmin } from '../Admin/admin-base'
import { JournalArticles, InputField, SelectField } from '../componentsBarrel'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { MessageFlash } from '../../Helpers/messageFlashHelper'
import { Checkbox } from 'react-bootstrap'

import './journalIssuesDetail.css'

export class JournalIssuesDetail extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        let id = this.props.match.params.Id
        this.journals = [{id: 0, title: 'Select an Option'}]
        this.years = []

        this.state = {
            id: id === 'new' ? 0 : id,
            isNew: (id === 'new'),
            title: '',
            journalId: 0,
            volume: '',
            issue: '',
            url: '',
            year: 0,
            articles: [],
            notificationsDate: null,
            //sendNotifications: false,
            notificationSent: false,
            isIssueNotificationApproved: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.setState({loading: true}, () => this.loadData());
    }

    loadData() {
        let promises = []

        promises.push(this.loadJournals())
        promises.push(this.loadYears())

        if (this.state.isNew === false) {
            promises.push(this.loadExisting())
        }

        Promise.all(promises)
            .then(values => {
                this.setState({loading: false})
            })
    }

    loadExisting() {
        return axios
            .get(environment.baseUrl + '/api/journalissues/get/' + this.state.id, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return;
                }

                //console.debug('DATA: ', response.data)

                this.loadValues(response.data.issue)

                return response.data
            })
            .catch(error => {
                console.error('Error loading journal issue: ', error, error.response);
                alert('Error Loading Journal Issue');
            })
    }

    getActiveJournals(journals){
        let arr = []
        for(let i = 0; journals.length > i; i++){
            let thisJournal = journals[i];
            if(thisJournal.isActive){
                arr.push(thisJournal);
            }
        }
        return arr;
    }

    loadJournals() {
        return axios
            .get(environment.baseUrl + '/api/journals/enumerate', this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                //console.debug('Journal Data: ', response.data)
                let activeJournals = this.getActiveJournals(response.data.journals);
                this.journals = this.journals.concat(activeJournals)

                return response.data
            })
            .catch(error => {
                console.error('Error loading journal data: ', error, error.response)
                alert('Error Loading Journal Data')

                this.journals = []
            })
    }

    loadYears() {
        return axios.get(environment.baseUrl + '/api/journals/years', this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                //console.debug('YEARS: ', response.data)

                this.years = []
                response.data.years.forEach(year => {
                    this.years.push({
                        id: year,
                        value: year
                    })
                })

                if (this.years.length > 0) {
                    this.setState({
                        year: this.years[0].value
                    })
                }

                return response.data
            })
            .catch(error => {
                console.error('Error loading journal years: ', error, error.response)
                alert('Error loading journal years')

                this.years = []
            })
    }

    loadValues(issue) {
        this.setState({
            id: issue.id,
            volume: `${issue.volume}`,
            issue: issue.issue ? `${issue.issue}` : '',
            url: issue.url,
            journalId: issue.journalId,
            articles: issue.articles,
            year: `${issue.year}`,
            loading: false,
            notificationsDate: issue.notificationsDate,
            notificationSent: issue.notificationSent,
            isIssueNotificationApproved: issue.isIssueNotificationApproved ? issue.isIssueNotificationApproved : false
        })
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeNumericOnly = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '')
        value = value.toUpperCase()
        this.setState({[e.target.name]: value})
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    onDelete = (e) => {
        e.preventDefault();

        if (!window.confirm('Are you sure you want to delete this journal issue?')) {
            return false;
        }
        
        axios
            .delete(environment.baseUrl + '/api/journalissues/delete/' + this.state.id, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                MessageFlash.setMessage('Journal Issue Deleted')
                this.props.history.goBack();
            })
            .catch(error => {
                console.error('Error deleting journal issue: ', error, error.response);
                alert('Error Deleting Journal Issue');
            })
    }

    getDeleteButton() {
        if (this.state.isNew === true || this.state.notificationSent) {
            return ''
        }

        return (
            <button className="secondary-button" onClick={this.onDelete}>Delete</button>
        )
    }

    validateForm() {
        //console.debug('state: ', this.state)

        if (this.state.journalId === 0 || this.state.journalId === '0') {
            alert('Please select a journal')
            return false
        }

        if (this.state.volume === null || this.state.volume.trim() === '') {
            alert('Please enter a volume.')
            return false
        }

        let issue = parseInt(this.state.issue)
        if (issue > 255) {
            alert('Issue must be less than 256')
            return false
        }

        if (this.state.articles.length === 0) {
            alert('At least one article is required.')
            return false
        }

        return true
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validateForm()) {
            return false;
        }

        let model = {...this.state}

        axios
            .post(environment.baseUrl + '/api/journalissues/save', model, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                MessageFlash.setMessage('Journal Issue Saved Successfully')
                this.props.history.goBack();
            })
            .catch(error => {
                console.error('Error saving journal issue: ', error, error.response);
                alert('Error Saving Journal Issue');
            })
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    formatDate =(strDate) =>{
        let date = new Date(strDate);
        return `${date.getMonth() + 1}/${date.getDay() + 1}/${date.getFullYear()}`
    }

    render() {
        let header = this.state.isNew === true ? 'Add Journal Issue' : 'Edit Journal Issue'

        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="journal-issues-detail">
                        <ModalRoot/>
                        <ModalRootStacked/>

                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="journal-issues-text-container">
                                        <span className="journal-issues-text">{header}</span>
                                    </div>
                                </div>
                                <div className="row required">
                                    <div className="required-legend col-xs-12">
                                        <span className="requiredMarker">
                                            *
                                        </span>
                                        <span className="requiredText">
                                            &nbsp;Required
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={this.handleSubmit}>
                                <div className="DetailsContainer">
                                    <div className="row col-xs-8">
                                        <SelectField name="journalId"
                                            Title="Journal"
                                            data={this.journals}
                                            onChange={this.handleInputChange}
                                            value={this.state.journalId}
                                            displayValue="id"
                                            displayName="title"
                                            required
                                            autoFocus
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <SelectField name="year"
                                            Title="Year"
                                            data={this.years}
                                            onChange={this.handleInputChange}
                                            value={this.state.year}
                                            displayValue="value"
                                            displayName="value"
                                            required
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="Volume"
                                            maxLength="5"
                                            name="volume"
                                            value={this.state.volume}
                                            onChange={this.onChangeNumericOnly}
                                            required
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="Issue Number"
                                            maxLength="5"
                                            name="issue"
                                            value={this.state.issue}
                                            onChange={this.onChangeNumericOnly}
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="URL"
                                            maxLength="4000"
                                            name="url"
                                            value={this.state.url}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>

                                    <div id="articles" className="row col-xs-10">
                                        <JournalArticles articles={this.state.articles}/>
                                    </div>

                                    <div className="row col-xs-10 notification-group">
                                        {
                                            (
                                                (new Date(this.state.notificationsDate).getMonth() <= new Date().getMonth()) &&
                                                (new Date(this.state.notificationsDate).getDay() < new Date().getDay()) &&
                                                (new Date(this.state.notificationsDate).getFullYear() <= new Date().getFullYear())
                                            ) ?

                                            this.disable = true :
                                            this.disable = false
                                        }
                                        <Checkbox name="isIssueNotificationApproved" 
                                            checked={this.state.isIssueNotificationApproved} 
                                            disabled={this.state.notificationSent}
                                            onChange={this.onCheckboxChange}
                                            id="isIssueNotificationApproved"
                                        >
                                            <b>Send Member Notification?</b>
                                        </Checkbox>
                                    </div>

                                    <div className="row col-xs-10 notification-date-group">
                                        {                                            
                                            (this.state.isIssueNotificationApproved && this.state.notificationsDate !== null) ?
                                            <span><b>Notification Sent:</b> {
                                                ((new Date(this.state.notificationsDate).getMonth() + 1).toString().padStart(2, '0') + "/") +
                                                ((new Date(this.state.notificationsDate).getDate()).toString().padStart(2, '0') + "/") +
                                                (new Date(this.state.notificationsDate).getFullYear())
                                                // this.formatDate(this.state.notificationsDate)
                                                }</span> :
                                            ""
                                        }
                                    </div>

                                    <div className="col-xs-12">
                                        <div className="row col-xs-12 text-center button-group">
                                            <button className="SubmitButton">Submit</button>
                                            <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                            {this.getDeleteButton()}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
