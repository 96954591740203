import React, {Fragment} from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'
import { Checkbox } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import './user.css'
import { Helmet } from 'react-helmet'

export class User extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            users: [],
            loading: false,
            checked: false
        }
    }

    defaultStyle = {
        'text-align': 'left'
    }

    componentDidMount() {
        let footer = document.getElementById('Footer')
        footer.style.position = 'relative'

        this.setState({loading: true}, () => {
            this.loadDataFromServer()
        })
    }

    loadDataFromServer() {
        const url = environment.baseUrl + '/api/adminusers/all'

        axios
            .get(url, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                this.setState({
                    users: response.data,
                    loading: false
                })
            })
            .catch(error => {
                console.error('Error loading User data: ', error, error.response)
                alert('Error Loading User Data')

                this.setState({
                    users: [],
                    loading: false
                })
            })
    }

    editUser = (e, id) => {
        e.preventDefault()
        this.props.history.push('/admin/UserDetail/' + id)
    }

    getUsers() {
        if (this.state.users.length === 0) {
            return (
                <Fragment>
                    <div className="no-items-found">
                        No Users Found to List
                    </div>
                </Fragment>
            )
        }

        return (
            <Fragment>
                {this.state.users.map((User, index) => {
                    return ( 
                        <div className={`user-item`} key={index}>                
                            <a onClick={(e) => this.editUser(e, User.id)}>{User.firstName} {User.lastName}</a>
                        </div>                    
                    )
                })}
            </Fragment>
        )
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="user-manager">
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <div className="BackgroundImageSection">
                            <div className="TextContainer container">
                                <div className="title-section col-xs-11 text-center">
                                    <div className="col-xs-12">
                                        <div className="user-text-container">
                                            <span className="user-text">Admin Users</span>
                                        </div>
                                        <div>
                                            <Link to={`/admin/UserDetail/new`}>Add Admin User</Link>
                                        </div>
                                        <div className='user-list'>
                                            {this.getUsers()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
