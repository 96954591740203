import React, {Fragment, useState} from 'react'
import axios from 'axios'
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'
import environment from "../../Environments/environments"
import ReactExport from "react-data-export"
import ReactTooltip from 'react-tooltip'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import Moment from 'moment'
import { Helmet } from 'react-helmet'

import "./subjectSelectionsSnapshot.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class SubjectSelectionsSnapshotReport extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            subjectCounts: null,
            loading: true
        }

        this.reportTitle = 'Subject Selections Snapshot'
        this.todaysDate = new Date()
    }

    componentDidMount() {
        this.setState({loading: true}, () => this.loadDataFromServer())

        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';
    }

    getExcelFunctionalButton() {
        return (
            <ExcelFile element={this.getExcelButton()} filename="SubjectSelectionsSnapshot">
                <ExcelSheet data={this.state.subjectCounts} name="SubjectSelectionsSnapshot">
                    <ExcelColumn label="Subject 1" value="subject1"/>
                    <ExcelColumn label="Subject 2" value="subject2"/>
                    <ExcelColumn label="Subject 3" value="subject3"/>
                    <ExcelColumn label="Count" value="count"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }

    getExcelButton = () => {
        return (
            <button
                id = "table-xls-button"
                className = "download-table-xls-button"
            ></button>
        )
    }

    onDownload = () => {
        let button = document.getElementById('table-xls-button')
        button.click()
    }

    loadDataFromServer() {
        this.makeAPICall('/api/notificationsSnapshot/subjectSelctionsSnapshot');
    }

    formatDate(date) {
        return Moment(date).format("MM/DD/YYYY hh:mm A");
    }

    makeAPICall(uri) {
        let url = environment.baseUrl.concat(uri)

        return axios.get(url, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    console.error('Error: ', response.data.message)
                    alert('Error getting Notifications data for the api: ' + uri + '. Error message: ' + response.data.message)
                    return;
                }
                else 
                {
                    this.setState({ subjectCounts: response.data.data })
                }

                this.setState({ 
                    loading: false
                })
            })
            .catch(error => {
                console.log('Error: ', error)
                alert('An error occurred getting report data: ' + error)

                this.setState({
                    loading: false
                })
                return false;
            })
    }

    ellipsisFormat(longText) {
        if (!longText || longText.length <= 50) {
            return longText
        }

        return longText.substring(0, 50).trim() + ' . . .'
    }

    formatTooltip(text) {
        const tipPrefix = "<p style='text-align: left; width: 600px'>"
        const tipSuffix = '</p>'

        return tipPrefix + text + tipSuffix
    }

    CustomDataGrid = () => {
        const initialRows = this.state.subjectCounts;
        if (initialRows != null && initialRows.length > 0)
        {
            return (
            <Fragment>
                {getBusyIndicator(this.state.loading, 
                    <div className="subject-selections-snapshot">
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                    <ReactTooltip multiline={true}/>
                    <div className="TextContainer container">
                        <div className="title-section col-xs-12 text-center">
                            <div className="col-xs-12">
                                <div className="rbl-export-container">
                                    <span className="title-text">Book/Review Notifications</span>
                                    <br/><br/><br/>
                                    <span className="title-text">{this.reportTitle}</span>
                                    <br/><br/><br/>
                                    <span className="title-subtext">as of { this.formatDate(this.todaysDate) } (ET)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data-display">
                        <hr/>
                        <div>
                            <a className="export-link" onClick={this.onDownload}>Export to Excel</a>
                            {this.getExcelFunctionalButton()}
                        </div>
                        <div className="data-table">
                            <table id="data-table">
                                <thead>
                                    <tr className="table-head">
                                        <th>Subject 1</th>
                                        <th>Subject 2</th>
                                        <th>Subject 3</th>
                                        <th># Selections</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.subjectCounts.map((item, index) => {
                                        let subject2Display = this.ellipsisFormat(item.subject2)
                                        let subject2Tooltip = this.formatTooltip(item.subject2)
    
                                        let subject3Display = this.ellipsisFormat(item.subject3)
                                        let subject3Tooltip = this.formatTooltip(item.subject3)

                                        return (
                                            <tr className="table-row" key={index}>
                                                <td>{item.subject1}</td>
                                                {(item.subject2.length > 50) ?
                                                    <td>
                                                        <span data-tip={subject2Tooltip} data-html={true} data-multiline={false}>
                                                            {subject2Display}
                                                        </span>
                                                    </td>
                                                :
                                                    <td>{item.subject2}</td>
                                                }
                                                {(item.subject3.length > 50) ?
                                                    <td>
                                                        <span data-tip={subject3Tooltip} data-html={true} data-multiline={false}>
                                                            {subject3Display}
                                                        </span>
                                                    </td>
                                                :
                                                    <td>{item.subject3}</td>
                                                }
                                                <td>{item.count}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                )}
            </Fragment>
            );
        }
        else
        {
            return getBusyIndicator(this.state.loading);
        }
    }

    render() {
        return (
        <this.CustomDataGrid />
    )}
}

const options = { 
    timeZone: "America/New_York", 
    hour12: true,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    //hour: 'numeric',
    //second: 'numeric',
    //minute: 'numeric'
};
