export class DefaultConfig {
    static getDefaultConfig() {
        const user = (typeof sessionStorage != 'undefined') ? JSON.parse(sessionStorage.getItem('loggedInUser')) : {}

        let config = {
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }

        return config
    }
}
