import * as React from "react";
import { guardAdmin } from './admin-base'
import { AdminSearchComponent, AdminQuickFilters } from "../../Components/componentsBarrel";

import "./admin.css";
import { Helmet } from 'react-helmet';

export class Admin extends React.Component {
  render() {
    // The icon below is rendered hidden to speed up display on other pages
    return guardAdmin(
      <div className="adminMain">
        <Helmet>
            <meta name="robots" content="noindex" />
        </Helmet>
        <div>
          <div className="SearchAndTitleContainer">
            <AdminSearchComponent/>
          </div>

          <i className="hidden-arrow fas fa-arrow-circle-right"></i>
        </div>
        <div>
          <AdminQuickFilters />
        </div>
      </div>
    )
  }
}
