import React from 'react'
import { SearchResultsPage } from '../SearchResultsPage/searchResultsPageContainer'
import {AdminSearchResultsPageComponent} from '../../Components/AdminSearchResultsPageComponent/adminSearchResultsPageComponent'
import { guardAdmin } from '../../Components/Admin/admin-base'
import { BookStatusHelper } from '../../Helpers/bookStatusHelper'
import './adminSearchResultsLandingPage.css'

const BookDateStatusBlock = (props) => {
  if (props.created) {
    const created = new Date(props.created)
    return (
      <div className="SearchResultItemPublicationDateContainer book-status-display-line">
        {'Entered: ' + created.toLocaleDateString() + ' ' + created.toLocaleTimeString()}
        <BookStatusDisplay bookStatusId={props.bookStatusId}/>
      </div>
    )
  }

  return ''
}

const BookStatusDisplay = (props) => {
  if (props.bookStatusId===null)
  {
    return ''
  }

  return (
    <div>
      {BookStatusHelper.translateStatus(props.bookStatusId)}
    </div>
  )
}

export class AdminSearchResultsLandingPage extends React.Component {

  componentDidMount() {
    let footer = document.getElementById('Footer');
    footer.style.position = 'relative';
  }

  calculateSearchComponent = (host) => {
    return (
      <AdminSearchResultsPageComponent
        searchTerm={host.state.searchTerm}
        keywordType={host.state.keywordType}
        pageCount={host.state.pageCount}
        perPage={host.state.perPage}
        filterData={host.state}
        numTotalResults={host.state.numTotalResults}
        pageSelectedIndex={host.state.pageSelectedIndex}
        sortTerm={host.state.sortTerm}
        onFilterClick={host.onFilterClick}
        handleContentTypeClick={host.handleContentTypeClick}
        handleMeetingTypeClick={host.handleMeetingTypeClick}
        sortResults={host.sortResults}
        handlePageClick={host.handlePageClick}
        updateResultsPerPage={host.updateResultsPerPage}
        updateSearchTerm={host.updateSearchTerm}
        displayShowingText={host.displayShowingText}
        renderLoaderOrSearchResults={host.renderLoaderOrSearchResults}
        isSeeMoreYearsHidden={host.state.isSeeMoreYearsHidden}
        selectedContentTypeOption={host.state.selectedContentTypeOption}
        selectedMeetingTypeOption={host.state.selectedMeetingTypeOption}
        searchDropdownValue={host.state.searchDropdownValue}
        annualMeetingFilter={host.state.annualMeetingFilter}
        internationalMeetingFilter={host.state.internationalMeetingFilter}
        browseAll={host.state.browseAll}
      />
    )
  }

  getRedirect = (host) => {

    let searchTerm = host.state.searchTerm.trim().replace(/\//g, '%2F')
    if (host.state.browseAll) {
      searchTerm = 'all'
    }

    let url = '/admin/searchResults/' + searchTerm
    url += '?page=' + (host.state.pageSelectedIndex + 1);

    let time = 0;
    time = (host.state.twentyTwentyOneToPresent) ? time | 2048 : time;
    time = (host.state.twentySixteenToTwentyTwenty) ? time | 1 : time;
    time = (host.state.twentyElevenToTwentyFifteen) ? time | 2 : time;
    time = (host.state.twoThousandSixToTwentyTen) ? time | 4 : time;
    time = (host.state.twoThousandOneToTwoThousandFive) ? time | 8 : time;
    time = (host.state.ninetySixToTwoThousand) ? time | 16 : time;
    time = (host.state.ninetyOneToNinetyFive) ? time | 32 : time;
    time = (host.state.eightySixToNinety) ? time | 64 : time;
    time = (host.state.eightyOneToEightyFive) ? time | 128 : time;
    time = (host.state.seventySixToEighty) ? time | 256 : time;
    time = (host.state.seventyOneToSeventyFive) ? time | 512 : time;
    time = (host.state.uptoSeventy) ? time | 1024 : time;

    url += "&time=" + time;

    url += "&type=1";

    url += `&sf=${host.state.bookStatusFilter}`

    let subType = host.state.reviewedFilter ? 1 : host.state.notReviewedFilter ? 2 : 0;
    url += "&subtype=" + subType;

    if ( host.state.sortTerm ) {
      url += "&sortTerm=" + host.state.sortTerm
    }

    if ( host.state.keywordType !== '' ) {
      url += "&kw=subsearch&reset=true";
    }

    if (host.state.browseAll) {
      url += "&browseAll"
    }
        
    return url;
  }
  
  getCreatedAndStatusBlock = (item) => {
    return <BookDateStatusBlock created={item.created} bookStatusId={item.bookStatusId}/>
  }
          
  render() {
    return guardAdmin(
        <SearchResultsPage
            calculateSearchComponent={this.calculateSearchComponent}
            location={this.props.location}
            match={this.props.match}
            getRedirect={this.getRedirect}
            bookDetailsPath={'/admin/bookDetails/'}
            filterPending={false}
            isAdmin={true}
            displayCreatedAndStatus={this.getCreatedAndStatusBlock}
        />
    )
  }
}
