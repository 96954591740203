import React, { Fragment } from 'react'
import { guardAdmin } from '../Admin/admin-base'
import { Helmet } from 'react-helmet'

import './systemMaintenance.css'

export class SystemMaintenance extends React.Component {
    getNotificationTestItem() {
        return (
            <Fragment>
                <li>
                    <a onClick={() => window.location='/admin/notificationTest'}>Process Book/Review Notifications</a>
                </li>
            </Fragment>
        )
    }

    getExportItem() {
        return (
            <li>
                <a onClick={() => window.location='/admin/rblexport'}>RBL Export</a>
            </li>
        )
    }

    render() {
        return guardAdmin(
            <div className="adminMenuPage">
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="title">
                    System Management
                </div>
                <div className="body">
                    <div className="systemContentMenu">
                        <ul>
                            <li>
                                <a onClick={() => window.location='/admin/accountManagement'}>
                                    Account Management
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/users'}>
                                    Admin User Management
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/meetingImageUpload'}>
                                    Meeting Image Upload
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/metamgmt'}>Metadata Management</a>
                            </li>
                            {this.getNotificationTestItem()}
                            {/* {this.getExportItem()} */}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
