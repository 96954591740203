import React from 'react'
import {BookStatusFilterEnum} from '../../Helpers/bookStatusHelper'

import './adminQuickFilters.css'

export class AdminQuickFilters extends React.Component {
    getSearchHref(statusFilterType) {
        return "/admin/searchResults/all?type=1&subttype=0&sf=" + statusFilterType + "&browseAll"
    }

    render() {
        return (
            <div id="admin-quick-filters" className="Table">
                <div className="Row">
                    <div className="Cell">
                        <h2>Action Needed</h2>
                        <ul>
                            <li>
                                <a href={this.getSearchHref(BookStatusFilterEnum.PendingSBLCApproval)}>
                                    Pending SBLC Approval
                                </a>
                            </li>
                            <li>
                                <a href={this.getSearchHref(BookStatusFilterEnum.PendingRBLApproval)}>
                                    Pending RBL Approval
                                </a>
                            </li>
                            <li>
                                <a href={this.getSearchHref(BookStatusFilterEnum.PendingSubjectTag)}>
                                    Pending Subject Tag
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="Cell">
                        <h2>Pending Book Review</h2>
                        <ul>
                            <li>
                                <a href={this.getSearchHref(BookStatusFilterEnum.RBLApproved)}>
                                    RBL Approved
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="Cell">
                        <h2>No Action Needed</h2>
                        <ul>
                            <li>
                                <a href={this.getSearchHref(BookStatusFilterEnum.SBLCRejected)}>
                                    SBLC Rejected
                                </a>
                            </li>
                            <li>
                                <a href={this.getSearchHref(BookStatusFilterEnum.RBLRejected)}>
                                    RBL Rejected
                                </a>
                            </li>
                            <li>
                                <a href={this.getSearchHref(BookStatusFilterEnum.Withdrawn)}>
                                    Withdrawn by Publisher
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
