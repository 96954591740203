import React from 'react'
import './CustomDateInput.css'

export const CustomDateInput = React.forwardRef(({formatted, onClick}, ref) => {
    return (
        <label className="custom-date-input" onClick={onClick}>
            {formatted}
            <i className="custom-date-input-icon fa fa-calendar"/>
        </label>
    )
})
