import * as React from 'react';
import { SearchResultsList } from '../../Components/componentsBarrel';
import axios from 'axios';
import environment from '../../Environments/environments';
import queryString from 'query-string';
import './searchResultsPageContainerStyles.css';
import { BookStatusFilterEnum } from '../../Helpers/bookStatusHelper';

export class SearchResultsPage extends React.Component {
  constructor(props) {
    super(props);

    let search = (this.props.location.search !== '') ? queryString.parse(this.props.location.search) : {};

    let time = this.calculateTimeFilters()
    let contentFilters = this.calculateContentFilters()
    let statusFilters = this.calculateStatusFilter()

    if (search.reset) {
      sessionStorage.setItem('scrollPosition', 0);
    }

    let otherState = {
      data: [],
      offset: 0,
      numTotalResults: -1,
      redirect: false,
      searchTerm: this.props.match.params.searchTerm.trim(),
      perPage: JSON.parse(sessionStorage.getItem('perPage')) || 20,
      pageSelectedIndex: search.page ? Math.max(Math.abs(JSON.parse(search.page)) - 1, 0) : 0,
      keywordType: search.kw ? search.kw : '',
      filterPending: props.filterPending,
      isAdmin: props.isAdmin
    };

    this.state = { ...time, ...contentFilters, ...otherState, ...statusFilters };

    this.updateResultsPerPage = this.updateResultsPerPage.bind(this);
    this.sortResults = this.sortResults.bind(this);
    this.loadResultsFromServer = this.loadResultsFromServer.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.renderLoaderOrSearchResults = this.renderLoaderOrSearchResults.bind(this);
    this.updateSearchTerm = this.updateSearchTerm.bind(this);
    this.handleContentTypeClick = this.handleContentTypeClick.bind(this);
    this.handleMeetingTypeClick = this.handleMeetingTypeClick.bind(this);
  }

  calculateTimeFilters() {
    let search = (this.props.location.search !== '') ? queryString.parse(this.props.location.search) : {};
    let time = search.time ? JSON.parse(search.time) : 0;

    let filters = {
      twentyTwentyOneToPresent: (time & 2048) > 0,
      twentySixteenToTwentyTwenty: (time & 1) > 0,
      twentyElevenToTwentyFifteen: (time & 2) > 0,
      twoThousandSixToTwentyTen: (time & 4) > 0,
      twoThousandOneToTwoThousandFive: (time & 8) > 0,
      ninetySixToTwoThousand: (time & 16) > 0,
      ninetyOneToNinetyFive: (time & 32) > 0,
      eightySixToNinety: (time & 64) > 0,
      eightyOneToEightyFive: (time & 128) > 0,
      seventySixToEighty: (time & 256) > 0,
      seventyOneToSeventyFive: (time & 512) > 0,
      uptoSeventy: (time & 1024) > 0
    }

    return filters;
  }

  calculateStatusFilter() {
    let search = (this.props.location.search !== '') ? queryString.parse(this.props.location.search) : {};

    let searchOverride = {}
    if (search.browseAll !== undefined) {
      searchOverride = { 
        searchTerm: '', 
        browseAll: true,
        sortTerm: search.sortTerm ? search.sortTerm : 'Created'
      }
    }

    return {bookStatusFilter: search.sf ? search.sf : BookStatusFilterEnum.All, ...searchOverride}
  }

  calculateContentFilters() {
    let search = (this.props.location.search !== '') ? queryString.parse(this.props.location.search) : {};
    let content = search.type ? JSON.parse(search.type) : 0;
    let subtype = search.subtype ? JSON.parse(search.subtype) : 0;
    let meettype = search.meettype ? JSON.parse(search.meettype) : 0;

    let filter = {
      allFilter: false,
      bookFilter: false,
      conferencePaperFilter: false,
      booksAll: true,
      reviewedFilter : false,
      notReviewedFilter: false,
      allMeetingFilter: true,
      annualMeetingFilter: false,
      internationalMeetingFilter: false
    }

    filter.sortTerm = search.sortTerm ? search.sortTerm : 'Relevance desc,TitleSortable'

    if (content === 1) {
      filter.bookFilter = true;
      filter.selectedContentTypeOption = 'bookFilter';
      filter.booksAll = (subtype === 0);
      filter.reviewedFilter = (subtype === 1);
      filter.notReviewedFilter = (subtype === 2);
      filter.searchDropdownValue = 'Books & Reviews';
      filter.isSeeMoreYearsHidden = false;
    }
    else if (content === 2) {
      filter.conferencePaperFilter = true;
      filter.selectedContentTypeOption = 'conferencePaperFilter';
      filter.allMeetingFilter = (meettype === 0);
      filter.annualMeetingFilter = (meettype === 1);
      filter.internationalMeetingFilter = (meettype === 2);
      filter.searchDropdownValue = 'Paper Abstracts';
      filter.isSeeMoreYearsHidden = true;
    }
    else {
      filter.allFilter = true;
      filter.selectedContentTypeOption = 'allFilter';
      filter.searchDropdownValue = 'All';
      filter.isSeeMoreYearsHidden = false;
    }

    sessionStorage.setItem('searchDropdownValue', filter.searchDropdownValue);

    return filter;
  }

  loadResultsFromServer() {
    let termToSearch = this.state.searchTerm;

    this.setState({
      loading: true,
      numTotalResults: -1
    });

    let odataSearchTermFilterQuery = '';
    let odataMasterQuery = '';

    odataSearchTermFilterQuery = "";

    let checkedBoxes = [];
    let yearsCheckboxFilterString = '';
    let contentCheckboxFilterString = '';
    let meetingTypeCheckboxFilterString = '';
    let reviewsCheckboxFilterString = '';
    let odataCheckboxFilterQueryYears = '';
    let odataCheckboxFilterQueryContent = '';
    let odataCheckboxFilterQueryMeetingType = '';
    let odataCheckboxFilterQueryReviews = '';
    let filterData = {
      twentyTwentyOneToPresent: this.state.twentyTwentyOneToPresent,
      twentySixteenToTwentyTwenty: this.state.twentySixteenToTwentyTwenty,
      twentyElevenToTwentyFifteen: this.state.twentyElevenToTwentyFifteen,
      twoThousandSixToTwentyTen: this.state.twoThousandSixToTwentyTen,
      twoThousandOneToTwoThousandFive: this.state.twoThousandOneToTwoThousandFive,
      ninetySixToTwoThousand: this.state.ninetySixToTwoThousand,
      ninetyOneToNinetyFive: this.state.ninetyOneToNinetyFive,
      eightySixToNinety: this.state.eightySixToNinety,
      eightyOneToEightyFive: this.state.eightyOneToEightyFive,
      seventySixToEighty: this.state.seventySixToEighty,
      seventyOneToSeventyFive: this.state.seventyOneToSeventyFive,
      uptoSeventy: this.state.uptoSeventy,
      bookFilter: this.state.bookFilter,
      allFilter: this.state.allFilter,
      conferencePaperFilter: this.state.conferencePaperFilter,
      allMeetingFilter: this.state.allMeetingFilter,
      annualMeetingFilter: this.state.annualMeetingFilter,
      internationalMeetingFilter: this.state.internationalMeetingFilter,
      reviewedFilter: this.state.reviewedFilter,
      notReviewedFilter: this.state.notReviewedFilter,
      boolsAll: this.state.booksAll
    };

    // add only checked checkboxes to new array
    for (let key in filterData) {
      if (filterData[key] === true) {
        checkedBoxes.push(key);
      }
    }

    // only run if there are checked filter boxes
    // then, map over array of only checked boxes and add correct odata filter string to array to filter search results
    if (checkedBoxes) {
      checkedBoxes.map(checkbox => {
        switch (checkbox) {
          case 'twentyTwentyOneToPresent':
            yearsCheckboxFilterString += '(Year ge 2021) or '
            break;
          case 'twentySixteenToTwentyTwenty':
            yearsCheckboxFilterString += '(Year ge 2016 and Year le 2020) or ';
            break;
          case 'twentyElevenToTwentyFifteen':
            yearsCheckboxFilterString += '(Year ge 2011 and Year le 2015) or ';
            break;
          case 'twoThousandSixToTwentyTen':
            yearsCheckboxFilterString += '(Year ge 2006 and Year le 2010) or ';
            break;
          case 'twoThousandOneToTwoThousandFive':
            yearsCheckboxFilterString += '(Year ge 2001 and Year le 2005) or ';
            break;
          case 'ninetySixToTwoThousand':
            yearsCheckboxFilterString += '(Year ge 1996 and Year le 2000) or ';
            break;
          case 'ninetyOneToNinetyFive':
            yearsCheckboxFilterString += '(Year ge 1991 and Year le 1995) or ';
            break;
          case 'eightySixToNinety':
            yearsCheckboxFilterString += '(Year ge 1986 and Year le 1990) or ';
            break;
          case 'eightyOneToEightyFive':
            yearsCheckboxFilterString += '(Year ge 1981 and Year le 1985) or ';
            break;
          case 'seventySixToEighty':
            yearsCheckboxFilterString += '(Year ge 1976 and Year le 1980) or ';
            break;
          case 'seventyOneToSeventyFive':
            yearsCheckboxFilterString += '(Year ge 1971 and Year le 1975) or ';
            break;
          case 'uptoSeventy':
            yearsCheckboxFilterString += '(Year le 1970) or ';
            break;
          case 'bookFilter':
            contentCheckboxFilterString += "(Type,'Book') or ";
            break;
          case 'allFilter':
            break;
          case 'conferencePaperFilter':
            contentCheckboxFilterString += "(Type,'ConferencePaper') or ";
            break;
          case 'annualMeetingFilter':
            meetingTypeCheckboxFilterString += "(MeetingType,'Annual') or ";
            break;
          case 'internationalMeetingFilter':
            meetingTypeCheckboxFilterString += "(MeetingType,'International') or ";
            break;
          case 'reviewedFilter':
            reviewsCheckboxFilterString += "(MeetingType,'Reviewed') or ";
            break;
          case 'notReviewedFilter':
            reviewsCheckboxFilterString += "(MeetingType,'NotReviewed') or ";
            break;
          default:
            break;
        }

        return null;
      });
    }

    if(yearsCheckboxFilterString || contentCheckboxFilterString || meetingTypeCheckboxFilterString || reviewsCheckboxFilterString) {
        // create odata string query for them
        odataSearchTermFilterQuery = '&$filter=(';
        if (yearsCheckboxFilterString) {
            // we are searching for the searchTerm OR block, AND the checkBox OR block, so add an and but also
            // create parenthesis around checkbox OR block
            odataCheckboxFilterQueryYears = ' ( '; // ' and (';
            odataCheckboxFilterQueryYears += yearsCheckboxFilterString;
            // we are added " or " to the end of every query for checkboxes, we must chop this off of the last query
            odataCheckboxFilterQueryYears = odataCheckboxFilterQueryYears.slice(0, -4);
             // close the checkbox OR block parenthesis and also close the entire filter block parenthesis
             if(contentCheckboxFilterString || meetingTypeCheckboxFilterString) {
                 odataCheckboxFilterQueryYears += ')';
             } else {
                 odataCheckboxFilterQueryYears += ')';
             }
        }

        if (contentCheckboxFilterString) {
            if(!(this.state.bookFilter && this.state.conferencePaperFilter)) {
                // we are searching for the searchTerm OR block, AND the checkBox OR block, so add an and but also
                // create parenthesis around checkbox OR block
              if(yearsCheckboxFilterString) {
                odataCheckboxFilterQueryContent = ' and (contains';
              } else {
                odataCheckboxFilterQueryContent = '(contains';
              }
                odataCheckboxFilterQueryContent += contentCheckboxFilterString;
                // we are added " or " to the end of every query for checkboxes, we must chop this off of the last query
                odataCheckboxFilterQueryContent = odataCheckboxFilterQueryContent.slice(0, -4);
                 // close the checkbox OR block parenthesis and also close the entire filter block parenthesis
                 if(meetingTypeCheckboxFilterString) {
                     odataCheckboxFilterQueryContent += ')';
                 } else {
                     odataCheckboxFilterQueryContent += ')';
                 }
            } else {
                odataCheckboxFilterQueryContent = '';
              if(!yearsCheckboxFilterString) {
                odataSearchTermFilterQuery = '';
              }
              // / add parenthesis on because we anticipate having too many parenthesis below when we do odataSearchTermFilterQuery.slice(0,-1);
                // odataSearchTermFilterQuery += ')';
            }
        }

        if (meetingTypeCheckboxFilterString) {
            // dont do this if both are checked, if both are checked it wont do anything anyway
          if(!(this.state.annualMeetingFilter && this.state.internationalMeetingFilter)) {
              // we are searching for the searchTerm OR block, AND the checkBox OR block, so add an and but also
              // create parenthesis around checkbox OR block
            if(yearsCheckboxFilterString || contentCheckboxFilterString) {
              odataCheckboxFilterQueryMeetingType = ' and (contains';
            } else {
              odataCheckboxFilterQueryMeetingType = '(contains';
            }
              odataCheckboxFilterQueryMeetingType += meetingTypeCheckboxFilterString;
              // we are added " or " to the end of every query for checkboxes, we must chop this off of the last query
              odataCheckboxFilterQueryMeetingType = odataCheckboxFilterQueryMeetingType.slice(0, -4);
               // close the checkbox OR block parenthesis and also close the entire filter block parenthesis
               odataCheckboxFilterQueryMeetingType += ')';

          } else {
              odataCheckboxFilterQueryMeetingType = '';
              // add parenthesis on because we anticipate having too many parenthesis below when we do odataSearchTermFilterQuery.slice(0,-1);
              // odataSearchTermFilterQuery += ')';
            if(!(yearsCheckboxFilterString || contentCheckboxFilterString)) {
              odataSearchTermFilterQuery = '';
            }
          }
        }

        if (reviewsCheckboxFilterString) {
            // dont do this if both are checked, if both are checked it wont do anything anyway
            if(!(this.state.reviewedFilter && this.state.notReviewedFilter)) {
                // we are searching for the searchTerm OR block, AND the checkBox OR block, so add an and but also
                // create parenthesis around checkbox OR block
                if(this.state.reviewedFilter) {
                    odataCheckboxFilterQueryReviews = ` and (BookStatusIdValue eq ${BookStatusFilterEnum.RBLPublished} and HasBookReviews eq true)`;
                } else if(this.state.notReviewedFilter) {
                    odataCheckboxFilterQueryReviews = ' and (IsAcceptingVolunteers eq true)';
                }

            } else {
                odataCheckboxFilterQueryReviews = '';
                // add parenthesis on because we anticipate having too many parenthesis below when we do odataSearchTermFilterQuery.slice(0,-1);
                // odataSearchTermFilterQuery += ')';
            }
        }
        // cut off the last parenthesis of the searchTerm query because we will be adding
        // checkboxFilterQuery to it and closing it as we did in the above line
        odataSearchTermFilterQuery = odataSearchTermFilterQuery.slice(0, -1);
    }

    let bookStatusFilterQuery = ''
    if (this.state.bookStatusFilter !== BookStatusFilterEnum.All ) {
      if (this.state.bookStatusFilter === BookStatusFilterEnum.PendingSubjectTag) {
        bookStatusFilterQuery = ` and (BookStatusIdValue ne ${BookStatusFilterEnum.PendingSBLCApproval}`
        bookStatusFilterQuery += ` and BookStatusIdValue ne ${BookStatusFilterEnum.SBLCRejected}`
        bookStatusFilterQuery += ` and BookStatusIdValue ne ${BookStatusFilterEnum.Withdrawn}`
        bookStatusFilterQuery += ' and SubjectCount eq 0)'
      }
      else {
        bookStatusFilterQuery = ` and (BookStatusIdValue eq ${this.state.bookStatusFilter})`
      }
    }

    const filterPending = `&filterPending=${this.state.filterPending}`

    const adminParam = this.state.isAdmin === true ? '&isAdmin=true' : ''

    odataMasterQuery += odataSearchTermFilterQuery 
      + odataCheckboxFilterQueryYears 
      + odataCheckboxFilterQueryContent 
      + odataCheckboxFilterQueryMeetingType 
      + odataCheckboxFilterQueryReviews
      + bookStatusFilterQuery;

    if (this.state.keyword === "subsearch") {
      axios
       .get(`${environment.baseUrl}/odata/odatasearchresults/subject?subject=${encodeURIComponent(termToSearch)}${odataMasterQuery}${filterPending}`, {
        params: {
          $top: this.state.perPage,
          $skip: this.state.pageSelectedIndex * this.state.perPage,
          $count: 'true',
          $orderby: this.state.sortTerm
        }
      })
      .then(response => {
        this.setState({
          data: response.data.items,
          numTotalResults: response.data.count,
          loading: false,
          pageCount: Math.ceil(response.data.count / this.state.perPage)
        });
      })
      .catch(function(error) {
        console.log(error);
      });
    }
    else {
    axios
       .get(`${environment.baseUrl}/odata/odatasearchresults?searchParam=${encodeURIComponent(this.state.searchTerm)}${odataMasterQuery}${filterPending}&status=${this.state.bookStatusFilter}${adminParam}`, {
        params: {
          $top: this.state.perPage,
          $skip: this.state.pageSelectedIndex * this.state.perPage,
          $count: 'true',
          $orderby: this.state.sortTerm
        }
      })
      .then(response => {
        this.setState({
          data: response.data.items,
          numTotalResults: response.data.count,
          loading: false,
          pageCount: Math.ceil(response.data.count / this.state.perPage)
        });
        window.scrollTo( 0, JSON.parse(sessionStorage.getItem('scrollPosition')) );
        sessionStorage.setItem('scrollPosition', 0);
      })
      .catch(function(error) {
        console.log('Error: ', error, error.message);
      });
    }
  }

  async componentWillReceiveProps(newProps) {
    let time = this.calculateTimeFilters();

    let search = (newProps.location.search !== '') ? queryString.parse(newProps.location.search) : {};

    let otherState = {
      searchTerm: newProps.match.params.searchTerm.trim(),
      pageSelectedIndex: search.page ? JSON.parse(search.page) : 0
    };

    this.setState({...time, ...otherState},
      () => {
        this.loadResultsFromServer();
      })
  }

  async componentDidMount() {
    this.loadResultsFromServer();
  }

  // fires when user searches a new term while they are already on the searchResultsPage
  // if the user is taken to this page by searching from another page,
  // the searchTerm is taken from the url on page load
  async updateSearchTerm(searchTerm, searchDropdownValue) {
    this.setState({ searchTerm: searchTerm.trim(), keywordType: '' });
    this.resetContentTypeFilters();

    //Set the filter data value based on the searchDropdownValue
    switch(searchDropdownValue) {
      case "Books & Reviews":
        this.setState({
          bookFilter: true, 
          allFilter: false, 
          conferencePaperFilter: false,
          isSeeMoreYearsHidden: false
        });
        break;
      case "Paper Abstracts":
        this.setState({
          bookFilter: false, 
          allFilter: false, 
          conferencePaperFilter: true,
          isSeeMoreYearsHidden: true
        });
        break;
      default:
        this.setState({
          bookFilter: false, 
          allFilter: true, 
          conferencePaperFilter: false,
          isSeeMoreYearsHidden: false
        });
        break;
    }

    this.loadResultsFromServer();
  }

  resetAllYears() {
    this.setState({
      twentyTwentyOneToPresent: false,
      twentySixteenToTwentyTwenty: false,
      twentyElevenToTwentyFifteen: false,
      twoThousandSixToTwentyTen: false,
      twoThousandOneToTwoThousandFive: false,
      ninetySixToTwoThousand: false,
      ninetyOneToNinetyFive: false,
      eightySixToNinety: false,
      eightyOneToEightyFive: false,
      seventySixToEighty: false,
      seventyOneToSeventyFive: false,
      uptoSeventy: false
    });
  }

  resetContentTypeFilters = () => {
    this.setState(
      {
        bookFilter: false,
        allFilter: true,
        conferencePaperFilter: false,
        selectedContentTypeOption: "allFilter",
      });

      this.sessionStorage.setItem("scrollPosition", 0);
  }

  // fires when user clicks on a new page number
  handlePageClick(data) {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perPage);

    this.setState({ offset: offset, pageSelectedIndex: selected }, () => {
      this.setState({redirect:true});
    });
  };

  // fires when user updates the number of results per page in the select dropdown
  updateResultsPerPage(event, pageSelectedIndex, perPage) {
    let radix = 10;
    if (this.state.pageSelectedIndex * parseInt(event.target.value, radix) > this.state.numTotalResults) {
      this.setState({ pageSelectedIndex: Math.floor(this.state.numTotalResults / parseInt(event.target.value, radix)) });
    }
    this.setState({ offset: 0, perPage: parseInt(event.target.value, radix) }, () => {
      sessionStorage.setItem('perPage', this.state.perPage);
      this.loadResultsFromServer();
    });
    window.scrollTo(0, 0);
  };

  // fires when user selects a new term to sort by in the Sort By select dropdown
  sortResults = event => {
    let sortTerm = event.target.value;
    this.setState({ redirect: true, sortTerm: sortTerm, pageSelectedIndex: 0 }, () => {
      this.loadResultsFromServer();
    });
  };

  handleContentTypeClick = event => {
    let value = 'All';

    switch(event.currentTarget.value) {
      case 'bookFilter':
        value = 'Books & Reviews'
        this.resetAllYears();
        break;
      case 'conferencePaperFilter':
        value = "Paper Abstracts";
        this.resetAllYears();
        break;
      default:
          value = 'All';
          this.resetAllYears();
          break;
    }

    this.setState({ selectedContentTypeOption: event.target.value, searchDropdownValue: value }, () => {
      this.onFilterClick(this.state.selectedContentTypeOption);
    });
  };

  handleMeetingTypeClick = event => {
    this.onFilterClick(event.target.value);
  };

  // first when user clicks on one of the filters on the left side
  onFilterClick = (filterName, value) => {
    switch (filterName) {
      case 'twentyTwentyOneToPresent':
        this.setState({ twentyTwentyOneToPresent: !this.state.twentyTwentyOneToPresent, redirect: true });
        break;
      case 'twentySixteenToTwentyTwenty':
        this.setState({ twentySixteenToTwentyTwenty: !this.state.twentySixteenToTwentyTwenty, redirect: true });
        break;
      case 'twentyElevenToTwentyFifteen':
        this.setState({ twentyElevenToTwentyFifteen: !this.state.twentyElevenToTwentyFifteen, redirect: true });
        break;
      case 'twoThousandSixToTwentyTen':
        this.setState({ twoThousandSixToTwentyTen: !this.state.twoThousandSixToTwentyTen, redirect: true });
        break;
      case 'twoThousandOneToTwoThousandFive':
        this.setState({ twoThousandOneToTwoThousandFive: !this.state.twoThousandOneToTwoThousandFive, redirect:true });
        break;
      case 'ninetySixToTwoThousand':
        this.setState({ ninetySixToTwoThousand: !this.state.ninetySixToTwoThousand, redirect: true });
        break;
      case 'ninetyOneToNinetyFive':
        this.setState({ ninetyOneToNinetyFive: !this.state.ninetyOneToNinetyFive, redirect: true });
        break;
      case 'eightySixToNinety':
        this.setState({ eightySixToNinety: !this.state.eightySixToNinety, redirect: true });
        break;
      case 'eightyOneToEightyFive':
        this.setState({ eightyOneToEightyFive: !this.state.eightyOneToEightyFive, redirect: true });
        break;
      case 'seventySixToEighty':
        this.setState({ seventySixToEighty: !this.state.seventySixToEighty, redirect: true });
        break;
      case 'seventyOneToSeventyFive':
        this.setState({ seventyOneToSeventyFive: !this.state.seventyOneToSeventyFive, redirect: true });
        break;
      case 'uptoSeventy':
        this.setState({ uptoSeventy: !this.state.uptoSeventy, redirect: true });
        break;
      case 'bookFilter':
          sessionStorage.setItem('searchDropdownValue', "Books & Reviews");
          this.setState({ 
            bookFilter: true, 
            allFilter: false, 
            conferencePaperFilter: false,
            annualMeetingFilter: false, 
            internationalMeetingFilter: false,
            booksAll: true,
            reviewedFilter: false,
            notReviewedFilter: false,
            searchDropdownValue: 'Books & Reviews',
            redirect: true
          });
        break;
      case 'conferencePaperFilter':
          sessionStorage.setItem('searchDropdownValue', "Paper Abstracts");
          this.setState({ 
            conferencePaperFilter: true, 
            allFilter: false, 
            bookFilter: false, 
            reviewedFilter: false, 
            allMeetingFilter: true,
            annualMeetingFilter: false,
            internationalMeetingFilter: false,
            searchDropdownValue: 'Paper Abstracts',
            redirect: true
          });
        break;
      case 'annualMeetingFilter':
        sessionStorage.setItem('searchDropdownValue', "Paper Abstracts");
        this.setState({ 
          annualMeetingFilter: true, 
          internationalMeetingFilter: false, 
          allMeetingFilter: false,
          searchDropdownValue: 'Paper Abstracts',
          redirect: true
        });
        break;
      case 'internationalMeetingFilter':
        sessionStorage.setItem('searchDropdownValue', "Paper Abstracts");
        this.setState({ 
          internationalMeetingFilter: true,
          annualMeetingFilter: false, 
          allMeetingFilter: false,
          searchDropdownValue: 'Paper Abstracts',
          redirect: true
        });
        break;
      case 'allMeetingFilter':
        sessionStorage.setItem('searchDropdownValue', "Paper Abstracts");
        this.setState({ 
          allMeetingFilter: false,
          annualMeetingFilter: false, 
          internationalMeetingFilter: false, 
          searchDropdownValue: 'Paper Abstracts',
          redirect: true
        });
        break;
      case 'booksAll':
        sessionStorage.setItem('searchDropdownValue', "Books & Reviews");
        this.setState({
          reviewedFilter: false,
          notReviewedFilter: false,
          booksAll: true,
          searchDropdownValue: 'Books & Reviews',
          redirect: true
        });
        break;
      case 'reviewedFilter':
        sessionStorage.setItem('searchDropdownValue', "Books & Reviews");
        this.setState({ 
          reviewedFilter: true,
          notReviewedFilter: false,
          booksAll: false,
          searchDropdownValue: 'Books & Reviews',
          redirect: true
        });
        break;
      case 'notReviewedFilter':
        sessionStorage.setItem('searchDropdownValue', "Books & Reviews");
          this.setState({ 
            notReviewedFilter: true,
            reviewedFilter: false,
            booksAll: false,
            searchDropdownValue: 'Books & Reviews',
            redirect: true
          });
        break;
      case 'bookStatusFilter':
        this.setState({bookStatusFilter: value, redirect: true})
        break;
      case '':
        //Reload data from server after resetting the filter data
        this.loadResultsFromServer();
        break;
      default:
        sessionStorage.setItem('searchDropdownValue', "All");
        this.setState({ 
          allFilter: true, 
          conferencePaperFilter: false, 
          bookFilter: false, 
          reviewedFilter: false,
          annualMeetingFilter: false, 
          internationalMeetingFilter: false,
          searchDropdownValue: 'Books & Reviews',
          redirect: true
        });
        break;
    }

    this.setState({ pageSelectedIndex: 0 });
  };

  displayShowingText(pageSelectedIndex, perPage, totalNumResults, searchTerm, dataLength) {
    if (totalNumResults < 0) {
      return ''
    }
    
    if (totalNumResults > 0) {
      return `Showing ${pageSelectedIndex * perPage + 1}-${pageSelectedIndex * perPage +
        (dataLength || perPage)} of ${totalNumResults} results for "${decodeURIComponent(searchTerm)}"`;
    }
    else {
      return `No results found for "${decodeURIComponent(searchTerm)}"`;
    }
  };

  onSearchResultClick = () => {
    const scrollPosition = document.scrollingElement.scrollTop;

    sessionStorage.setItem('scrollPosition', scrollPosition);
    sessionStorage.setItem('selectedContentTypeOption', JSON.stringify(this.state.selectedContentTypeOption));
    sessionStorage.setItem('perPage', this.state.perPage);

    this.setSearchDropdownValueToSessionStorage();
  };

  setSearchDropdownValueToSessionStorage() {
    var selectedContentTypeOption = JSON.parse(sessionStorage.getItem('selectedContentTypeOption'));
    switch (selectedContentTypeOption) {
      case "bookFilter":
        this.setState({searchDropdownValue: 'Books & Reviews'});
        break;
      case "conferencePaperFilter":
        this.setState({searchDropdownValue: 'Paper Abstracts'});
        break;
      default:
        this.setState({searchDropdownValue: 'All'});
        break;
    }
  };

  renderLoaderOrSearchResults = () => {
    if (this.state.loading) {
      return (
        <div className="sk-circle">
          <div className="sk-circle1 sk-child" />
          <div className="sk-circle2 sk-child" />
          <div className="sk-circle3 sk-child" />
          <div className="sk-circle4 sk-child" />
          <div className="sk-circle5 sk-child" />
          <div className="sk-circle6 sk-child" />
          <div className="sk-circle7 sk-child" />
          <div className="sk-circle8 sk-child" />
          <div className="sk-circle9 sk-child" />
          <div className="sk-circle10 sk-child" />
          <div className="sk-circle11 sk-child" />
          <div className="sk-circle12 sk-child" />
        </div>
      );
    }

    var term = this.state.keywordType === 'subsearch' ? '' : this.state.searchTerm;

    let type = this.determineType();

    return <SearchResultsList 
        searchResults={this.state.data} 
        onSearchResultClick={this.onSearchResultClick} 
        searchTerm={term}
        type={type}      
        bookDetailsPath={this.props.bookDetailsPath}
        displayCreatedAndStatus={this.props.displayCreatedAndStatus}
      />
  };

  determineType() {
    if ( this.state.bookFilter === true ) {
      return 1;
    }

    if( this.state.conferencePaperFilter === true ) {
      return 2;
    }

    return 0;
  }

  generateRedirect() {
    return this.props.getRedirect(this);
  }

  render() {
    if ( this.state.redirect ) {
      this.setState({redirect: false}, () => window.location = this.generateRedirect());
      return "";
    }

    return this.props.calculateSearchComponent(this);
  }
}
