import React from 'react'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

export default class DataCallerBaseComponent extends React.Component {
    constructor(props) {
        super(props)

        this.axiosHeaders = DefaultConfig.getDefaultConfig()
        this.user = JSON.parse(sessionStorage.getItem('loggedInUser'))
    }
}
