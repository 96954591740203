import React, { Fragment } from 'react'
import { ModalConsumer } from "../../Containers/Login/loginContext";
import { ListField, BookFormatModal } from '../componentsBarrel'

export class BookFormats extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            formats: props.formats ? props.formats : []
        }
    }

    showBookFormatModal(showModal, self, item, index) {
        let close = (data, self) => {
            if (data) {
                let array = self.state.formats

                if (data.deleteRequested) {
                    array.splice(index, 1)
                }
                else if (data.edit) {
                    let record = array[index]
                    record.isbn = data.isbn
                    record.price = data.price
                    record.format = data.format
                    record.formatTypeId = data.formatTypeId
                    array[index] = record;
                }
                else {
                    array.push(data)
                }

                self.setState({
                    formats: array
                })
            }
        }

        showModal(BookFormatModal, { onClose: close, parent: self, item: item })
    }

    addModal(event, modal, showModal) {
        event.preventDefault();
        modal(showModal, this)
    }

    editModal(event, modal, showModal, item, index) {
        event.preventDefault();
        if (item !== undefined) {
            modal(showModal, this, item, index)
        }
    }

    getModal() {
        return (
            <div>
                <span>
                    <ModalConsumer>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="modal-button">
                                    <button className="EditButton" onClick={(e) => this.addModal(e, this.showBookFormatModal, showModal)}>
                                        Add Format
                                    </button>
                                </div>
                                <div className="col-xs-10">
                                    <ListField name="formatList"
                                        required
                                        data={this.state.formats}
                                        displayitem={this.displayFormat}
                                        modal={this.showBookFormatModal}
                                        showModal={showModal}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </ModalConsumer>
                </span>
            </div>
        )
    }

    displayFormat = (item, index, modal, showModal) => {
        let format = this.translateFormatType(item.format) + ' (ISBN: ' + item.isbn
        
        if (item.price && item.price !== '') {
            format += ', Price: ' + item.price
        }

        format += ')'

        return (
            <div key={index}>
                <a onClick={(e) => this.editModal(e, modal, showModal, item, index)}>{format}</a>
            </div>
        )
    }

    translateFormatType(type) {
        if ( type === 'EBook' ) {
            return 'E-Book'
        }

        return type
    }

    render() {
        return (
            <div className="select-title">
                <span className="select-title-text">
                    <span className="requiredMarker">* </span>
                    <strong>Formats</strong>
                </span>
                
                {this.getModal()}
            </div>
        )
    }
}
