import React, {Fragment} from 'react'
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'
import DatePicker from 'react-datepicker'
import ReactTooltip from 'react-tooltip'
import { Helmet } from 'react-helmet'

import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { CustomDateInput } from '../componentsBarrel'

export class BaseSummaryReport extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: false
        }

        if (!this.user || this.user === null) {
            window.location = '/'
            return
        }
    } 

    componentDidMount() {
        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        let fromDate = new Date()
        let toDate = new Date()

        fromDate.setDate(fromDate.getDate())
        toDate.setDate(toDate.getDate())

        this.setfromDate(fromDate)
        this.settoDate(toDate)
    }

    setfromDate = (date) => {
        date.setHours(0, 0, 0, 0)

        this.setState({
            fromDate: date,
            fromDateText: date.toLocaleString('en-US', options) + ' 12:00 AM'
        })
    }

    settoDate = (date) => {
        date.setHours(23, 59, 59, 999)

        this.setState({
            toDate: new Date(date),
            toDateText: date.toLocaleString('en-US', options) + ' 11:59:59.999 PM'
        })
    }

    getYesterday() {
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        return yesterday
    }

    filtertoDate = (date, fromDate) => {
        let yesterday = this.getYesterday()
        return (date <= yesterday) && date >= fromDate
    }

    filterfromDate = (date) => {
        let yesterday = this.getYesterday()
        return date <= yesterday
    }

    onCancel = (e) => {
        window.location = '/admin/reports'
    }

    onSubmit = async (e) => {
        this.setState({loading: true}, async () => {
            await this.props.onSubmit()
            this.setState({loading: false})
        })
    }

    onDownload = () => {
        let button = document.getElementById('table-xls-button')
        button.click()
    }

    getExcelButton = () => {
        return (
            <button
                id = "table-xls-button"
                className = "download-table-xls-button"
            ></button>
        )
    }

    render()
    {
        return (
            <Fragment>
                {getBusyIndicator(this.state.loading, 
                    <div className="rbl-export">
                        <Helmet>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <ReactTooltip multiline={true}/>
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="rbl-export-container">
                                        <span className="title-text">{this.props.reportTitle}</span>
                                    </div>

                                    <div className="export-content">
                                        <div className="row rbl-date-pick">
                                            <div className="col-xs-4"><strong>From</strong></div>
                                            <div className="col-xs-5"><strong>To</strong></div>
                                        </div>

                                        <div className="row rbl-date-pick">
                                            <div className="col-xs-4">
                                                <DatePicker
                                                    selected={this.state.fromDate}
                                                    customInput={<CustomDateInput formatted={this.state.fromDateText}/>}
                                                    onChange={date => this.setfromDate(date)}
                                                    filterDate={this.filterfromDate}
                                                />
                                            </div>
                                            <div className="col-xs-5">
                                                <DatePicker
                                                    selected={this.state.toDate}
                                                    customInput={<CustomDateInput formatted={this.state.toDateText}/>}
                                                    onChange={date => this.settoDate(date)}
                                                    filterDate={date => this.filtertoDate(date, this.state.fromDate)}
                                                />
                                            </div>
                                        </div>

                                        <div className="button-section row col-xs-13 text-center">
                                            <button className="SubmitButton" onClick={this.onSubmit}>Submit</button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.props.getDataDisplay()}
                    </div>
                )}
            </Fragment>
        )
    }
}

const options = { 
timeZone: "America/New_York", 
hour12: true,
year: 'numeric',
month: 'long',
day: 'numeric',
//hour: 'numeric',
//second: 'numeric',
//minute: 'numeric'
};
