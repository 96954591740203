import React, { Fragment } from 'react'
import { ModalConsumer } from "../../Containers/Login/loginContext";
import { BookStatusModal } from './bookStatusModal'
import { BookStatusHelper } from '../../Helpers/bookStatusHelper'

import './bookStatus.css'

export class BookStatus extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            bookStatusId: props.bookStatusId,
            note: props.note,
            bookId: props.bookId
        }
    }

    onEditStatus(e, showModal) {
        e.preventDefault();

        const self = this

        const save = (data) => {
            self.setState({
                bookStatusId: data.bookStatusId,
                note: data.note
            }, () => {
                if (self.props.onSave) {
                    self.props.onSave(data)
                }
            })
        }

        const params = {
            onSave: save,
            bookStatusId: this.state.bookStatusId,
            note: this.state.note,
            bookId: this.state.bookId
        }

        showModal(BookStatusModal, params)
    }

    changeStatus = (newStatusId) => {
        this.setState({
            bookStatusId: newStatusId
        })
    }

    calculateStatusText() {
        return BookStatusHelper.translateStatus(this.state.bookStatusId)
    }

    getModal() {
        return (
            <div>
                <span>
                    <ModalConsumer>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="col-xs-10">
                                    <strong>Status: </strong>
                                    <a className="bookStatusEditLink" onClick={(e) => this.onEditStatus(e, showModal)}>
                                        {this.calculateStatusText()}
                                    </a>
                                </div>
                            </Fragment>
                        )}
                    </ModalConsumer>
                </span>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.getModal()}
            </div>
        )
    }
}
