import * as React from 'react';
import bookImage from '../../Assets/Images/book_with_image.png';
import { Link } from 'react-router-dom';

import './searchResultBook.css';

export const SearchResultBook = props => {
  return (
    <Link to={props.detailsBasePath + props.searchResultItem.id + '?search=' + props.searchTerm + "&type=" + props.type}
      key={props.index} 
      className="SearchResultItemSection" 
      onClick={props.onSearchResultClick}>
        <div className="SearchResultItemImageContainer">
          <img src={props.searchResultItem.imgUrl || bookImage} className="img-fluid SearchResultItemImage" id="SearchResultItemImage" alt="Search Result Item" />
        </div>
        <div className="SearchResultItemDetailsContainer">
          <div className="SearchResultItemTitleContainer" id="SearchResultItemTitleContainer">
            {props.searchResultItem.meetingType ? props.searchResultItem.title + ': ' + props.searchResultItem.meetingType : props.searchResultItem.title}
          </div>
          <div className="SearchResultItemAuthorContainer" id="SearchResultItemAuthorContainer">
              <OptionalContributor title="" names={props.searchResultItem.presenterNames}/>
          </div>
          <div className="SearchResultItemAuthorContainer" id="SearchResultItemAuthorContainer">
            <OptionalContributor title='editor' names={props.searchResultItem.coPresenterNames}/>
          </div>
          <div className="SearchResultItemAuthorContainer" id="SearchResultItemAuthorContainer">
            <OptionalContributor title='translator' names={props.searchResultItem.translators}/>
          </div>
          <div className="SearchResultItemNumReviewsContainer" id="SearchResultItemNumReviewsContainer">
            {props.searchResultItem.extraDesc1}, {props.searchResultItem.year}
          </div>
          <div className="SearchResultItemPublicationDateContainer" id="SearchResultItemPublicationDateContainer"></div>
          <div className="SearchResultItemNumReviewsContainer" id="SearchResultItemNumReviewsContainer">
            {props.searchResultItem.meetingLocation}
          </div>
          {props.displayCreatedAndStatus(props.searchResultItem)}
        </div>
    </Link>
  )
}

const OptionalContributor = (props) => {
  if (props.names) {
    return (
      <div>
        {props.names}{props.title ? ', ' + props.title : ''}
      </div>
    )
  }
  else return null;
}
