import React, { Fragment } from 'react'
import { JournalNotifications } from './journalNotifications'
import { ModalConsumer } from "../Login/loginContext";
import ModalRoot from "../Login/loginRoot";

import './journalNotificationsLandingPage.css'

export class JournalNotificationsLandingPage extends React.Component {
    componentDidMount() {
        document.getElementById('helpaAndLoginContainer').style.visibility = 'hidden'
        document.getElementById('RightFooterContainer').style.visibility = 'hidden'

        this.showDialog.click()
    }

    onRequestClose = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="journal-notifications-landing-page">
                <ModalRoot />
                <ModalConsumer>
                {({ showModal }) => (
                    <Fragment>
                        <div ref={x => this.showDialog = x} onClick={() => showModal(JournalNotifications, {onClose: this.onRequestClose})}></div>
                    </Fragment>
                )}
                </ModalConsumer>
            </div>
        )
    }
}
