import React, { Fragment } from 'react'
import { ModalConsumer } from "../../Containers/Login/loginContext";
import { ListField, BookReviewModal } from '../componentsBarrel'

import './bookReviews.css'

export class BookReviews extends React.Component {
    constructor(props) {
        super(props)

        this.loggedInUser = null;
        if (sessionStorage.getItem("loggedInUser")) {
          let user = JSON.parse(sessionStorage.getItem("loggedInUser"));
          this.loggedInUser = `${user.firstName} ${user.lastName}`
        }

        this.state = {
            reviews: props.reviews ? props.reviews : []
        }
    }

    showBookReviewModal(showModal, self, item, index) {
        let close = (data, self) => {
            if (data) {
                let array = self.state.reviews

                if (data.deleteRequested) {
                    array.splice(index, 1)
                }
                else if (data.edit) {
                    let record = array[index]

                    record.names = data.names
                    record.reviewPDFName = data.reviewPDFName
                    record.needsUploading = data.needsUploading ? data.needsUploading : false
                    record.selectedFile = data.selectedFile

                    array[index] = record;
                }
                else {
                    data.submitter = self.loggedInUser
                    array.push(data)
                }

                self.props.onReviewsUpdated(array)
            }
        }

        showModal(BookReviewModal, { onClose: close, parent: self, item: item })
    }

    displayReview = (item, index, modal, showModal) => {
        return (
            <span key={index}>
                <div className="review-citation-block">
                    <a onClick={(e) => this.editModal(e, modal, showModal, item, index)}>{item.names}</a>
                </div>
            </span>
        )
    }

    addModal(event, modal, showModal) {
        event.preventDefault();
        modal(showModal, this)
    }

    editModal(event, modal, showModal, item, index) {
        event.preventDefault();
        if (item !== undefined) {
            modal(showModal, this, item, index)
        }
    }

    getModal() {
        return (
            <div>
                <span>
                    <ModalConsumer>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="modal-button">
                                    <button className="EditButton" onClick={(e) => this.addModal(e, this.showBookReviewModal, showModal)}>
                                        Add Review
                                    </button>
                                </div>
                                <div className="col-xs-10">
                                    <ListField name="formatList"
                                        required
                                        data={this.state.reviews}
                                        displayitem={this.displayReview}
                                        modal={this.showBookReviewModal}
                                        showModal={showModal}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </ModalConsumer>
                </span>
            </div>
        )
    }

    render() {
        return (
            <div className="select-title">
                <span className="select-title-text">
                    <strong>Reviews</strong>
                </span>
                
                {this.getModal()}
            </div>
        )
    }
}
