import React from 'react'
import Modal from 'react-modal'
import { InputField } from '../componentsBarrel'

import '../Styles/dialogButtons.css'
import './bookFormatModal.css'

export class BookFormatModal extends React.Component {

    constructor(props) {
        super(props)

        this.showDelete = props.item ? true : false

        this.state = {
            format: props.item ? props.item.format : 'Hardcover',
            formatTypeId: props.item ? props.item.formatTypeId : 2,
            isbn: props.item ? props.item.isbn : '',
            //price: props.item ? props.item.price : '',
            edit: props.item ? true : false
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();
        this.props.onRequestClose();
    }

    onRequestDelete = (e) => {
        e.preventDefault();
        let data = {
            ...this.state,
            deleteRequested: true
        }
        this.onRequestClose(e);
        this.props.onClose(data, this.props.parent);

    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (/[^A-Z0-9]/.test(this.state.isbn)) {
            alert('ISBN must be alphanumeric only (upper case only).')
            return false
        }

        this.onRequestClose(e);
        this.props.onClose(this.state, this.props.parent);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeAlphanumericOnly = (e) => {
        let value = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
        value = value.toUpperCase()
        this.setState({[e.target.name]: value})
    }

    getDeleteButton() {
        if ( this.showDelete ) {
            return (
                <button className="secondary-button" onClick={(e) => this.onRequestDelete(e)}>Delete</button>
            )
        }
    }

    getFormatTypes() {
        return (
            <div className="format-type-group">
                <label>
                    <input type="radio"
                        name="format-type"
                        value="Hardcover"
                        checked={this.state.format === 'Hardcover'}
                        className="format-check-input"
                        onChange={(e) => this.setState({format: 'Hardcover', formatTypeId: 2})}
                    />
                    Hardcover
                </label>

                <label>
                    <input type="radio"
                        name="format-type"
                        value="Paperback"
                        checked={this.state.format === 'Paperback'}
                        className="format-check-input"
                        onChange={(e) => this.setState({format: 'Paperback', formatTypeId: 1})}
                    />
                    Paperback
                </label>

                <label>
                    <input type="radio"
                        name="format-type"
                        value="EBook"
                        checked={this.state.format === 'EBook'}
                        className="format-check-input"
                        onChange={(e) => this.setState({format: 'EBook', formatTypeId: 4})}
                    />
                    E-Book
                </label>

                <label>
                    <input type="radio"
                        name="format-type"
                        value="Other"
                        checked={this.state.format === 'Other'}
                        className="format-check-input"
                        onChange={(e) => this.setState({format: 'Other', formatTypeId: 3})}
                    />
                    Other
                </label>
            </div>
        )
    }

    render() {
        return (
            <main>
                <div className="book-format-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="BookFormatCustomModal book-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>Book Format</h2>

                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="fieldGroup">
                                    <div>
                                        <strong>Format</strong>
                                    </div>
                                    <div>
                                        {this.getFormatTypes()}
                                    </div>
                                </div>
                                <div className="fieldGroup">
                                    <InputField title="ISBN" 
                                        name="isbn" 
                                        onChange={this.onChangeAlphanumericOnly} 
                                        value={this.state.isbn} 
                                        maxLength="13"
                                        required
                                    />
                                </div>
                                {/* <div className="fieldGroup">
                                    <InputField title="Price" 
                                        name="price" 
                                        onChange={this.onChange} 
                                        value={this.state.price} 
                                        maxLength="255"
                                    />
                                </div> */}
                            </div>
                            <div className="dialogButtons">
                                <button className="default-button">Save</button>
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                                {this.getDeleteButton()}
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
