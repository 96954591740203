import React, {Fragment} from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import environment from "../../Environments/environments";
import { BookStatusHistoryModal } from './bookStatusHistoryModal'
import { ModalConsumerStacked } from '../../Containers/Login/loginContext'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { BookStatusEnum } from '../../Helpers/bookStatusHelper';

import '../Styles/dialogButtons.css'
import './bookStatusModal.css'

export class BookStatusModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            bookStatusId: props.bookStatusId,
            note: props.note,
            bookId: props.bookId,
            rblDisabled: true,
            sblDisabled: false,
            loading: true
        }
    }

    componentDidMount() {
        this.setBookStatus(this.state.bookStatusId)

        const url = environment.baseUrl + '/api/book/bookStatusHistory/' + this.state.bookId
        const self = this

        axios.get(url)
            .then(response => {
                self.setState({
                    history: response.data.history,
                    loading: false
                })
            })
            .catch(error => {
                self.setState({ loading: false })
                console.error('Error getting history: ', error)
                alert('Error getting history: ' + error.response.data.message)
            })
    }

    setBookStatus(statusId) {
        let rblDisabled = (statusId < 3)
        let sblDisabled = (statusId === 0)

        this.setState({
            bookStatusId: statusId,
            rblDisabled: rblDisabled,
            sblDisabled: sblDisabled
        })
    }

    toggleWithdrawn() {
        (this.state.bookStatusId === 0) ? this.onChange(1) : this.onChange(0)
    }

    onStatusHistory = (e, showModal) => {
        showModal(BookStatusHistoryModal, {bookId: this.props.bookId, history: this.state.history})
    }

    onRequestClose = (e) => {
        e.preventDefault();

        if (this.props.onRequestClose) {
            // this is what allows the dialog to close
            this.props.onRequestClose();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.onRequestClose(e)

        if (!this.validateForm()) {
            return false;
        }

        if (this.props.onSave) {

            const data = {
                bookStatusId: this.state.bookStatusId,
                note: this.state.note
            }

            this.props.onSave(data);
        }

        return true;
    }

    validateForm() {
        return true;
    }

    onChange(newStatus) {
        if (this.state.bookStatusId === BookStatusEnum.RBLPublished) {
            alert('Reviews already published. You must delete all reviews before you can change the status on this screen.')
            return false
        }

        this.setBookStatus(newStatus)
    }

    onNoteChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getStatusHistoryLink(showModal) {
        return (
            <a onClick={(e) => this.onStatusHistory(e, showModal)}>Status History</a>
        )
    }

    getStatusHistoryBlock() {
        return (
            <div>
                <span>
                    <ModalConsumerStacked>
                        {({ showModal }) => (
                            <Fragment>
                                <strong>Notes</strong>
                                {this.getStatusHistoryLink(showModal)}
                            </Fragment>
                        )}
                    </ModalConsumerStacked>
                </span>
            </div>
        )
    }

    render() {
        return (
            <main>
                <div className="book-status-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="BookStatusCustomModal book-status-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>Status</h2>

                        {getBusyIndicator(this.state.loading,
                            <form onSubmit={this.handleSubmit}>
                                <div className="fieldGroup">
                                    <div className="col-sm-7">
                                        <div>
                                            <label>Approve for SBL Central?</label>
                                        </div>

                                        <div className="status-type-group">
                                            <label>
                                                <input type="radio"
                                                    name="status-type"
                                                    value="approve"
                                                    checked={this.state.bookStatusId >= 3}
                                                    className="status-check-input"
                                                    onChange={(e) => this.onChange(3)}
                                                    disabled={this.state.sblDisabled}
                                                />
                                                Approve
                                            </label>
                                        </div>

                                        <div className="status-type-group">
                                            <label>
                                                <input type="radio"
                                                    name="status-type"
                                                    value="reject"
                                                    checked={this.state.bookStatusId === 2}
                                                    className="status-check-input"
                                                    onChange={(e) => this.onChange(2)}
                                                    disabled={this.state.sblDisabled}
                                                />
                                                Reject
                                            </label>
                                        </div>

                                        <div className="status-type-group">
                                            <label>
                                                <input type="radio"
                                                    name="status-type"
                                                    value="unanswered"
                                                    checked={this.state.bookStatusId <= 1}
                                                    className="status-check-input"
                                                    onChange={(e) => this.onChange(1)}
                                                    disabled={this.state.sblDisabled}
                                                />
                                                Unanswered
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-5">
                                        <div>
                                            <label>Approve for RBL?</label>
                                        </div>

                                        <div className="status-type-group">
                                            <label>
                                                <input type="radio"
                                                    name="rbl-status-type"
                                                    value="approve"
                                                    checked={this.state.bookStatusId === 5 
                                                        || this.state.bookStatusId === 6}
                                                    className="status-check-input"
                                                    onChange={(e) => this.onChange(5)}
                                                    disabled={this.state.rblDisabled}
                                                />
                                                Approve
                                            </label>
                                        </div>

                                        <div className="status-type-group">
                                            <label>
                                                <input type="radio"
                                                    name="rbl-status-type"
                                                    value="reject"
                                                    checked={this.state.bookStatusId === 4}
                                                    className="status-check-input"
                                                    onChange={(e) => this.onChange(4)}
                                                    disabled={this.state.rblDisabled}
                                                />
                                                Reject
                                            </label>
                                        </div>

                                        <div className="status-type-group">
                                            <label>
                                                <input type="radio"
                                                    name="rbl-status-type"
                                                    value="unanswered"
                                                    checked={this.state.bookStatusId === 3}
                                                    className="status-check-input"
                                                    onChange={(e) => this.onChange(3)}
                                                    disabled={this.state.rblDisabled}
                                                />
                                                Unanswered
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div id="withdrawn">
                                    <label>
                                        <input type="checkbox"
                                            checked={this.state.bookStatusId === 0}
                                            onChange={(e) => this.toggleWithdrawn()}
                                        />
                                        Withdrawn by Publisher
                                    </label>
                                </div>
                                
                                <div id="status-note">
                                    <div>
                                        <div>
                                            {this.getStatusHistoryBlock()}
                                        </div>
                                        <div>
                                            <input name="note"
                                                onChange={this.onNoteChange}
                                                value={this.state.note}
                                                maxLength='75'
                                                className="form-control-small form-control"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="dialogButtons">
                                    <button className="default-button">Save</button>
                                    <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                                </div>
                            </form>
                        )}
                    </Modal>
                </div>
            </main>
        )
    }
}