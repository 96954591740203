import * as React from "react";
import { SearchContainer } from "../../Containers/containersBarrel";
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';
import rbllogo from "../../Assets/Images/rbl_logo.jpg";
import environment from "../../Environments/environments";
import axios from "axios";

import "./volunteerStyles.css";

export class Volunteer extends React.Component {
  constructor(props) {
    super(props);
    var loggedInUser = {};
    if (sessionStorage.getItem("loggedInUser")) {
      loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));
    }

    this.config = DefaultConfig.getDefaultConfig()

    let title = sessionStorage.getItem('book_' + this.props.match.params.Id);

    this.state = {
      firstName: loggedInUser.firstName,
      lastName: loggedInUser.lastName,
      emailAddress: loggedInUser.emailAddress,
      researchTeachingArea: "",
      selectedOption: "doctoralDegree",
      institution: "",
      positionHeld: "",
      qualifications: "",
      sblid: loggedInUser.sblId,
      bookId: this.props.match.params.Id,
      bookName: title
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  componentDidMount() {
    let user = sessionStorage.getItem("loggedInUser")
    if (!user) {
      window.location = '/'
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    let data = {
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      EmailAddress: this.state.emailAddress,
      ResearchArea: this.state.researchTeachingArea,
      Institution: this.state.institution,
      Position: this.state.positionHeld,
      Qualifications: this.state.qualifications,
      EducationalLevel: this.state.selectedOption,
      SblId: this.state.sblid,
      BookId: this.state.bookId
    }

    axios
      .post(`${environment.baseUrl}/api/volunteer`, data, this.config)
      .then(response => {
        this.props.history.replace('/home/volunteerConfirmation');
      })
      .catch(error => {
        console.error('Error Occured on Submit: ', error);
        alert('Error creating volunteer record: ' + error);
      });
  }

  handleOptionChange(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }

  render() {
    return (
      <div className="VolunteerPage">
        <div className="BackgroundImageSection">
          <div className="SearchAndTitleContainer">
            <SearchContainer
              className="Search"
              location={this.props.location.pathname}
              resetLocalStorage="true"
            />
          </div>
        </div>
        <div className="TextContainer container">
          <div className="logoContainer navbar-header navbar-left">
            <img className="logo-image" src={rbllogo} alt="rbl logo" />
          </div>

          <div className="VolunteerTextContainer">
            <span className="VolunteerText">Volunteer to Review</span>
          </div>

          <div className="VolunteerQuestions">
            <span>For questions relating to RBL, please contact <a href="mailto:rbladministrator@sbl-site.org?subject=RBL Volunteer Offer">rbladministrator@sbl-site.org.</a></span>
          </div>

          <br />

          <div className="col-sm-10">
            <form onSubmit={this.handleSubmit}>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                  &nbsp;
                </label>
                <div className="col-sm-6 noleftpadding required">
                  <label className="requiredMarker">
                    *
                  </label>
                  <label className="requiredText">
                    &nbsp;Required
                  </label>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                    Book:
                </label>
                <div className="col-sm-6 noleftpadding">
                  <input
                    name="book"
                    type="text"
                    value={this.state.bookName}
                    title={this.state.bookName}
                    onChange={this.handleChange}
                    readOnly
                    className="form-control-small form-control display-only"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                <label className="requiredMarker">*&nbsp;</label>
                  First Name:
                </label>
                <div className="col-sm-6 noleftpadding">
                  <input
                    name="firstName"
                    type="text"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    required
                    maxLength="100"
                    className="form-control-small form-control"
                    autoComplete="given-name"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                  <label className="requiredMarker">*&nbsp;</label>
                    Last Name:
                </label>
                <div className="col-sm-6 noleftpadding">
                  <input
                    name="lastName"
                    type="text"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    required
                    maxLength="100"
                    className="form-control-small form-control"
                    autoComplete="family-name"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                    Email Address:
                  </label>
                  <div className="col-sm-6 noleftpadding">
                    <input
                      name="email"
                      type="text"
                      value={this.state.emailAddress}
                      onChange={this.handleChange}
                      readOnly
                      className="form-control-small form-control display-only"
                    />
                  </div>
                </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                  <label className="requiredMarker">*&nbsp;</label>
                    Research or Teaching Area:
                </label>
                <div className="col-sm-6 noleftpadding">
                  <input
                    name="researchTeachingArea"
                    type="text"
                    value={this.state.researchTeachingArea}
                    onChange={this.handleChange}
                    required
                    maxLength="250"
                    className="form-control-small form-control"
                    autoFocus
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                  <label className="requiredMarker">*&nbsp;</label>
                  Highest Educational Level in Biblical Studies:
                </label>
                <div className="form-check-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="doctoralDegree"
                      id="doctoralDegree"
                      value="doctoralDegree"
                      checked={this.state.selectedOption === "doctoralDegree"}
                      onChange={this.handleOptionChange}
                    />
                    <span className="form-check-label" htmlFor="doctoralDegree">
                      &nbsp;Doctoral Degree
                    </span>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="doctoralCandidate"
                      id="doctoralCandidate"
                      value="doctoralCandidate"
                      checked={this.state.selectedOption === "doctoralCandidate"}
                      onChange={this.handleOptionChange}
                    />
                    <span className="form-check-label" htmlFor="doctoralCandidate">
                      &nbsp;Doctoral Candidate (ABD)
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                  Institution:
                </label>
                <div className="col-sm-6 noleftpadding">
                  <input
                    name="institution"
                    type="text"
                    value={this.state.institution}
                    onChange={this.handleChange}
                    maxLength="200"
                    className="form-control-small form-control"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                  Position Held:
                </label>
                <div className="col-sm-6 noleftpadding">
                  <input
                    type="text"
                    name="positionHeld"
                    value={this.state.positionHeld}
                    onChange={this.handleChange}
                    maxLength="200"
                    className="form-control-small form-control"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-right">
                  Description of Qualifications:
                </label>
                <div className="col-sm-6 noleftpadding">
                  <textarea
                    name="qualifications"
                    value={this.state.qualifications}
                    onChange={this.handleChange}
                    maxLength="500"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group row submit-group">
                <button id="submit" className="SubmitButton">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
