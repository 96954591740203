import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import ModalRoot, {ModalRootStacked} from "../../Containers/Login/loginRoot";
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'
import journalImage from "../../Assets/Images/book_with_image.png";
import { Checkbox } from 'react-bootstrap'
import { guardAdmin } from '../Admin/admin-base'
import { InputField, TextField } from '../componentsBarrel'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { MessageFlash } from '../../Helpers/messageFlashHelper'


import './journalDetail.css'

export class JournalDetail extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        let id = this.props.match.params.Id

        this.state = {
            id: id === 'new' ? 0 : id,
            isNew: (id === 'new'),
            title: '',
            preview: '',
            imageUrl: '',
            isActive: true
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.setState({loading: true}, () => this.loadFromServer());
    }

    loadFromServer() {
        let promises = [
            this.getJournals(),
        ]

        Promise.all(promises)
            .then(values => {
                this.loadValues(values[0])
                
                this.setState({
                    journals: values[0],
                    loading: false
                })
            })
    }

    getJournals() {
        if (this.state.isNew === false) {
            let url = `${environment.baseUrl}/api/journals/get/${this.state.id}` 
            return axios
                .get(url, this.axiosHeaders)
                .then(response => {
                    if (response.data.success === false) {
                        alert(response.data.message)
                        return {};
                    }

                    return response.data.journal
                })
                .catch(error => {
                    console.error('Error loading journal: ', error, error.response);
                    alert('Error Loading Journal');

                    return {}
                })
        }

        return {}
    }

    loadValues(journal) {
        //console.debug('JOURNAL: ', journal)
        this.setState({
            id: journal.id,
            title: journal.title ? journal.title : '',
            printISSN: journal.printISSN ? journal.printISSN : '',
            onlineISSN: journal.onlineISSN ? journal.onlineISSN : '',
            publisher: journal.publisher ? journal.publisher : '',
            url: journal.url ? journal.url : '',
            description: journal.description ? journal.description : '',
            issuesPerYear: journal.issuesPerYear ? journal.issuesPerYear : '',
            imageUrl: journal.imageUrl,
            isActive: journal.isActive === undefined ? true : journal.isActive,
            loading: false

        })
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeNumericOnly = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '')
        value = value.toUpperCase()
        this.setState({[e.target.name]: value})
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    onDelete = (e) => {
        e.preventDefault();

        if (!window.confirm('Are you sure you want to delete this journal?')) {
            return false;
        }
        
        axios
            .delete(environment.baseUrl + '/api/journals/delete/' + this.state.id, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                MessageFlash.setMessage('Journal deleted')
                this.props.history.goBack();
            })
            .catch(error => {
                console.error('Error deleting journal: ', error, error.response);
                alert('Error Deleting Journal');
            })
    }

    getDeleteButton() {
        if (this.state.isNew === true) {
            return ''
        }

        return (
            <button className="secondary-button" onClick={this.onDelete}>Delete</button>
        )
    }

    showPreview = (e) => {
        const self = this
        const file = e.target.files[0]
        const _URL = window.URL || window.webkitURL;

        let filename = e.target.value

        if (!this.checkExtension(filename)) {
            alert("Invalid file type. Image must be GIF, JPEG, or PNG format.")
            this.fileUpload.value = ''
            return false
        }

        const sizeInMb = file.size / 1024 / 1024
        if (sizeInMb > 5) {
            this.fileUpload.value = ''
            let sizeErrorMessage = "Image file must be equal to or less than 5 MB."
            alert(sizeErrorMessage + "  The selected file is " + Math.ceil(sizeInMb) + "mb.")
            return false
        }

        var reader = new FileReader()

        reader.onload = function (e) {
            let image = new Image();

            image.onload = function () {

                self.setState({
                    preview: e.target.result,
                    selectedFile: file
                })
            };

            image.src = _URL.createObjectURL(file);
        }

        reader.readAsDataURL(e.target.files[0])
    }

    checkExtension(filename) {
        var hash = {
            '.jpg': 1,
            '.jpeg': 1,
            '.png': 1,
            '.gif': 1
        };

        const re = /\.[0-9a-z]+$/i
        const ext = filename.toLowerCase().match(re);

        return hash[ext]
    }

    onBrowseClick = (e) => {
        e.preventDefault();
        this.fileUpload.click();
    }

    getImageDisplay() {
        let source = this.state.preview === '' ? this.state.imageUrl || journalImage : this.state.preview;

        return (
            <div id="imageDisplay">
                <img
                    id="JournalImage"
                    src={source}
                    alt="Journal"
                />
                <input type="file"
                    id="avatar" name="avatar"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                    onChange={this.showPreview}
                    ref={(ref) => this.fileUpload = ref}
                />
                <button id="file-button" onClick={this.onBrowseClick}>Browse</button>
            </div>
        )
    }

    validateForm() {
        if (this.state.title === null || this.state.title.trim() === '') {
            alert('Please enter a title.')
            return false
        }

        if (!this.state.publisher || this.state.publisher.trim() === '') {
            alert('Please enter a publisher.')
            return false
        }

        if (!this.state.description || this.state.description.trim() === '') {
            alert('Please enter a description.')
            return false
        }

        let issuesPerYear = parseInt(this.state.issuesPerYear)
        if (this.state.issuesPerYear === '' || issuesPerYear < 1 || issuesPerYear > 255) {
            alert('Please enter a number of issues between 1 and 255.')
            return false
        }

        if (this.state.printISSN === '' && this.state.onlineISSN === '') {
            alert('Print ISSN or Online ISSN is required.')
            return false
        }

        if ((!this.state.preview || this.state.preview === '') && (!this.state.imageUrl || this.state.imageUrl === '')) {
            alert('An image is required.')
            return false
        }

        return true
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.validateForm()) {
            return false;
        }

        const journalId = await this.saveData();

        if (journalId === -1) {
            return false;
        }

        if (!this.state.selectedFile) {
            MessageFlash.setMessage('Journal Saved Successfully')
            this.props.history.goBack();
            return
        }

        const formData = new FormData();
        formData.append('files', this.state.selectedFile)
        formData.append('titleId', 'J' + journalId)

        const url = environment.baseUrl + '/api/image/upload'
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.user.token
            }
        }

        axios
            .post(url, formData, config)
            .then(async response => {
                MessageFlash.setMessage('Journal Saved Successfully')
                this.props.history.goBack();
            })
            .catch(error => {
                console.error('Error occurred on image upload: ', error, error.response.data);
                alert('Error uploading image: ' + error);
            });
    }

    async saveData() {
        let model = {...this.state}

        model.printISSN = model.printISSN !== '' ? model.printISSN : null
        model.onlineISSN = model.onlineISSN !== '' ? model.onlineISSN : null
        model.description = model.description !== '' ? model.description : null

        return axios
            .post(environment.baseUrl + '/api/journals/save', model, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return -1
                }

                //console.debug('RESULTS: ', response.data)

                return response.data.journal
            })
            .catch(error => {
                console.error('Error saving journal: ', error, error.response);
                alert('Error Saving Journal');
            })
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    setIsActive() {
        // if (this.state.isClassicRBLImport === true) {
        //     return ''
        // }

        return (
            <div className="row col-xs-8 active-check-box-group">
                <Checkbox name="isActive" 
                    checked={this.state.isActive} 
                    onChange={this.onCheckboxChange}
                    id="activeCheckBox"
                >
                    Active
                </Checkbox>
            </div>
        )
    }

    render() {
        let header = this.state.isNew === true ? 'Add Journal' : 'Edit Journal'

        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="journal-detail">
                        <ModalRoot/>
                        <ModalRootStacked/>
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="journal-text-container">
                                        <span className="journal-text">{header}</span>
                                    </div>
                                </div>
                                <div className="row required">
                                    <div className="required-legend col-xs-12">
                                        <span className="requiredMarker">
                                            *
                                        </span>
                                        <span className="requiredText">
                                            &nbsp;Required
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-10">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row ImageAndDetailsRow"> 
                                        <div className="xol-xs-3 ImageSection">
                                            <div className="JournalImageContainer">
                                                {this.getImageDisplay()}
                                            </div>
                                        </div>
                                        <div className="col-xs-9 DetailsSection">
                                            <div className="DetailsContainer">
                                                <div>
                                                    <InputField title="Journal Title"
                                                        maxLength="250"
                                                        name="title"
                                                        value={this.state.title}
                                                        onChange={this.handleInputChange}
                                                        required
                                                        autoFocus
                                                    />
                                                </div>
                                                <div id="publisher-block">
                                                    <InputField title="Publisher"
                                                        maxLength="100"
                                                        name="publisher"
                                                        value={this.state.publisher}
                                                        onChange={this.handleInputChange}
                                                        required
                                                    />
                                                </div>
                                                <div id="issues-block">
                                                    <InputField title="Number of Issues Per Year"
                                                        maxLength="3"
                                                        name="issuesPerYear"
                                                        value={this.state.issuesPerYear}
                                                        onChange={this.onChangeNumericOnly}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row col-xs-10">
                                        <TextField title="Description"
                                            subtitle="(To italicize, wrap text in <i> and </i>)"
                                            maxLength="200"
                                            name="description"
                                            value={this.state.description}
                                            onChange={this.handleInputChange}
                                            rows="3"
                                            required
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="Print ISSN"
                                            maxLength="10"
                                            name="printISSN"
                                            value={this.state.printISSN}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="Online ISSN"
                                            maxLength="10"
                                            name="onlineISSN"
                                            value={this.state.onlineISSN}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="URL"
                                            maxLength="4000"
                                            name="url"
                                            value={this.state.url}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    {this.setIsActive()}
                                    <div className="col-xs-12">
                                        <div className="row col-xs-12 text-center button-group">
                                            <button className="SubmitButton">Submit</button>
                                            <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                            {this.getDeleteButton()}
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
