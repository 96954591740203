import * as React from "react";
import { SearchResultConferencePaper } from "../componentsBarrel";
import { SearchResultBook } from "../componentsBarrel";
import ConferencePaperImage from "../../Assets/Images/Paper-standard-image.png";
// import TwoThousandImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2000-AMcover.png";
// import TwoThousandOneImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2001-AMcover.png";
// import TwoThousandTwoImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2002-AMcover.png";
// import TwoThousandThreeImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2003-AMcover.png";
// import TwoThousandFourImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2004-AMCover.png";
// import TwoThousandFiveImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2005-AMCover.png";
// import TwoThousandSixImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2006-AMcover.png";
// import TwoThousandSevenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2007-AMcover.png";
// import TwoThousandEightImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2008-AMcover.png";
// import TwoThousandNineImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2009-AMcover.png";
// import TwoThousandTenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2010-AMcover.png";
// import TwoThousandElevenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2011-AMcover.png";
// import TwoThousandTwelveImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2012-AMcover.png";
// import TwoThousandThirteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2013-AMcover.png";
// import TwoThousandFourteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2014-AMcover.png";
// import TwoThousandFifteenImageA from "../../Assets/Images/SearchResultsPlaceholderImages/2015-AMcover.png";
// import TwoThousandSixteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2016-AMcover.png";
// import TwoThousandSeventeenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2017-AMcover.png";
// import TwoThousandEighteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2018-AMcover.png";
// import TwoThousandNineteenImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2019-AMcover.jpg";
// import TwoThousandTwentyImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2020-AMcover.png";
// import TwoThousandTwentyOneImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2021-AMcover.png";
// import TwoThousandTwentyTwoImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2022-AMcover.png";
// import TwoThousandTwentyThreeImageAM from "../../Assets/Images/SearchResultsPlaceholderImages/2023-AMcover.png";

// import TwoThousandImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2000-IMcover.jpg";
// import TwoThousandOneImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2001-IMcover.jpg";
// import TwoThousandTwoImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2002-IMcover.jpg";
// import TwoThousandThreeImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2003-IMcover.jpg";
// import TwoThousandFourImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2004-IMcover.jpg";
// import TwoThousandFiveImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2005-IMcover.jpg";
// import TwoThousandSixImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2006-IMcover.jpg";
// import TwoThousandSevenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2007-IMcover.jpg";
// import TwoThousandEightImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2008-IMcover.jpg";
// import TwoThousandNineImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2009-IMcover.jpg";
// import TwoThousandTenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2010-IMcover.jpg";
// import TwoThousandElevenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2011-IMcover.jpg";
// import TwoThousandTwelveImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2012-IMcover.jpg";
// import TwoThousandThirteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2013-IMcover.jpg";
// import TwoThousandFourteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2014-IMcover.jpg";
// import TwoThousandFifteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2015-IMcover.jpg";
// import TwoThousandSixteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2016-IMcover.jpg";
// import TwoThousandSeventeenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2017-IMcover.jpg";
// import TwoThousandEighteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2018-IMcover.jpg";
// import TwoThousandNineteenImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2019-IMcover.png";
// import TwoThousandTwentyTwoImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2022-IMcover.png";
// import TwoThousandTwentyThreeImageIM from "../../Assets/Images/SearchResultsPlaceholderImages/2023-IMcover.png";

import "./searchResultsListStyles.css";

export const SearchResultsList = props => (
  <div className="SearchResultsList">
    {props.searchResults.map((searchResultItem, index) => {
      function returnSearchResult() {
        let result;

        switch (searchResultItem.type) {
          case "ConferencePaper":
            result = (
              <SearchResultConferencePaper
                searchResultItem={searchResultItem}
                index={index}
                key={index}
                onSearchResultClick={props.onSearchResultClick}
                searchTerm={props.searchTerm}
                type={props.type}
              />
            );
            break;
          case "Book":
            result = (
              <SearchResultBook
                searchResultItem={searchResultItem}
                index={index}
                onSearchResultClick={props.onSearchResultClick}
                key={index}
                searchTerm={props.searchTerm}
                type={props.type}
                detailsBasePath={props.bookDetailsPath}
                displayCreatedAndStatus={props.displayCreatedAndStatus}
              />
            );
            break;
          case "Review":
            result = (
              <SearchResultConferencePaper
                searchResultItem={searchResultItem}
                index={index}
                key={index}
                searchTerm={props.searchTerm}
                type={props.type}
              />
            );
            break;
          default:
            result = (
              <SearchResultConferencePaper
                searchResultItem={searchResultItem}
                key={index}
                index={index}
                searchTerm={props.searchTerm}
                type={props.type}
              />
            );
        }

        return result;
      }

        function returnImage(year, meetingType) {
          if (meetingType === "International") {
            if (year === 2000) {
              return TwoThousandImageIM;
            } else if (year === 2001) {
              return TwoThousandOneImageIM;
            } else if (year === 2002) {
              return TwoThousandTwoImageIM;
            } else if (year === 2003) {
              return TwoThousandThreeImageIM;
            } else if (year === 2004) {
              return TwoThousandFourImageIM;
            } else if (year === 2005) {
              return TwoThousandFiveImageIM;
            } else if (year === 2006) {
              return TwoThousandSixImageIM;
            } else if (year === 2007) {
              return TwoThousandSevenImageIM;
            } else if (year === 2008) {
              return TwoThousandEightImageIM;
            } else if (year === 2009) {
              return TwoThousandNineImageIM;
            } else if (year === 2010) {
              return TwoThousandTenImageIM;
            } else if (year === 2011) {
              return TwoThousandElevenImageIM;
            } else if (year === 2012) {
              return TwoThousandTwelveImageIM;
            } else if (year === 2013) {
              return TwoThousandThirteenImageIM;
            } else if (year === 2014) {
              return TwoThousandFourteenImageIM;
            } else if (year === 2015) {
              return TwoThousandFifteenImageIM;
            } else if (year === 2016) {
              return TwoThousandSixteenImageIM;
            } else if (year === 2017) {
              return TwoThousandSeventeenImageIM;
            } else if (year === 2018) {
              return TwoThousandEighteenImageIM;
            } else if (year === 2019) {
              return TwoThousandNineteenImageIM;
            } else if (year === 2022) {
              return TwoThousandTwentyTwoImageIM;
            } else if (year === 2023) {
              return TwoThousandTwentyThreeImageIM;
            } else {
              return ConferencePaperImage;
            }
          } else if (meetingType === "Annual") {
            if (year === 2000) {
              return TwoThousandImageAM;
            } else if (year === 2001) {
              return TwoThousandOneImageAM;
            } else if (year === 2002) {
              return TwoThousandTwoImageAM;
            } else if (year === 2003) {
              return TwoThousandThreeImageAM;
            } else if (year === 2004) {
              return TwoThousandFourImageAM;
            } else if (year === 2005) {
              return TwoThousandFiveImageAM;
            } else if (year === 2006) {
              return TwoThousandSixImageAM;
            } else if (year === 2007) {
              return TwoThousandSevenImageAM;
            } else if (year === 2008) {
              return TwoThousandEightImageAM;
            } else if (year === 2009) {
              return TwoThousandNineImageAM;
            } else if (year === 2010) {
              return TwoThousandTenImageAM;
            } else if (year === 2011) {
              return TwoThousandElevenImageAM;
            } else if (year === 2012) {
              return TwoThousandTwelveImageAM;
            } else if (year === 2013) {
              return TwoThousandThirteenImageAM;
            } else if (year === 2014) {
              return TwoThousandFourteenImageAM;
            } else if (year === 2015) {
              return TwoThousandFifteenImageA;
            } else if (year === 2016) {
              return TwoThousandSixteenImageAM;
            } else if (year === 2017) {
              return TwoThousandSeventeenImageAM;
            } else if (year === 2018) {
              return TwoThousandEighteenImageAM;
            } else if (year === 2019) {
              return TwoThousandNineteenImageAM;
            } else if (year === 2020) {
              return TwoThousandTwentyImageAM;
            } else if (year === 2021) {
              return TwoThousandTwentyOneImageAM;
            } else if (year === 2022) {
              return TwoThousandTwentyTwoImageAM;
            } else if (year === 2023) {
              return TwoThousandTwentyThreeImageAM;
            } else {
              return ConferencePaperImage;
            }
          } else {
            return ConferencePaperImage;
          }
        }

      return returnSearchResult();
    })}
  </div>
);
