import React from 'react'
import { SearchResultsPage } from '../SearchResultsPage/searchResultsPageContainer'
import { SearchResultsPageComponent } from '../../Components/componentsBarrel'

export class SearchResultsLandingPage extends React.Component {

    calculateSearchComponent = (host) => {
        return (
          <SearchResultsPageComponent
            searchTerm={host.state.searchTerm}
            keywordType={host.state.keywordType}
            pageCount={host.state.pageCount}
            perPage={host.state.perPage}
            filterData={host.state}
            numTotalResults={host.state.numTotalResults}
            pageSelectedIndex={host.state.pageSelectedIndex}
            sortTerm={host.state.sortTerm}
            onFilterClick={host.onFilterClick}
            handleContentTypeClick={host.handleContentTypeClick}
            handleMeetingTypeClick={host.handleMeetingTypeClick}
            sortResults={host.sortResults}
            handlePageClick={host.handlePageClick}
            updateResultsPerPage={host.updateResultsPerPage}
            updateSearchTerm={host.updateSearchTerm}
            displayShowingText={host.displayShowingText}
            renderLoaderOrSearchResults={host.renderLoaderOrSearchResults}
            isSeeMoreYearsHidden={host.state.isSeeMoreYearsHidden}
            selectedContentTypeOption={host.state.selectedContentTypeOption}
            selectedMeetingTypeOption={host.state.selectedMeetingTypeOption}
            searchDropdownValue={host.state.searchDropdownValue}
            annualMeetingFilter={host.state.annualMeetingFilter}
            internationalMeetingFilter={host.state.internationalMeetingFilter}
            bookDetailsPath={'/home/bookDetails/'}
          />
        )
      }

      getRedirect = (host) => {
        let url = '/home/searchResults/' + host.state.searchTerm.trim().replace(/\//g, '%2F');
        url += '?page=' + (host.state.pageSelectedIndex + 1);
    
        let time = 0;
        time = (host.state.twentyTwentyOneToPresent) ? time | 2048 : time;
        time = (host.state.twentySixteenToTwentyTwenty) ? time | 1 : time;
        time = (host.state.twentyElevenToTwentyFifteen) ? time | 2 : time;
        time = (host.state.twoThousandSixToTwentyTen) ? time | 4 : time;
        time = (host.state.twoThousandOneToTwoThousandFive) ? time | 8 : time;
        time = (host.state.ninetySixToTwoThousand) ? time | 16 : time;
        time = (host.state.ninetyOneToNinetyFive) ? time | 32 : time;
        time = (host.state.eightySixToNinety) ? time | 64 : time;
        time = (host.state.eightyOneToEightyFive) ? time | 128 : time;
        time = (host.state.seventySixToEighty) ? time | 256 : time;
        time = (host.state.seventyOneToSeventyFive) ? time | 512 : time;
        time = (host.state.uptoSeventy) ? time | 1024 : time;
    
        url += "&time=" + time;
    
        let type = 0;
        if ( host.state.bookFilter === true ) {
          type = 1;
          let subType = host.state.reviewedFilter ? 1 : host.state.notReviewedFilter ? 2 : 0;
          url += "&type=" + type + "&subtype=" + subType;
        }
        else if ( host.state.conferencePaperFilter === true ) {
          type = 2;
          let meettype = host.state.annualMeetingFilter ? 1 : host.state.internationalMeetingFilter ? 2 : 0;
          url += "&type=" + type + "&meettype=" + meettype;
        }
        else {
          url += "&type=" + type;
        }

        if ( host.state.sortTerm ) {
          url += "&sortTerm=" + host.state.sortTerm
        }
    
        if ( host.state.keywordType !== '' ) {
          url += "&kw=subsearch&reset=true";
        }
            return url;
    
      }

      getDisplayCreatedAndStatus = () => {}
            
      render() {
        return (
            <SearchResultsPage 
                calculateSearchComponent={this.calculateSearchComponent}
                location={this.props.location}
                match={this.props.match}
                getRedirect={this.getRedirect}
                bookDetailsPath={'/home/bookDetails/'}
                filterPending={true}
                isAdmin={false}
                displayCreatedAndStatus= {this.getDisplayCreatedAndStatus}
            />
        )
    }
}
