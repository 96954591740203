import React from 'react'
import Modal from 'react-modal'
import {InputField} from '../componentsBarrel'

import './imprintModal.css'

export class ImprintModal extends React.Component {
    constructor(props) {
        super(props)

        this.showDelete = props.item ? true : false

        this.state = {
            description: props.item ? props.item.description : '',
            edit: props.item ? true : false
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();
        this.props.onRequestClose();
    }

    onRequestDelete = (e) => {
        e.preventDefault();
        let data = {
            ...this.state,
            deleteRequested: true
        }
        this.onRequestClose(e);
        this.props.onClose(data, this.props.parent);

    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.onRequestClose(e);
        this.props.onClose(this.state, this.props.parent);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    getDeleteButton() {
        if ( this.showDelete ) {
            return (
                <button className="secondary-button" onClick={(e) => this.onRequestDelete(e)}>Delete</button>
            )
        }
    }

    render() {
        return (
            <main>
                <div className="imprint-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="ImprintCustomModal imprint-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>Imprint</h2>

                        <form onSubmit={this.handleSubmit}>
                            <div className="fieldContainer">
                                <div className="fieldGroup">
                                    <InputField title="Imprint" 
                                        name="description" 
                                        onChange={this.onChange} 
                                        value={this.state.description} 
                                        maxLength="100"
                                        required
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div className="dialogButtons">
                                <button className="default-button">Save</button>
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                                {this.getDeleteButton()}
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}