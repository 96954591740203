import * as React from 'react'

const requiredMark = (required) => {
    if ( required ) {
        return <span className="requiredMarker">* </span>
    }
}

export const TextField = (props) => {
    return (
        <div>
            <div>
                <span>{requiredMark(props.required)}<strong>{props.title}</strong>&nbsp;{props.subtitle}</span>
            </div>
            <div>
                <textarea
                    onChange={props.onChange}
                    className="form-control"
                    value={props.value ? props.value : ''}
                    rows={props.rows ? props.rows : 3}
                    {...props}
                />
            </div>
        </div>
    )
}
