export class BookStatusHelper {
    static translateStatus(statusId) {
        switch(statusId) {
            case null:
            case undefined:
                return 'None'
            case 0:
                return 'Withdrawn by Publisher'
            case 1:
                return 'Pending SBLC Approval'
            case 2:
                return 'SBLC Rejected'
            case 3: 
                return 'Pending RBL Approval'
            case 4:
                return 'RBL Rejected'
            case 5:
                return 'RBL Approved'
            case 6:
                return 'RBL Published'
            default:
                return '** UNKNOWN STATUS ENCOUNTERED **'
        }
    }
}

export const BookStatusFilterEnum = {
    All: '8',
    PendingSBLCApproval: '1',
    PendingRBLApproval: '3',
    PendingSubjectTag: '7',
    RBLApproved: '5',
    SBLCRejected: '2',
    RBLRejected: '4',
    Withdrawn: '0',
    RBLPublished: '6'
}

export const BookStatusEnum = {
    All: 8,
    PendingSBLCApproval: 1,
    SBLCRejected: 2,
    PendingRBLApproval: 3,
    RBLRejected: 4,
    RBLApproved: 5,
    RBLPublished: 6
}