import * as React from 'react'

const requiredMark = (required) => {
    if ( required ) {
        return <span className="requiredMarker">* </span>
    }
}

export const InputField = (props) => {
    return (
        <div>
            <div>
                <span>{requiredMark(props.required)}<strong>{props.title}</strong></span>
            </div>
            <div>
                <input
                    id={props.name}
                    onChange={props.onChange}
                    className="form-control-small form-control"
                    value={props.value ? props.value : ''}
                    {...props}
                />
            </div>
        </div>
    )
}
