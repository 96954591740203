import React, {Fragment} from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { AccountSelect } from '../componentsBarrel'
import { MessageFlash } from '../../Helpers/messageFlashHelper';
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import './uploadMeta.css'

export class UploadMeta extends React.Component {
    constructor(props) {
        super(props)

        this.user = JSON.parse(sessionStorage.getItem('loggedInUser'))
        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            accounts: [],
            accountId: 0,
            selectedFile: null,
            loading: false
        }
    }

    componentDidMount() {
        this.setState({loading: true}, () => this.loadDataFromServer())
    }

    loadDataFromServer() {
        axios
            .get(environment.baseUrl + '/api/accounts/enumerate', this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return;
                }

                let accounts = response.data.accounts

                this.setState({
                    accounts: accounts,
                    loading: false
                })
            })
            .catch(error => {
                console.error('Error loading data: ', error, error.response);
                alert('Error Loading Data');

                this.setState({
                    accounts: [],
                    loading: false
                })
            })
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.user.token
            }
        }

        if (this.state.accountId < 1) {
            alert('A Bill-To option must be selected')
            return false
        }

        if (this.state.selectedFile === null) {
            alert('Must select a file.')
            return false
        }

        const formData = new FormData()
        formData.append('files', this.state.selectedFile)

        this.setState({loading: true})

        return axios
            .post(`${environment.baseUrl}/api/metadata/upload`, formData, config)
            .then(response => {
                if (response.data.error) {
                    alert('Error processing metadata upload: ' + response.data.error)
                    return false;
                }

                let data = {
                    tempfile: response.data.tempfile,
                    filename: this.state.filename,
                    accountId: this.state.accountId,
                    sblid: this.user.sblId
                }

                return axios
                    .post(`${environment.baseUrl}/api/metadata/save`, data, this.config)
                    .then(response => {
                        if (response.data.error) {
                            alert('Error saving metadata upload: ' + response.data.error)
                            return false;
                        }

                        MessageFlash.setMessage('File Uploaded Successfully')
                        this.props.history.goBack()
                    })
                    .catch(error => {
                        this.setState({loading: false})
                        console.error('Error uploading file: ', error, error.response);
                        alert('Error Uploading File: ' + error);
                    })
            })
            .catch(error => {
                this.setState({loading: false})
                console.error('Error uploading file: ', error, error.response);
                alert('Error Uploading File: ' + error);
            })
    }

    handleAccountChange = (e) => {
        e.preventDefault()

        let account = e.target.options[e.target.selectedIndex].value

        this.setState({
            accountId: account
        })
    }

    handleFileSelection = (e) => {
        const self = this
        const file = e.target.files[0]
        const filename = e.target.value

        if (!this.checkExtension(filename)) {
            this.fileUpload.value = ''
            alert('Invalid file type. File must be XML or XLSX format.')
            return false
        }

        const sizeInMb = file.size / 1024 / 1024
        if (sizeInMb > 25) {
            this.fileUpload.value = ''
            alert("Error: File size cannot exceed 25 MB.")
            return false
        }

        this.setState({
            selectedFile: file,
            filename: file.name
        })
    }

    checkExtension(filename) {
        var hash = { 
            '.xml': 1,
            '.xlsx': 1
        };

        const re = /\.[0-9a-z]+$/i
        const ext = filename.toLowerCase().match(re);

        return hash[ext]
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    render() {
        return guardAdmin(
            <Fragment>
                    <div className="upload-meta">
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="upload-text-container">
                                        <span className="upload-text">
                                            Upload Metadata
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {getBusyIndicator(this.state.loading, 
                                <form onSubmit={this.handleSubmit}>
                                    <div className="upload-form-entry">
                                        <div className="row col-xs-8">
                                            <AccountSelect name="account"
                                                Title="Bill To"
                                                accounts={this.state.accounts}
                                                onChange={this.handleAccountChange}
                                                required
                                            />
                                        </div>

                                        <div className="ro col-xs-8">
                                            <input type="file"
                                                //accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml"
                                                //accept="application/xml"
                                                onChange={this.handleFileSelection}
                                                ref={(ref) => this.fileUpload = ref}>
                                            </input>
                                        </div>
                                    </div>

                                    <div className="row col-xs-12 text-center">
                                        <button className="SubmitButton">Submit</button>
                                        <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
            </Fragment>
        )
    }
}
