/**
 * Created by bhadaway on 2/8/18.
 */
import * as React from 'react';

import './sponsorsCarouselStyles.css';

class SponsorsCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [
        { name: '', donation: 30, img: 'hey.html' },
        { name: '', donation: 70, img: 'hey2.html' },
        { name: '', donation: 20, img: 'hey3.html' },
        { name: '', donation: 450, img: 'hey4.html' },
        { name: '', donation: 450, img: 'hey4.html' },
        { name: '', donation: 450, img: 'hey4.html' },
        { name: '', donation: 10, img: 'hey5.html' }
      ]
    };
  }
  render() {
    const returnSponsors = this.state.sponsors.map((sponsor, i) => {
      return (
        <div key={i} className="Sponsors">
          {sponsor.name}
        </div>
      );
    });
    return (
      <div className="SponsorsSection">
        <div className="SponsorsContainer">{returnSponsors}</div>
      </div>
    );
  }
}

export { SponsorsCarousel };
