import React from 'react'
import Modal from 'react-modal'

import { JournalArticleAuthors, InputField, TextField } from '../componentsBarrel'

import '../Styles/dialogButtons.css'
import './journalArticleModal.css'

export class JournalArticleModal extends React.Component {

    constructor(props) {
        super(props)

        this.showDelete = props.item ? true : false

        this.state = {
            title: props.item ? props.item.title : '',
            authors: props.item ? [...props.item.authors] : [],
            sendNotifications: props.item ? props.sendNotifications : false,
            doi: props.item ? props.item.doi : '',
            edit: props.item ? true : false
        }
    }

    onRequestClose = (e) => {
        e.preventDefault();
        this.props.onRequestClose();
    }

    onRequestDelete = (e) => {
        e.preventDefault();
        let data = {
            ...this.state,
            deleteRequested: true
        }
        this.onRequestClose(e);
        this.props.onClose(data, this.props.parent);

    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.validate()) return false

        this.onRequestClose(e);
        this.props.onClose(this.state, this.props.parent);
    }

    validate() {
        if (this.state.authors.length === 0) {
            alert('At least one author is required.')
            return false
        }

        return true
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeNumericOnly = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '')
        value = value.toUpperCase()
        this.setState({[e.target.name]: value})
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getDeleteButton() {
        if ( this.showDelete ) {
            return (
                <button className="secondary-button" onClick={(e) => this.onRequestDelete(e)}>Delete</button>
            )
        }
    }

    render() {
        let heading = this.state.edit === false ? 'Add Journal Article' : 'Edit Journal Article'

        return (
            <main>
                <div className="journal-article-modal">
                    <Modal
                        isOpen
                        onRequestClose={this.onRequestClose}
                        handleClose={this.onRequestClose}
                        className="JournalArticleCustomModal journal-article-modal-main"
                        appElement={document.getElementById('root')}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
                        }}
                    >
                        <h2>{heading}</h2>

                        <form onSubmit={this.handleSubmit}>
                            
                            <div className="fieldContainer">
                                <div className="fieldGroup">
                                    <InputField title="Title" 
                                        name="title" 
                                        onChange={this.onChange} 
                                        value={this.state.title} 
                                        maxLength="250"
                                        required
                                        autoFocus
                                    />
                                </div>
                            </div>

                            <div className="row fieldGroup">
                                <InputField title="DOI/URL"
                                    maxLength="4000"
                                    name="doi"
                                    value={this.state.doi}
                                    onChange={this.onChange}
                                    required
                                />
                            </div>

                            <div className="row fieldGroup">
                                <JournalArticleAuthors authors={this.state.authors}/>
                            </div>

                            <div className="dialogButtons">
                                <button className="default-button">Save</button>
                                <button className="secondary-button" onClick={(e) => this.onRequestClose(e)}>Cancel</button>
                                {this.getDeleteButton()}
                            </div>
                        </form>
                    </Modal>
                </div>
            </main>
        )
    }
}
