import React, {Fragment} from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { AccountSelect } from '../componentsBarrel'
import { MessageFlash } from '../../Helpers/messageFlashHelper';
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';
import { Helmet } from 'react-helmet';

import meetingImage from "../../Assets/Images/book_with_image.png";

import './meetingImageUpload.css'

import { 
    TextField, 
    InputField
} from '../componentsBarrel'

export class MeetingImageUpload extends React.Component {
    constructor(props) {
        super(props)

        this.user = JSON.parse(sessionStorage.getItem('loggedInUser'))
        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            meetingType: props.item ? props.item.format : 'AnnualMeeting',
            meetingTypeId: props.item ? props.item.formatTypeId : 1,
            selectedFile: null,
            year: '',
            loading: false,
            preview: '',
            deleteOldVersion: false
        }
    }

    componentDidMount() {
    }

    loadDataFromServer() {
        alert(this.state.year)
        this.showPreview = this.showPreview.bind(this)
        this.setState({loading: false})
    }

    onChangeNumericOnly = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '')
        value = value.toUpperCase()
        this.setState({[e.target.name]: value})
    }

    onBrowseClick = (e) => {
        e.preventDefault();
        this.fileUpload.click();
    }

    showPreview = (e) => {
        const self = this
        const file = e.target.files[0]
        const _URL = window.URL || window.webkitURL;

        let filename = e.target.value

        if (!this.checkExtension(filename)) {
            alert("Image file type must be .png or .jpg file.")
            this.fileUpload.value = ''
            return false
        }

        const sizeInMb = file.size / 1024 / 1024
        if (sizeInMb > 1) {
            this.fileUpload.value = ''
            let sizeErrorMessage = "Image file size cannot exceed 1 MB."
            alert(sizeErrorMessage)
            return false
        }

        var reader = new FileReader()

        reader.onload = function (e) {
            let image = new Image();

            image.onload = function () {
                var height = this.naturalHeight;
                var width = this.naturalWidth;
                if (height > 1200 || width > 750) {
                    alert("Image file dimensions cannot exceed height = 1,200 pixels and width = 750 pixels.");
                    return false;
                }

                self.setState({
                    preview: e.target.result,
                    selectedFile: file
                })
            };

            image.src = _URL.createObjectURL(file);
        }

        reader.readAsDataURL(e.target.files[0])
    }

    getImageDisplay() {
        let source = this.state.preview === '' ? this.state.imageUrl || meetingImage : this.state.preview;

        return (
            <div id="imageDisplay">
                <img
                    id="MeetingImage"
                    src={source}
                    alt="MeetingImage"
                />
                <input type="file"
                    id="avatar" name="avatar"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={this.showPreview}
                    ref={(ref) => this.fileUpload = ref}
                />
                <br />
                <br />
                <button id="file-button" onClick={this.onBrowseClick}>Browse</button>
                <br />
                <div id="imageText">.png or .jpg files only. Max size = 1 MB. <br />Max hgt = 1,200 px. Max wth = 750 px.</div>
            </div>
        )
    }

    redirectAfterImageSave() {
        MessageFlash.setMessage('Meeting image successfully uploaded.');
        //this.props.history.goBack();
        window.location.href = '/admin/system';
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.user.token
            }
        }

        if (this.validateYear() === false) {
            alert('Year must be entered as a four-digit year.')
            return false
        }

        if (!this.state.selectedFile) {
            alert('Please upload an image file.')
            return false;
        }

        const formData = new FormData()
        formData.append('files', this.state.selectedFile)
        formData.append('year', this.state.year)
        formData.append('meetingType', this.state.meetingType)
        formData.append('deleteOldVersion', this.state.deleteOldVersion)

        this.setState({loading: true})
        console.log('formData: ', formData)

        return axios
            .get(`${environment.baseUrl}/api/image/checkifimageexists/` + this.state.year + '/' + this.state.meetingType, config)
            .then(response => {
                if (response.data.exists == true) {
                    if (window.confirm('The image file for the ' + this.state.year + ' ' + this.state.meetingType +'already exists. Do you want to replace it?'))
                    {
                        console.log('deleteOldVersion: ', this.state.deleteOldVersion)
                        console.log('Clicked on OK')
                        //this.setState({deleteOldVersion: true})
                        return axios
                            .post(`${environment.baseUrl}/api/image/meetingupload`, formData, config)
                            .then(response2 => {
                                console.log('response2: ', response2)
                                this.redirectAfterImageSave()
                            })
                    }
                    else 
                    {
                        console.log('deleteOldVersion: ', this.state.deleteOldVersion)
                        console.log('Clicked on Cancel')
                        //this.props.history.goBack();
                        this.setState({loading: false})
                        return false;
                    }
                }
                else
                {
                    return axios
                            .post(`${environment.baseUrl}/api/image/meetingupload`, formData, config)
                            .then(response2 => {
                                console.log('response2: ', response2)
                                this.redirectAfterImageSave()
                            })
                }
            })
            .catch(error => {
                this.setState({loading: false})
                console.error('Error uploading file: ', error, error.response);
                alert('Error Uploading File: ' + error);
            })
    }

    handleFileSelection = (e) => {
        const self = this
        const file = e.target.files[0]
        const filename = e.target.value

        if (!this.checkExtension(filename)) {
            this.fileUpload.value = ''
            alert('Image file type must be .png or .jpg file.')
            return false
        }

        const sizeInMb = file.size / 1024 / 1024
        if (sizeInMb > 1) {
            this.fileUpload.value = ''
            alert("Image file size cannot exceed 1 MB.")
            return false
        }

        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height > 100 || width > 100) {
                alert("Height and Width must not exceed 100px.");
                return false;
                }
            alert("Uploaded image has valid Height and Width.");
            return true;
        };

        this.setState({
            selectedFile: file,
            filename: file.name
        })
    }

    checkExtension(filename) {
        var hash = { 
            '.png': 1,
            '.jpg': 1,
            '.jpeg': 1
        };

        const re = /\.[0-9a-z]+$/i
        const ext = filename.toLowerCase().match(re);

        return hash[ext]
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    exactlyFourDigits(value) {
        let numberFormat = /^\d{4}$/
        return numberFormat.test(value)
    }

    validateYear() {
        if (this.state.year
            && this.state.year.length === 4
            && this.exactlyFourDigits(this.state.year)) {
            return true
        }

        return false;
    }

    render() {
        return guardAdmin(
            <Fragment>
                <div className="meeting-image-upload">
                    <Helmet>
                        <meta name="robots" content="noindex" />
                    </Helmet>
                    <div className="TextContainer container">
                        <div className="title-section col-xs-12 text-center">
                            <div className="col-xs-12">
                                <div className="meeting-image-upload-text-container">
                                    <span className="meeting-image-upload-text">
                                        Meeting Image Upload
                                    </span>
                                </div>
                            </div>
                        </div>

                        {getBusyIndicator(this.state.loading, 
                            <form onSubmit={this.handleSubmit}>
                                <div className="col-sm-10">
                                    <div className="row ImageAndDetailsRow">
                                        <div className="col-xs-3 ImageSection">
                                            <div className="ConferencePaperImageContainer">
                                                {this.getImageDisplay()}
                                            </div>
                                        </div>
                                        <div className="col-xs-9 DetailsSection">
                                            <div className="DetailsContainer">
                                                <div>
                                                    <strong>Meeting Type</strong>
                                                </div>
                                                <div className="format-type-group">
                                                    <label>
                                                        <input type="radio"
                                                            name="meetingType"
                                                            value="AnnualMeeting"
                                                            checked={this.state.meetingType === 'AnnualMeeting'}
                                                            className="format-check-input"
                                                            onChange={(e) => this.setState({meetingType: 'AnnualMeeting', meetingTypeId: 1})}
                                                        />
                                                        Annual Meeting
                                                    </label>

                                                    <label>
                                                        <input type="radio"
                                                            name="meetingType"
                                                            value="InternationalMeeting"
                                                            checked={this.state.meetingType === 'InternationalMeeting'}
                                                            className="format-check-input"
                                                            onChange={(e) => this.setState({meetingType: 'InternationalMeeting', meetingTypeId: 2})}
                                                        />
                                                        International Meeting
                                                    </label>
                                                </div>
                                                <div className="ro col-xs-2">
                                                    <InputField title="Year" 
                                                        name="year" 
                                                        onChange={this.onChangeNumericOnly} 
                                                        value={this.state.year} 
                                                        maxLength="4"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-xs-12 text-center">
                                    <button className="SubmitButton">Submit</button>
                                    <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
}
