import * as React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { MenuItem } from 'react-bootstrap';
import { ButtonToolbar } from 'react-bootstrap';

import './searchComponentStyles.css';

export class SearchComponent extends React.Component {

  redirectToSearchResults = false;

  constructor(props) {
    super(props);

    this.state = {
      search: this.props.searchTerm || '',
      keywordType: this.props.keywordType,
      searchDropdownValue: sessionStorage.getItem('searchDropdownValue') || "All"
    };

    this.redirectToSearchResults = false;

    if (sessionStorage.getItem('searchDropdownValue') == null)
    {
      sessionStorage.setItem('searchDropdownValue', 'All');
    }

    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    let value = sessionStorage.getItem('searchDropdownValue');

    if ( this.state.searchDropdownValue !== value ) {
      this.setState({
        searchDropdownValue: value
      })
    }
  }

  onSearchSubmit = e => {
    e.preventDefault();
    this.redirectToPage();
  };

  redirectToPage() {
    this.props.resetSearchFilters(true);
    if (this.state.search) {
      this.redirectToSearchResults = true;
      this.forceUpdate();
    }
  }

  onSearchValueChange = e => this.setState({ 
    search: e.target.value,
    keywordType: ''
  });

  onDropdownChange = e => {
    switch(e) {
      case 'allFilter':
        sessionStorage.setItem('searchDropdownValue', "All");
        this.setState({searchDropdownValue: 'All'});
        break;
      case 'bookFilter':
        sessionStorage.setItem('searchDropdownValue', "Books & Reviews");
        this.setState({searchDropdownValue: 'Books & Reviews'});
        break;
      case 'conferencePaperFilter':
        sessionStorage.setItem('searchDropdownValue', "Paper Abstracts");
        this.setState({searchDropdownValue: 'Paper Abstracts'});
        break;
      default:
        console.warn('Unknown filter (defaulting to ALL): ', e);
        sessionStorage.setItem('searchDropdownValue', "All");
        this.setState({searchDropdownValue: 'All'});
        break;
    }
  };

  render() {
    // The part that makes the redirect happen
    if (this.redirectToSearchResults) {
      this.redirectToSearchResults = false;
      window.location = this.generateRedirect('/home/searchResults/');
      return "";
    }

    return (
      <div className="SearchContainer">
        <div className="TopSearchContainer">
          <div className="FilterSearchContainer">
            <div className="FilterSearchDropdownContainer">
              <ButtonToolbar>
                <DropdownButton title={this.state.searchDropdownValue} id="dropdown-size-medium">
                  <MenuItem eventKey="allFilter" onSelect={this.onDropdownChange}>All</MenuItem>
                  <MenuItem eventKey="bookFilter" onSelect={this.onDropdownChange}>Books &amp; Reviews</MenuItem>
                  <MenuItem eventKey="conferencePaperFilter" onSelect={this.onDropdownChange}>Paper Abstracts</MenuItem>
                </DropdownButton>
              </ButtonToolbar>
            </div>
          </div>
          <div className="SearchBarContainer">
            <form className="SearchBarForm" onSubmit={this.onSearchSubmit}>
              <input
                className="SearchBar"
                placeholder="Search SBL Central..."
                onChange={this.onSearchValueChange}
                value={this.state.keywordType !== "subsearch" ? decodeURIComponent(this.state.search) : ""}
                id="SearchBar"
                autoFocus
              />
            </form>
          </div>
          <div className="SearchIconContainer" onClick={this.onSearchSubmit}>
            <div className="SearchIcon" id="SearchIcon" />
          </div>
        </div>
        <div className="BottomSearchContainer">
          <div className="LeftSection" />
          <div className="MiddleSection">
            <div className="AdvancedSearchText" id="AdvancedSearchText">Advanced Search</div>
            <div className="BrowseJournalsText" id="BrowseJournalsText">Browse Journals</div>
          </div>
          <div className="RightSection" />
        </div>
      </div>
    );
  }

  generateRedirect(baseUrl) {
    let url = baseUrl + this.state.search.trim().replace(/\//g, '%2F');

    let type = "?type=";

    if ( this.state.searchDropdownValue === 'Books & Reviews') {
      type += "1";
    }
    else if ( this.state.searchDropdownValue === 'Paper Abstracts') {
      type += "2";
    }
    else {
      type += "0";
    }

    return url + type;
  }
}
