import React, { Component } from "react";
import Routes from "./App/Routes/routes";
import environment from "./App/Environments/environments";
import IdleTimer from 'react-idle-timer'
import { ModalProvider } from "./App/Containers/Login/loginContext";
import { SessionTimeoutDialog } from './App/Components/SessionTimeout/sessionTimeoutDialog'

import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
  }

  componentDidMount() {
    window.addEventListener('storage', this.storageListener)

    if (!sessionStorage.getItem("loggedInUser")) {
      //console.debug('ASKING FOR USER')
      sessionStorage.setItem("loggedInUser", JSON.stringify({isAuthenticated: false}))
      localStorage.setItem("getSessionStorage", Date.now())
    }
  }

  storageListener = (event) => {
    //console.debug('STORAGE LISTENER: ', event.key, event.newValue)
    if (event.key === 'getSessionStorage') {
      let payload = {
        loggedInUser: JSON.parse(sessionStorage.getItem("loggedInUser"))
      }

      if (payload.loggedInUser.isAuthenticated === true) {
        //console.debug('GOT REQUEST FOR USER.  SENDING: ', payload)
        localStorage.setItem('sessionStorage', JSON.stringify(payload));
        localStorage.removeItem('sessionStorage');
      }
     } else if (event.key === 'sessionStorage' && event.newValue) {
      let user = JSON.parse(sessionStorage.getItem("loggedInUser"))
      if (user && user.isAuthenticated === true) {
        return
      }

       var data = JSON.parse(event.newValue)

       let payload = {}

       for (let key in data) {
         payload[key] = data[key];
       }

       //console.debug('GOT PAYLOAD: ', payload)

       if (payload.loggedInUser && payload.loggedInUser.isAuthenticated === true) {
          sessionStorage.setItem("loggedInUser", JSON.stringify(payload.loggedInUser))
          window.location = window.location.href
       }
     }
  }

  componentWillUnmount() {
    console.debug('Removing event listener')
    window.removeEventListener('storage', this.storageListener)
  }

  handleOnIdle = (event) => {
    const user = JSON.parse(sessionStorage.getItem('loggedInUser'))

    if (user && !this.sessionDialog.state.modalIsOpen) {
      this.sessionDialog.showModal({
        onSessionClose: this.onSessionClose, 
        onSessionContinue: this.onSessionContinue
      })
    }
    else {
      this.idleTimer.reset()
    }
  }

  onSessionClose = () => {
    this.idleTimer.reset()
    sessionStorage.removeItem("loggedInUser")
    sessionStorage.setItem("sblc_session_timeout", 1)
    window.location = '/'
  }

  onSessionContinue = () => {
    this.idleTimer.reset()
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => {this.idleTimer = ref}}
          timeout={environment.sessionTimeoutInSeconds}
          onIdle={this.handleOnIdle}
          debounce={250}
        />

        <div className="App container-fluid">
          <ModalProvider>
            <Routes />
            <SessionTimeoutDialog 
              ref={ref => this.sessionDialog = ref}
              onSessionClose={this.onSessionClose}
              onSessionContinue={this.onSessionContinue}
            />
          </ModalProvider>
        </div>
      </div>      
    );
  }
}

export default App;
