import * as React from 'react';
import { SearchContainer } from '../../Containers/containersBarrel';
import nl2br from 'react-newline-to-break';

import './contactStyles.css';


let address =
  'Society Of Biblical Literature\nThe Luce Center\n825 Houston Mill Road\nAtlanta, GA 30329\nUSA';

export const Contact = (props) => (
  <div className="ContactPage">
    <div className="BackgroundImageSection">
      <div className="SearchAndTitleContainer">
        <SearchContainer className="Search" location={props.location.pathname} resetLocalStorage="true" />
      </div>
    </div>
    <div className="ContentContainer">
      <div className="TextContainer">
        <div className="ContactUsTextContainer">
          <div className="ContactUsText">Contact Us</div>
          <div className="MailText">Email</div>
          <div><a href="mailto: SBLCentral@sbl-site.org?subject=SBL Central">SBLCentral@sbl-site.org</a></div>
        </div>
        <div className="MailTextContainer">
          <div className="MailText">Mail</div>
          <div className="MailDetailsTextContainer">
            <div className="MailAddress">{nl2br(address)}</div>
          </div>
        </div>
        <div className="TelephoneNumberContainer">
          <div className="TelephoneNumber">Telephone</div>
          <div>01-404-727-3100</div>
        </div>
      </div>
      <div className="ImageContainer">
        <div className="Image">

        </div>
      </div>
    </div>
  </div>
);