import React from 'react'
import { InputField, TextField, ExpertiseComponent, Contributors } from '../componentsBarrel'

import '../BookEditor/bookEditor.css'
import '../Styles/dialogButtons.css'

export class CollectedWorksEssaysModalContent extends React.Component {
    constructor(props) {
        super(props)

        let essay = props.essay ? props.essay : {}

        this.state = {
            id: essay.id ? essay.id : -1,
            title: essay.title ? essay.title : '',
            pageStart: essay.pageStart ? essay.pageStart : '',
            pageEnd: essay.pageEnd ? essay.pageEnd : '',
            abstract: essay.abstract ? essay.abstract : '',
            doi: essay.doi ? essay.doi : '',
            keywords: essay.keywords ? essay.keywords : '',
            contributors: props.essay ? props.essay.contributors : [],
            subjects: props.essay ? props.essay.subjects : []
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubjectChange = (subjects) => {
        this.setState({
            subjects: subjects
        })
    }

    render() {
        return (
            <div className="TextContainer container">
                <div className="fieldContainer">
                    <div className="fieldGroup col-xs-12">
                        <InputField title="Essay Title"
                            name="title"
                            id="title"
                            maxLength="500"
                            onChange={this.onTextChange}
                            value={this.state.title}
                            required
                            autoFocus
                        />
                    </div>

                    <div className="fieldGroup row col-xs-12">
                        <Contributors
                            contributors={this.state.contributors}
                            title="Essay Contributors"
                        />
                    </div>

                    <div className="fieldGroup row">
                        <div className="col-xs-2">
                            <InputField title="Essay Page Start"
                                id="pageStart"
                                name="pageStart"
                                maxLength="5"
                                onChange={this.onTextChange}
                                value={this.state.pageStart}
                            />
                        </div>
                        <div className="col-xs-2">
                            <InputField title="Essay Page End"
                                id="pageEnd"
                                name="pageEnd"
                                maxLength="5"
                                onChange={this.onTextChange}
                                value={this.state.pageEnd}
                            />
                        </div>
                    </div>

                    <div className="fieldGroup row col-xs-12">
                        <TextField title="Essay Abstract"
                            id="abstract"
                            name="abstract"
                            rows="3"
                            maxLength="500"
                            onChange={this.onTextChange}
                            value={this.state.abstract}
                        />
                    </div>

                    <div className="fieldGroup row col-xs-12">
                        <InputField title="Essay DOI"
                            id="doi"
                            name="doi"
                            maxLength="500"
                            onChange={this.onTextChange}
                            value={this.state.doi}
                        />
                    </div>

                    <div className="fieldGroup row col-xs-12">
                        <ExpertiseComponent 
                            subjects={this.state.subjects} 
                            onSubjectChange={this.onSubjectChange} 
                            rows="2" 
                            title="Essay Subjects"
                        />
                    </div>

                    <div className="fieldGroup row col-xs-12">
                        <InputField title="Essay Keywords"
                            maxLength="500"
                            name="keywords"
                            value={this.state.keywords}
                            onChange={this.onTextChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}