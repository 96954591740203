import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

export class ExpertiseModalContent extends React.Component {
    constructor(props) {
        super(props)

        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            expertiseCategoryTypes: [],
            expertiseCategories: [],
            experise: [],
            subjectInfo: [],
            bookSubjects: props.subjects ? props.subjects : [],
            loading: true,
            categoryTypeIndex: -1,
            expertiseCategoryIndex: -1,
            selectedAvailableSubjectIndex: 0,
            selectedBookSubjectIndex: 0
        }
    }

    componentDidMount() {
        const url = environment.baseUrl + '/api/subjects/enumerateExpertise'

        axios
            .get(url, this.config)
            .then(response => {
                let data = response.data

                this.setState({
                    expertiseCategoryTypes: data.expertiseCategoryTypes,
                    expertiseCategories: data.expertiseCategories,
                    subjectInfo: data.subjectInfo,
                    loading: false,
                    categoryTypeIndex: 0,
                    expertiseCategoryIndex: 0,
                    selectedAvailableSubjectIndex: 0,
                    selectedBookSubjectIndex: 0
                })
            })
            .catch(error => {
                console.error(error.response.data)
                alert(error.response.data)
            })
    }

    async componentWillReceiveProps(newProps) {
        this.setState({
            bookSubjects: newProps.subjects ? newProps.subjects : []
        })
    }

    onTypeChange = (e) => {
        let index = parseInt(e.target.name, 10)
        this.setState({
            categoryTypeIndex: index,
            expertiseCategoryIndex: 0,
            selectedAvailableSubjectIndex: 0
        })
    }

    onCategoryChange = (e) => {
        this.setState({
            expertiseCategoryIndex: e.target.selectedIndex
        })
    }

    onAvailableSelectionChange = (e) => {
        this.setState({
            selectedAvailableSubjectIndex: e.target.selectedIndex
        })
    }

    onAddSubjectToBook = (e) => {
        e.preventDefault()
        if (this.state.selectedAvailableSubjectIndex < 0) {
            return;
        }

        let available = this.calculateSubjects();
        let subjectId = '';

        if (this.state.selectedAvailableSubjectIndex < available.length)
        {
            subjectId = available[this.state.selectedAvailableSubjectIndex].subjectId
        }else
        {
            subjectId = available[0].subjectId
        }

        if (this.isSubjectAlreadySelected(subjectId)) {
            alert('Subject already assigned to the book')
            return
        }

        let subject = this.state.subjectInfo.find(x => x.subjectId === subjectId)

        let bookSubject = {
            bookId: 0,
            subjectId: subjectId,
            subject: {
                id: subjectId,
                description: subject.description
            }
        }

        let bookSubjects = this.state.bookSubjects
        bookSubjects.push(bookSubject)

        this.setState({
            bookSubjects: bookSubjects,
            selectedBookSubjectIndex: Math.max(bookSubjects.length - 1, 0)
        })
    }

    isSubjectAlreadySelected(subjectId) {
        var subject = this.state.bookSubjects.find(x => x.subjectId === subjectId)
        return subject ? true : false
    }

    onRemoveSubjectFromBook = (e) => {
        e.preventDefault();

        if (this.state.selectedBookSubjectIndex >= 0 ) {
            let subjects = this.state.bookSubjects
            subjects.splice(this.state.selectedBookSubjectIndex, 1)

            this.setState({
                bookSubjects: subjects,
                selectedBookSubjectIndex: Math.max(subjects.length - 1, 0)
            })
        }
    }

    onSelectedBookSubjectChange = (e) => {
        this.setState({
            selectedBookSubjectIndex: e.target.selectedIndex
        })
    }

    calculateChecked(index) {
        return this.state.categoryTypeIndex === index;
    }

    getExpertiseCategories() {
        let array = this.calculateCategories()

        return (
            <Fragment>
                <select id="categories" className="standard-select" onChange={this.onCategoryChange} value={this.state.expertiseCategoryIndex}>
                    {array.map((item, index) => {
                        return (
                            <option key={index} id={item.id} value={index}>{item.category}</option>
                        )
                    })}
                </select>
            </Fragment>
        )
    }

    getAvailableSubjects() {
        let array = this.calculateSubjects();

        return (
            <Fragment>
                <select id="subject-display" className="standard-select" size="14" 
                    value={this.state.selectedAvailableSubjectIndex} 
                    onDoubleClick={this.onAddSubjectToBook}
                    onChange={this.onAvailableSelectionChange}>
                        {array.map((item, index) => {
                            return (
                                <option key={index} value={index}>{item.description}</option>
                            )
                        })}
                </select>
            </Fragment>
        )
    }

    getBookSubjects() {
        let array = this.calculateSelectedSubjects();

        return (
            <select id="subject-display" size="14" className="standard-select" 
                value={this.state.selectedBookSubjectIndex} 
                onDoubleClick={this.onRemoveSubjectFromBook}
                onChange={this.onSelectedBookSubjectChange}>
                    {array.map((item, index) => {
                        return (
                            <option key={index} value={index}>
                                {this.calculateSubjectDescription(item.subjectId)}
                            </option>
                        )
                    })}
            </select>
        )
    }

    calculateCategories() {
        if (this.state.categoryTypeIndex < 0) {
            return [];
        }

        let type = this.state.expertiseCategoryTypes[this.state.categoryTypeIndex].expertiseCategoryTypeId;
        let array = [];

        this.state.expertiseCategories.forEach(x => {
            if (x.expertiseCategoryTypeId === type) {
                array.push(x);
            }
        })

        return array;
    }

    calculateSubjects() {
        if (this.state.expertiseCategoryIndex < 0) {
            return [];
        }

        let expertiseCategories = this.calculateCategories();
        let expertiseId = expertiseCategories[this.state.expertiseCategoryIndex].id

        let array = []
        this.state.subjectInfo.forEach(info => {
            if (info.expertiseCategoryId === expertiseId) {
                if (this.subjectAlreadySelected(info) === false) {
                    array.push(info);
                }
            }
        })

        return array
    }

    subjectAlreadySelected(item) {
        for(let i = 0; i < this.state.bookSubjects.length; ++i) {
            let subject = this.state.bookSubjects[i]

            if (subject.subjectId === item.subjectId) {
                return true;
            }
        }
        
        return false;
    }

    calculateSelectedSubjects() {
        return this.state.bookSubjects
    }

    calculateSubjectDescription(subjectId) {
        let subject = this.state.subjectInfo.find(x => x.subjectId === subjectId)
        if (subject) {
            return `${subject.description} (${subject.expertiseCategory}: ${subject.expertiseSubCategory})`
        }

        return "Unknown " + subjectId
    }

    getMethodsAndTexts() {
        return (
            <Fragment>
                <div id="methodsAndTexts">
                    {this.state.expertiseCategoryTypes.map((item, index) => {
                        return (
                            <label key={index}>
                                <input type="radio"
                                    name={index}
                                    className="modal-radio"
                                    checked={this.calculateChecked(index)}
                                    onChange={this.onTypeChange}
                                />
                                {item.expertiseCategory}
                            </label>
                        )
                    })}
                </div>
            </Fragment>
        )
    }

    render() {
        return (
                <div>
                    {getBusyIndicator(this.state.loading,
                        <Fragment>
                            <div className="fieldContainer">
                                <div className="fieldGroup">
                                    {this.getMethodsAndTexts()}
                                </div>

                                <div className="fieldGroup">
                                    {this.getExpertiseCategories()}
                                </div>

                                <div className="subjects">
                                    <div className="subject-controls">
                                        <div className="block">
                                            <span><strong>Available Subjects</strong></span>
                                            {this.getAvailableSubjects()}
                                        </div>

                                        <div className="blockButtons">
                                            <i className="subject-arrow fas fa-arrow-circle-right"
                                                onClick={this.onAddSubjectToBook}></i>
                                            <br /><br /><br />
                                            <i className="subject-arrow fas fa-arrow-circle-left"
                                                onClick={this.onRemoveSubjectFromBook}></i>
                                        </div>

                                        <div className="block">
                                            <span><strong>Selected Subjects</strong></span>
                                            {this.getBookSubjects()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
        )
    }
}
