import * as React from "react";
import "./loginStyles.css";
import axios from "axios";
import environment from "../../Environments/environments";
import Modal from "react-modal";
import { Link } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: {
        errorText: "",
        isAuthenticated: false,
        firstName: "",
        lastName: "",
        sblId: "",
        isAdminUser: false
      }
    };
  }

  updateEmail = e =>
    this.setState({
      emailAddress: e.target.value
    });

  updatePassword = e =>
    this.setState({
      password: e.target.value
    });

  onRequestClose = () => {
    this.props.onRequestClose();
  };

  postCredentials = async event => {
    event.preventDefault();

    let loggedInUser = this.state.loggedInUser

    await axios
      .post(`${environment.loginUrl}/api/login`, {
        email: this.state.emailAddress,
        password: this.state.password,
      })
      .then(async response => {
        if (response.data.success === false) {
          this.setState(prevState => ({
            loggedInUser: {
              ...prevState.loggedInUser,
              errorText: response.data.message,
              isAuthenticated: false,
              isAdminUser: false,
              firstName: '',
              lastName: '',
              sblId: '',
              emailAddress: ''
            }
          }));

          return;
        }

        const config = {
          headers: {
            'Authorization': 'Bearer ' + response.data.token
          }
        }

        if (response.data.isAuthenticated) {
          await axios.post(`${environment.baseUrl}/api/login/getExtendedUserInfo`, { EmailAddress: response.data.emailAddress }, config)
            .then(info => {

                console.debug('Info: ', info)
                loggedInUser.errorText = ""
                loggedInUser.isAuthenticated = response.data.isAuthenticated
                loggedInUser.isAdminUser = info.data.isAdminUser
                loggedInUser.firstName = response.data.firstName
                loggedInUser.lastName = response.data.lastName
                loggedInUser.sblId = response.data.sblId
                loggedInUser.emailAddress = response.data.emailAddress
                loggedInUser.token = response.data.token

              this.setState({ loggedInUser: loggedInUser }, async () => {
                this.onRequestClose()
                window.location.reload()
              });
            })
        } else {
          this.setState(prevState => ({
            loggedInUser: {
              ...prevState.loggedInUser,
              errorText: response.data.error,
              isAuthenticated: response.data.isAuthenticated,
              isAdminUser: false,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              sblId: response.data.sblId,
              emailAddress: response.data.emailAddress
            }
          }));
        }

        sessionStorage.setItem(
          "loggedInUser",
          JSON.stringify(this.state.loggedInUser)
        );

        let payload = {
          loggedInUser: this.state.loggedInUser
        }
  
        localStorage.setItem('sessionStorage', JSON.stringify(payload));
        localStorage.removeItem('sessionStorage');
      })
      .catch(error => {
        alert(error);
      });
  };

  render() {
    return (
      <main>
        <Modal
          isOpen
          onRequestClose={this.onRequestClose}
          handleClose={this.onRequestClose}
          className="CustomModal modal-main"
          appElement={document.getElementById('root')}
          style={{
            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" }
          }}
        >
          <div className="flex-container login">
            <div className="box">
              <div id="close">
                <Link to={"#"} onClick={this.onRequestClose} className="close" />
              </div>
              <div className="login">
                <form onSubmit={this.postCredentials}>
                  <div>
                    <label className="label-text label-header">
                      SBL Member Credentials
                    </label>
                  </div>
                  <label className="label-text">Email:</label>
                  <input
                    type="text"
                    name="emailAddress"
                    maxLength="75"
                    required
                    onChange={this.updateEmail}
                  />
                  <br />
                  <label className="label-text">Password:</label>
                  <input
                    type="password"
                    name="password"
                    maxLength="32"
                    required
                    onChange={this.updatePassword}
                  />
                  <br />
                  <button
                    id="sign-in-button"
                    className="SignInButton"
                    onClick={this.handleShow}
                  >
                    Sign In
                  </button>
                </form>
                <div
                  className="error"
                  dangerouslySetInnerHTML={{
                    __html: this.state.loggedInUser.errorText
                  }}
                />
              </div>
              <div className="forgot">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                  href="https://www.sbl-site.org/Membership/Forgot.aspx"
                >
                  Forgot Your Password?
                </a>
              </div>
              <div className="forgot">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                  href="https://www.sbl-site.org/membership/joinnow.aspx"
                >
                  Join SBL or Renew Membership
                </a>
              </div>
            </div>
          </div>
          <br />
        </Modal>
      </main>
    );
  }
}

export { Login };
