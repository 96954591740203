import React, { Fragment } from 'react'
import { ModalConsumerStacked } from "../../Containers/Login/loginContext";
import { ListField, JournalArticleAuthorModal } from '../componentsBarrel'

export class JournalArticleAuthors extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            authors: props.authors ? props.authors : []
        }
    }

    showJournalArticleAuthorModal(showModal, self, item, index) {
        let close = (data, self) => {
            if (data) {
                let array = self.state.authors

                if (data.deleteRequested) {
                    array.splice(index, 1)
                }
                else if (data.edit) {
                    let record = array[index]
                    record.firstName = data.firstName
                    record.lastName = data.lastName
                    array[index] = record;
                }
                else {
                    array.push(data)
                }

                self.setState({
                    authors: array
                })
            }
        }

        showModal(JournalArticleAuthorModal, { onClose: close, parent: self, item: item })
    }

    addModal(event, modal, showModal) {
        event.preventDefault();
        modal(showModal, this)
    }

    editModal(event, modal, showModal, item, index) {
        event.preventDefault();
        if (item !== undefined) {
            modal(showModal, this, item, index)
        }
    }

    getModal() {
        return (
            <div>
                <span>
                    <ModalConsumerStacked>
                        {({ showModal }) => (
                            <Fragment>
                                <div className="modal-button">
                                    <button className="EditButton" onClick={(e) => this.addModal(e, this.showJournalArticleAuthorModal, showModal)}>
                                        Add Author
                                    </button>
                                </div>
                                <div className="col-xs-10">
                                    <ListField name="formatList"
                                        required
                                        data={this.state.authors}
                                        displayitem={this.displayAuthor}
                                        modal={this.showJournalArticleAuthorModal}
                                        showModal={showModal}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </ModalConsumerStacked>
                </span>
            </div>
        )
    }

    displayAuthor = (item, index, modal, showModal) => {
        let author = `${item.firstName} ${item.lastName}`

        return (
            <div key={index}>
                <a onClick={(e) => this.editModal(e, modal, showModal, item, index)}>{author}</a>
            </div>
        )
    }

    render() {
        return (
            <div className="select-title">
                <span className="select-title-text">
                    <span className="requiredMarker">* </span>
                    <strong>Authors</strong>
                </span>
                
                {this.getModal()}
            </div>
        )
    }
}
